<!-- eslint-disable @angular-eslint/template/elements-content -->
<div id="home-news-carousel" class="pt-5 pb-3">
  <div class="container">
    <div class="d-none d-lg-flex justify-content-center align-items-center">
      <h3 class="text-secondary fw-bold text-center" style="font-size: var(--home-section-title-fontSize)">
        {{ title }}
      </h3>
    </div>
    <div class="d-flex justify-content-between align-items-center justify-content-lg-end m-2">
      <h3 class="text-secondary fw-bold text-center d-lg-none m-0" style="font-size: var(--home-section-title-fontSize)">
        {{ title }}
      </h3>
      <div class="landing-swiper-controls">
        <button class="btn navigation-btn prev" (click)="next()">
          <fa-icon [icon]="faArrowLeft" class="fa-lg"></fa-icon>
        </button>

        <button class="btn navigation-btn next" (click)="previous()">
          <fa-icon [icon]="faArrowRight" class="fa-lg"></fa-icon>
        </button>
      </div>
    </div>

    <swiper-container swiper #swiperRef [config]="swiperOptions" init="false">
      <swiper-slide *ngFor="let piece of news; let i = index" class="d-flex justify-content-center">
        <div class="single-blog-card-item">
          <div class="card mx-2 rounded-0">
            <img
              [routerLink]="['/news', piece.id]"
              [src]="piece.thumbnail ?? piece.image | imageLink"
              class="card-img-top cursor-pointer"
              [alt]="piece.title"
            />
            <span class="triangle"></span>
            <div class="card-body pt-4 pb-4 d-flex flex-column">
              <h6
                style="font-size: var(--news-card-title-fontSize)"
                class="text-light tw-line-clamp-2 tw-leading-[1.5rem] tw-min-h-[3rem]"
              >
                {{ piece.title }}
              </h6>
              <p
                style="font-size: var(--news-card-description-fontSize)"
                class="mt-auto mb-0 d-flex justify-content-between text-light fw-light"
              >
                <span class="d-flex gap-2">
                  <fa-icon [icon]="faCalendar"></fa-icon>
                  {{ piece.date | date : "YYYY/MM/dd" }}
                </span>

                <span class="d-flex gap-2">
                  <fa-icon [icon]="faFlag"></fa-icon>
                  {{ piece.country }}
                </span>
              </p>
              <a [routerLink]="['/news', piece.id]" class="stretched-link"></a>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper-container>
  </div>
</div>
