import {Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {faEllipsisVertical} from "@fortawesome/free-solid-svg-icons";
import {ManagerPageData} from "src/app/interfaces/portal/manager-page";

@Component({
  selector: "app-manager-page",
  templateUrl: "./manager-page.component.html",
  styleUrls: ["./manager-page.component.scss"],
})
export class ManagerPageComponent implements OnInit {
  faEllipsisVertical = faEllipsisVertical;

  managerPageData!: ManagerPageData;

  constructor(private route: ActivatedRoute) {}

  managerSkills: {name: string; title: string; value: string}[] = [];

  ngOnInit(): void {
    const page = this.route.snapshot.data["page"] as ManagerPageData;
    this.managerPageData = page;
    this.managerSkills = [
      {
        name: "experiences",
        title: $localize`الخبرات العملية`,
        value: page.meta.experiences,
      },
      {name: "certificates", title: $localize`المؤهلات الأكاديمية`, value: page.meta.certificates},
      {name: "committees", title: $localize`عضوية المجالس واللجان`, value: page.meta.committees},
      {name: "achievements", title: $localize`أهم الإنجازات`, value: page.meta.achievements},
    ];
  }
}
