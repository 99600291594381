<app-page>
  <page-header></page-header>
  <page-content>
    <div class="container">
      <div class="row">
        <div class="col-md-9 d-flex">
          <div *ngIf="mode === 'list'" class="d-flex align-items-center gap-4">
            <button
              class="btn btn-primary rounded-circle page-btn d-flex justify-content-center align-items-center"
              (click)="getPrevPeriodEvents()"
            >
              <fa-icon [icon]="faChevronRight"></fa-icon>
            </button>
            <span class="text-primary d-inline-block fw-bold fs-4">
              {{ currentPeriod | date : currentPeriodFormat }}
            </span>
            <button
              class="btn btn-primary rounded-circle page-btn d-flex justify-content-center align-items-center"
              (click)="getNextPeriodEvents()"
            >
              <fa-icon [icon]="faChevronLeft"></fa-icon>
            </button>
          </div>
        </div>
        <div class="col-md-3 mt-4 mt-md-0">
          <div class="row mt-3 mt-md-0 d-flex justify-content-end align-items-center">
            <div *ngIf="mode === 'list'" class="col-8">
              <select
                class="form-select rounded-0 focus-shadow-none py-3"
                i18n-aria-label
                aria-label="الفترة الزمنية"
                [(ngModel)]="periodFilter"
                (change)="onPeriodFilterChange()"
              >
                <option i18n [value]="PeriodFilter.DAY">استعراض بـ: اليوم</option>
                <option i18n [value]="PeriodFilter.MONTH">استعراض بـ: الشهر</option>
                <option i18n [value]="PeriodFilter.YEAR">استعراض بـ: السنة</option>
              </select>
            </div>
            <div class="col-4">
              <app-search-menu [fields]="filterFields" (search)="onSubmit($event)" (clear)="onClear()"></app-search-menu>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <div *ngFor="let event of events" class="col-lg-3 col-md-4 col-sm-6 mt-4">
          <div class="card h-100 shadow-sm">
            <div class="card-header border-0 bg-light">
              <fa-icon [icon]="getSlugIcon(event.type.slug)" class="me-2"></fa-icon>
              <ng-container>{{ event.type.name }}</ng-container>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between align-items-start">
                <div class="d-flex align-items-center justify-content-start gap-3">
                  <img
                    [src]="event.image | imageLink"
                    style="width: 80px; aspect-ratio: 1/1; object-fit: cover"
                    class="rounded-circle"
                    [alt]="event.name"
                  />
                  <div>
                    <h6 class="h3 text-primary fw-bold mb-0">
                      {{ event.startDate | date : "dd" }}
                    </h6>
                    <p class="fw-bold text-primary mb-0">{{ event.startDate | date : "MMMM YYYY" }}</p>
                  </div>
                </div>
              </div>
              <p class="h6 mt-4 tw-line-clamp-3 tw-leading-[1.5rem] tw-min-h-[4.5rem]">{{ event.name }}</p>
              <a [routerLink]="['/', event.type.slug, event.id]" class="stretched-link"></a>
            </div>
          </div>
        </div>
        <div *ngIf="!loading && paginate" class="text-center mt-4">
          <button i18n class="btn btn-outline-dark rounded-0 px-5 py-4" (click)="getNextPage()">عرض المزيد</button>
        </div>
        <div *ngIf="!loading && (!events || !events.length)" class="col-12 text-center py-5">
          <span i18n class="fw-bold">لا توجد فعاليات في هذه الفترة</span>
        </div>
        <div *ngIf="loading" class="d-flex align-items-center justify-content-center bg-light" style="min-height: 300px">
          <div class="spinner"></div>
        </div>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>
