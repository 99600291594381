<app-navbar></app-navbar>

<app-landing-carousel
  *ngIf="heroType === LandingHeroType.PHOTOS || !heroVideo"
  [slides]="carouselSlides"
></app-landing-carousel>
<app-landing-hero *ngIf="heroType === LandingHeroType.VIDEO && heroVideo" [video]="heroVideo"></app-landing-hero>
<app-landing-news
  *ngIf="newsSlides && newsSlides.length > 0"
  [title]="meta.newsSectionTitle"
  [news]="newsSlides"
></app-landing-news>
<app-landing-events
  *ngIf="eventsSlides && eventsSlides.length > 0"
  [title]="meta.eventsSectionTitle"
  [events]="eventsSlides"
></app-landing-events>
<app-landing-projects
  *ngIf="projectsSlides && projectsSlides.length > 0"
  [title]="meta.projectsSectionTitle"
  [projects]="projectsSlides"
></app-landing-projects>
<app-landing-services
  *ngIf="servicesSlides && servicesSlides.length > 0"
  [title]="meta.servicesSectionTitle"
  [services]="servicesSlides"
></app-landing-services>
<app-footer-links></app-footer-links>
<app-copyright></app-copyright>
