import {ChangeDetectorRef, Component, OnDestroy} from "@angular/core";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {
  addDays,
  addMonths,
  addYears,
  endOfDay,
  endOfMonth,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfYear,
} from "date-fns";
import {ReplaySubject, takeUntil} from "rxjs";
import {PeriodFilter} from "src/app/enums/period-filter";
import {EventSlug, EventsPageListItem} from "src/app/interfaces/portal/events-page";
import {SearchMenuField, SearchMenuResult} from "src/app/interfaces/search-menu";
import {PortalContentService} from "src/app/services/portal-content.service";
import {environment} from "src/environments/environment";

@Component({
  selector: "app-events-page",
  templateUrl: "./events-page.component.html",
  styleUrls: ["./events-page.component.scss"],
})
export class EventsPageComponent implements OnDestroy {
  faChevronRight = faChevronRight;
  faChevronLeft = faChevronLeft;

  PeriodFilter = PeriodFilter;

  loading!: boolean;

  events: EventsPageListItem[] = [];

  periodFilter: PeriodFilter = PeriodFilter.YEAR;
  periodOffset = 0;
  paginate = true;
  emptyFilter = {
    name: "",
    content: "",
  };
  filterFields: SearchMenuField[] = [
    {key: "name", name: $localize`الاسم`, type: "text"},
    {key: "content", name: $localize`النص`, type: "text"},
  ];
  filter!: SearchMenuResult;
  page = 1;

  mode: "search" | "list" = "list";

  unsubscribe$ = new ReplaySubject(1);

  constructor(private cmsService: PortalContentService, private cd: ChangeDetectorRef) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next(1);
    this.unsubscribe$.complete();
  }

  get currentPeriod() {
    switch (this.periodFilter) {
      case PeriodFilter.DAY:
        return addDays(new Date(), this.periodOffset);
      case PeriodFilter.MONTH:
        return addMonths(new Date(), this.periodOffset);
      case PeriodFilter.YEAR:
        return addYears(new Date(), this.periodOffset);
    }
  }

  get currentPeriodFormat() {
    switch (this.periodFilter) {
      case PeriodFilter.DAY:
        return "dd MMMM YYYY";
      case PeriodFilter.MONTH:
        return "MMMM YYYY";
      case PeriodFilter.YEAR:
        return "YYYY";
    }
  }

  get startDate() {
    switch (this.periodFilter) {
      case PeriodFilter.DAY:
        return startOfDay(addDays(new Date(), this.periodOffset));
      case PeriodFilter.MONTH:
        return startOfMonth(addMonths(new Date(), this.periodOffset));
      case PeriodFilter.YEAR:
        return startOfYear(addYears(new Date(), this.periodOffset));
    }
  }

  get endDate() {
    switch (this.periodFilter) {
      case PeriodFilter.DAY:
        return endOfDay(addDays(new Date(), this.periodOffset));
      case PeriodFilter.MONTH:
        return endOfMonth(addMonths(new Date(), this.periodOffset));
      case PeriodFilter.YEAR:
        return endOfYear(addYears(new Date(), this.periodOffset));
    }
  }

  onPeriodFilterChange() {
    this.periodOffset = 0;
    this.refreshData();
  }

  onTypeFilterChange() {
    this.refreshData();
  }

  getPrevPeriodEvents() {
    this.periodOffset--;
    this.refreshData();
  }

  getNextPeriodEvents() {
    this.periodOffset++;
    this.refreshData();
  }

  fetchEvents() {
    this.loading = true;
    this.cmsService
      .listEvents(this.page, {startDate: this.startDate.toISOString(), endDate: this.endDate.toISOString(), ...this.filter})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (result) => {
          this.events = [...this.events, ...result];
          if (this.mode === "search" && result.length < environment.limit.events) {
            this.paginate = false;
          }
          this.loading = false;
        },
        error: () => {
          this.events = [];
          this.loading = false;
        },
      });
  }

  onSubmit(filter: SearchMenuResult) {
    const fieldsCount = Object.values(filter).filter((value) => !!value).length;
    this.mode = fieldsCount > 0 ? "search" : "list";
    this.page = 1;
    this.filter = filter;
    this.refreshData();
    this.cd.detectChanges();
  }

  onClear() {
    this.filter = {...this.emptyFilter};
    this.page = 1;
    this.mode = "list";
    this.refreshData();
  }

  refreshData() {
    this.paginate = this.mode === "search";
    this.events = [];
    this.fetchEvents();
  }

  getNextPage() {
    if (this.mode !== "search") return;
    this.page++;
    this.fetchEvents();
  }

  getSlugIcon(slug: EventSlug) {
    return this.cmsService.getEventSlugIcon(slug);
  }
}
