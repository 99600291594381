import {ChangeDetectorRef, Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {ReplaySubject, takeUntil} from "rxjs";
import {Center} from "src/app/interfaces/portal/centers-page";
import {
  Publication,
  PublicationCenters,
  PublicationsClassification,
  PublicationSort,
  PublicationsPage,
  PublicationsPageData,
} from "src/app/interfaces/portal/publications-page";
import {SearchMenuField, SearchMenuResult} from "src/app/interfaces/search-menu";
import {PortalContentService} from "src/app/services/portal-content.service";
import {environment} from "src/environments/environment";

enum PublicationView {
  ALL_PUBLICATIONS,
  NEW_PUBLICATIONS,
  MOST_VIEWED_PUBLICATIONS,
}

@Component({
  selector: "app-publications-page",
  templateUrl: "./publications-page.component.html",
  styleUrls: ["./publications-page.component.scss"],
})
export class PublicationsPageComponent implements OnInit, OnDestroy {
  PublicationView = PublicationView;
  activeView: PublicationView = PublicationView.NEW_PUBLICATIONS;

  isLoading = false;

  meta!: PublicationsPage;
  publications!: Publication[];
  classifications!: PublicationsClassification[];
  centers!: Center[];
  publicationCenters!: PublicationCenters[];
  paginate = true;
  page = 1;

  emptyFilter = {
    title: "",
    author: "",
    center: "",
    summary: "",
    classification: "",
  };

  filterFields: SearchMenuField[] = [];

  filter!: SearchMenuResult;

  unsubscribe$ = new ReplaySubject(1);

  constructor(private route: ActivatedRoute, private cmsService: PortalContentService, private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    const {meta, ...page} = this.route.snapshot.data["page"] as PublicationsPageData;
    this.meta = meta;
    this.classifications = page.data.classifications;
    this.centers = page.data.centers;
    this.centers = this.centers.filter((center) => Number(center.id) !== 6);
    this.publicationCenters = page.data.publicationCenters;

    this.filterFields = [
      {key: "title", name: $localize`عنوان الإصدار`, type: "text"},
      {key: "author", name: $localize`اسم المؤلف`, type: "text"},
      {
        key: "center",
        name: $localize`الناشر`,
        type: "select",
        options: this.publicationCenters.map((center) => ({text: center.name, value: center.id})),
      },
      {key: "summary", name: $localize`الملخص`, type: "text"},
      {
        key: "classification",
        name: $localize`التصنيف`,
        type: "select",
        options: this.classifications.map((classification) => ({text: classification.name, value: classification.id})),
      },
      {key: "audio", name: $localize`يحتوي على تلخيص صوتي؟`, type: "tristate"},
      {key: "infographics", name: $localize`يحتوي على انفوجرافيك؟`, type: "tristate"},
    ];

    this.filter = {...this.emptyFilter};
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(1);
    this.unsubscribe$.complete();
  }

  get sort(): PublicationSort {
    switch (this.activeView) {
      case PublicationView.ALL_PUBLICATIONS:
        return "-release_date";
      case PublicationView.MOST_VIEWED_PUBLICATIONS:
        return "-views";
      case PublicationView.NEW_PUBLICATIONS:
        return "-release_date";
    }
  }

  selectNewPublications() {
    this.activeView = PublicationView.NEW_PUBLICATIONS;
    this.cdRef.detectChanges();

    this.resetData();
    this.getPublicationsByFilter();
  }

  selectMostViewedPublications() {
    this.activeView = PublicationView.MOST_VIEWED_PUBLICATIONS;
    this.cdRef.detectChanges();

    this.resetData();
    this.getPublicationsByFilter();
  }

  selectAllPublications() {
    this.activeView = PublicationView.ALL_PUBLICATIONS;
    this.cdRef.detectChanges();

    this.resetData();
    this.paginate = true;
    this.getPublicationsByFilter();
  }

  refreshData() {
    switch (this.activeView) {
      case PublicationView.ALL_PUBLICATIONS:
        this.selectAllPublications();
        break;
      case PublicationView.MOST_VIEWED_PUBLICATIONS:
        this.selectMostViewedPublications();
        break;
      case PublicationView.NEW_PUBLICATIONS:
        this.selectNewPublications();
        break;
    }
  }

  resetData() {
    this.publications = [];
    this.paginate = false;
    this.page = 1;
  }

  getNextPage() {
    this.page++;
    this.getPublicationsByFilter();
  }

  onClear() {
    this.filter = {...this.emptyFilter};
    this.refreshData();
  }

  isEmpty(filter: SearchMenuResult): boolean {
    for (const key in filter) {
      const value = filter[key];
      if (typeof value === "string") {
        if (value.trim()) {
          return false;
        }
      }

      if (typeof value === "boolean") {
        return false;
      }
    }
    return true;
  }

  onSubmit(filter: SearchMenuResult) {
    this.filter = filter;
    if (this.isEmpty(filter)) {
      this.refreshData();
      return;
    }
    this.selectAllPublications();
  }

  getPublicationsByFilter() {
    this.isLoading = true;
    this.cmsService
      .getPublicationsByFilter(this.page, this.filter, this.sort)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (publications) => {
          this.publications = [...this.publications, ...publications];
          if (publications.length < environment.limit.publications) {
            this.paginate = false;
          }
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
        },
      });
  }
}
