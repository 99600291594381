import {DOCUMENT} from "@angular/common";
import {Component, Inject, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {filter, ReplaySubject, take, takeUntil} from "rxjs";

import {AccessibilityService} from "./services/accessiblity.service";
import {AnalyticsService} from "./services/analytics.service";
import {WindowService} from "./services/window.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  loading = true;

  unsubscribe$ = new ReplaySubject(1);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private windowService: WindowService,
    @Inject(DOCUMENT) private document: Document,
    private accessiblityService: AccessibilityService,
    private analyticsService: AnalyticsService,
  ) {
    this.analyticsService.run();
    this.subscribeToRouterEvents();
    this.subscribeToFragmentChange();
  }

  ngOnInit(): void {
    this.accessiblityService.updateGrayscale();
    this.accessiblityService.updateDocumentFontSize();
    this.accessiblityService.updateDarkMode();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(1);
    this.unsubscribe$.complete();
  }

  subscribeToRouterEvents() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(() => {
        this.loading = false;
        this.windowService.scrollTo(0, 0);
      });
  }

  subscribeToFragmentChange() {
    this.route.fragment
      .pipe(
        filter((fragment) => !!fragment),
        take(1),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((fragment) => {
        if (!fragment) return;
        const interval = setInterval(() => {
          const element = this.document.getElementById(fragment);
          if (!element) return;
          element.scrollIntoView();
          clearInterval(interval);
        }, 500);
      });
  }
}
