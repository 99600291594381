import {gql} from "apollo-angular";
import {Report, ReportsFilter, ReportsPage, ReportsSort} from "src/app/interfaces/portal/reports-page";

export const GET_REPORTS_PAGE = gql<{page: ReportsPage}, void>`
  query {
    page: reports_page {
      title
      image {
        id
        filename: filename_download
        title
      }
    }
  }
`;

export const getReportsByFilterFactory = (filter?: ReportsFilter, sort: ReportsSort = "-date_created") => {
  const variables: string[] = [];
  const expressions: string[] = ['{status: {_eq: "published"}}'];

  if (filter) {
    if (filter.title) {
      variables.push("$title: String!");
      expressions.push(`{title: {_icontains: $title}}`);
    }
  }

  const queryVariables = variables.length > 0 ? `(${variables.join(", ")})` : "";
  const queryFilter = expressions.length > 1 ? `{_and: [${expressions.join(", ")}]}` : expressions.join("");

  return gql<{reports: Report[]}, {title?: string}>`
    query ${queryVariables} {
      reports(
        filter: ${queryFilter}
        sort: ["${sort}"]
      ) {
        id
        title
        content
        date
        file {
          id
          filename: filename_download
          title
          type
          filesize
        }
        image {
          id
          filename: filename_download
          title
        }
      }
    }
  `;
};

export const GET_REPORT_PAGE = gql<
  {page: ReportsPage; report: Report; reports: Report[]},
  {reportId: string; reportsLimit: number}
>`
  query ($reportId: ID!, $reportsLimit: Int!) {
    page: reports_page {
      title
      image {
        id
        filename: filename_download
        title
      }
    }
    report: reports_by_id(id: $reportId) {
      id
      title
      content
      date
      file {
        id
        filename: filename_download
        title
        type
        filesize
      }
      image {
        id
        filename: filename_download
        title
      }
    }
    reports(filter: {status: {_eq: "published"}}, sort: ["-date_created"], limit: $reportsLimit) {
      id
      title
      content
      date
      file {
        id
        filename: filename_download
        title
        type
        filesize
      }
      image {
        id
        filename: filename_download
        title
      }
    }
  }
`;
