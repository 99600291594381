<div class="footer-font-override">
  <div id="newsletter" class="bg-dark py-3">
    <h5 class="text-light fw-bold">
      <fa-icon [icon]="faEnvelopeOpenText" class="pb-3 me-2"></fa-icon>
      <ng-container i18n>النشرة البريدية - جسور</ng-container>
    </h5>
    <p i18n class="small text-light">
      ابق على تواصل مع المكتب من خلال الاشتراك في النشرة البريدية ( جسور )؛ ليصلك جميع المستجدات في مجال التربية والتعليم
    </p>

    <div class="row">
      <form class="col-12 col-md-8 col-lg-6" [formGroup]="emailFormGroup">
        <div #ref class="input-group">
          <input
            type="email"
            required
            formControlName="email"
            class="form-control small focus-shadow-none"
            [ngClass]="{'is-invalid': email.invalid && (email.dirty || email.touched)}"
            i18n-placeholder
            placeholder="اشترك بتسجيل بريدك الإلكتروني"
          />
          <span class="input-group-text p-0 border-0">
            <button
              [disabled]="email.invalid || sending || success || hasError"
              (click)="addToNewslettersList()"
              class="btn py-3 px-4 rounded-0 rounded-end"
              [ngClass]="{'btn-danger': hasError, 'btn-dark': !hasError && !success, 'btn-success': success}"
              style="min-width: 100px"
            >
              <span i18n *ngIf="!sending && !success && !hasError">اشتراك</span>
              <span *ngIf="sending" class="fade-in-down">
                <fa-icon [icon]="faCircleNotch" class="ms-1" animation="spin"></fa-icon>
              </span>
              <span *ngIf="!sending && success" class="fade-in-down"><fa-icon [icon]="faCheck"></fa-icon></span>
              <span *ngIf="!sending && hasError" class="fade-in-down">
                <fa-icon [icon]="faTriangleExclamation"></fa-icon>
              </span>
            </button>
          </span>
        </div>
      </form>
    </div>
  </div>
</div>
