<app-page>
  <page-header></page-header>
  <page-content>
    <lightgallery *ngIf="isBrowser" id="infographic-gallery" [settings]="settings" [onInit]="onInit">
      <div class="container">
        <div class="row mt-3 mt-md-0 d-flex justify-content-end align-items-center">
          <div class="col-1">
            <app-search-menu [fields]="filterFields" (search)="onSubmit($event)" (clear)="onClear()"></app-search-menu>
          </div>
        </div>
        <div class="row">
          <div *ngFor="let infographic of infographics" class="col-lg-4 col-md-6 mt-4">
            <div class="item" [attr.data-src]="infographic.image | imageLink">
              <div class="card rounded-0 shadow">
                <div class="card-image">
                  <img
                    [src]="infographic.image | imageLink"
                    class="img-fluid w-100 cover"
                    style="height: 225px"
                    [alt]="infographic.title"
                  />
                </div>
                <div class="card-body">
                  <h6 class="title fs-5 mb-4 fw-bold mt-3 tw-line-clamp-2 tw-leading-[1.5rem] tw-min-h-[3rem]">
                    {{ infographic.title }}
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="!loading && paginate" class="text-center mt-4">
            <button i18n class="btn btn-outline-dark rounded-0 px-5 py-4" (click)="fetchInfographics()">عرض المزيد</button>
          </div>
          <div
            *ngIf="!loading && (!infographics || !infographics.length)"
            class="d-flex justify-content-center align-items-center"
            style="min-height: 300px"
            i18n
          >
            لا توجد بيانات
          </div>
        </div>
        <div *ngIf="loading" class="d-flex align-items-center justify-content-center bg-light" style="min-height: 300px">
          <div class="spinner"></div>
        </div>
      </div>
    </lightgallery>
  </page-content>
  <page-footer></page-footer>
</app-page>
