import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Apollo} from "apollo-angular";
import {forkJoin, map, Observable} from "rxjs";
import {PageType} from "src/app/enums/page-type";
import {SitemapPageData} from "src/app/interfaces/portal/sitemap-page";
import {GET_SITEMAP_PAGE} from "src/app/queries/portal/sitemap-page.query";
import {NavbarService} from "src/app/services/navbar.service";
import {SeoService} from "src/app/services/seo.service";

@Injectable({
  providedIn: "root",
})
export class SitemapPageResolver implements Resolve<SitemapPageData> {
  constructor(private apollo: Apollo, private navbarService: NavbarService, private seoService: SeoService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<SitemapPageData> {
    return forkJoin([
      this.apollo.query({
        query: GET_SITEMAP_PAGE,
      }),
      this.navbarService.getMenu(),
    ]).pipe(
      map(([result, menu]): SitemapPageData => {
        const {page: meta} = result.data;
        return this.seoService.patchTitleAndMetaTags(route, {
          title: $localize`خريطة الموقع`,
          breadcrumbs: [
            {title: $localize`البوابة`, routerLink: ""},
            {title: $localize`خريطة الموقع`, routerLink: "sitemap"},
          ],
          data: menu,
          meta,
          type: PageType.INTERIOR,
        });
      }),
    );
  }
}
