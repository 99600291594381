import {Component, Input} from "@angular/core";
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import {PublishingLandingPage} from "src/app/interfaces/publishing/publishing-landing-page";

@Component({
  selector: "app-publishing-landing-cta",
  templateUrl: "./publishing-landing-cta.component.html",
  styleUrls: ["./publishing-landing-cta.component.scss"],
})
export class PublishingLandingCtaComponent {
  faDownload = faDownload;

  @Input() meta!: PublishingLandingPage;
}
