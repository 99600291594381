import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";

import {AboutPageComponent} from "./components/portal/pages/about-page/about-page.component";
import {AgreementsPageComponent} from "./components/portal/pages/agreements-page/agreements-page.component";
import {BooksPageComponent} from "./components/portal/pages/books-page/books-page.component";
import {CentersPageComponent} from "./components/portal/pages/centers-page/centers-page.component";
import {ContactUsPageComponent} from "./components/portal/pages/contact-us-page/contact-us-page.component";
import {CountriesPageComponent} from "./components/portal/pages/countries-page/countries-page.component";
import {DepartmentsPageComponent} from "./components/portal/pages/departments-page/departments-page.component";
import {SingleDepartmentPageComponent} from "./components/portal/pages/departments-page/single-department-page/single-department-page.component";
import {EventCategoryPageComponent} from "./components/portal/pages/events-page/event-category-page/event-category-page.component";
import {EventsPageComponent} from "./components/portal/pages/events-page/events-page.component";
import {SingleEventPageComponent} from "./components/portal/pages/events-page/single-event-page/single-event-page.component";
import {InfographicGalleryPageComponent} from "./components/portal/pages/infographic-gallery-page/infographic-gallery-page.component";
import {LandingPageComponent} from "./components/portal/pages/landing-page/landing-page.component";
import {ManagerPageComponent} from "./components/portal/pages/manager-page/manager-page.component";
import {MediaReportsPageComponent} from "./components/portal/pages/media-reports-page/media-reports-page.component";
import {NewsPageComponent} from "./components/portal/pages/news-page/news-page.component";
import {SingleNewsPageComponent} from "./components/portal/pages/news-page/single-news-page/single-news-page.component";
import {NumbersPageComponent} from "./components/portal/pages/numbers-page/numbers-page.component";
import {PrivacyPageComponent} from "./components/portal/pages/privacy-page/privacy-page.component";
import {ProjectsPageComponent} from "./components/portal/pages/projects-page/projects-page.component";
import {SingleProjectPageComponent} from "./components/portal/pages/projects-page/single-project-page/single-project-page.component";
import {PublicationsPageComponent} from "./components/portal/pages/publications-page/publications-page.component";
import {SinglePublicationPageComponent} from "./components/portal/pages/publications-page/single-publication-page/single-publication-page.component";
import {ReportsPageComponent} from "./components/portal/pages/reports-page/reports-page.component";
import {SingleReportPageComponent} from "./components/portal/pages/reports-page/single-report-page/single-report-page.component";
import {ResourcesPageComponent} from "./components/portal/pages/resources-page/resources-page.component";
import {SearchComponent} from "./components/portal/pages/search/search.component";
import {SitemapPageComponent} from "./components/portal/pages/sitemap-page/sitemap-page.component";
import {StrategicPlansPageComponent} from "./components/portal/pages/strategic-plans-page/strategic-plans-page.component";
import {UnsubscribePageComponent} from "./components/portal/pages/unsubscribe-page/unsubscribe-page.component";
import {PortalComponent} from "./components/portal/portal.component";
import {PublishingLandingPageComponent} from "./components/publishing/pages/publishing-landing-page/publishing-landing-page.component";
import {PublishingComponent} from "./components/publishing/publishing.component";
import {StoriesLandingPageComponent} from "./components/stories/pages/stories-landing-page/stories-landing-page.component";
import {StoriesComponent} from "./components/stories/stories.component";
import {LanguageGuard} from "./guards/language.guard";
import {AboutUsPageResolver} from "./resolvers/portal/about-us-page.resolver";
import {AgreementsPageResolver} from "./resolvers/portal/agreements-page.resolver";
import {BooksPageResolver} from "./resolvers/portal/books-page.resolver";
import {CentersPageResolver} from "./resolvers/portal/centers-page.resolver";
import {ContactUsPageResolver} from "./resolvers/portal/contact-us-page.resolver";
import {CountriesPageResolver} from "./resolvers/portal/countries-page.resolver";
import {DepartmentsPageResolver} from "./resolvers/portal/departments-page.resolver";
import {EventCategoryPageResolver} from "./resolvers/portal/event-category-page.resolver";
import {EventsPageResolver} from "./resolvers/portal/events-page.resolver";
import {InfographicGalleryPageResolver} from "./resolvers/portal/infographic-gallery-page.resolver";
import {LandingPageResolver} from "./resolvers/portal/landing-page.resolver";
import {ManagerPageResolver} from "./resolvers/portal/manager-page.resolver";
import {MediaReportsPageResolver} from "./resolvers/portal/media-reports-page.resolver";
import {NewsPageResolver} from "./resolvers/portal/news-page.resolver";
import {NumbersPageResolver} from "./resolvers/portal/numbers-page.resolver";
import {PrivacyPageResolver} from "./resolvers/portal/privacy-page.resolver";
import {ProjectsPageResolver} from "./resolvers/portal/projects-page.resolver";
import {PublicationsPageResolver} from "./resolvers/portal/publications-page.resolver";
import {ReportsPageResolver} from "./resolvers/portal/reports-page.resolver";
import {ResourcesPageResolver} from "./resolvers/portal/resources-page.resolver";
import {SearchPageResolver} from "./resolvers/portal/search-page.resolver";
import {SingleDepartmentPageResolver} from "./resolvers/portal/single-department-page.resolver";
import {SingleEventPageResolver} from "./resolvers/portal/single-event-page.resolver";
import {SingleNewsPageResolver} from "./resolvers/portal/single-news-page.resolver";
import {SingleProjectPageResolver} from "./resolvers/portal/single-project-page.resolver";
import {SinglePublicationResolver} from "./resolvers/portal/single-publication.resolver";
import {SingleReportPageResolver} from "./resolvers/portal/single-report-page.resolver";
import {SitemapPageResolver} from "./resolvers/portal/sitemap-page.resolver";
import {StrategicPlansPageResolver} from "./resolvers/portal/strategic-plans-page.resolver";
import {UnsubscribePageResolver} from "./resolvers/portal/unsubscribe-page.resolver";
import {PublishingLandingPageResolver} from "./resolvers/publishing/publishing-landing-page.resolver";
import {StoriesLandingPageResolver} from "./resolvers/stories/stories-landing-page.resolver";

const routes: Routes = [
  {
    path: "publishing",
    component: PublishingComponent,
    children: [
      {
        path: "",
        component: PublishingLandingPageComponent,
        resolve: {page: PublishingLandingPageResolver},
        data: {landing: true, locales: ["ar"]},
        canActivate: [LanguageGuard],
      },
    ],
    data: {
      group: $localize`برنامج الإصدارات و النشر`,
    },
  },
  {
    path: "stories",
    component: StoriesComponent,
    children: [
      {
        path: "",
        component: StoriesLandingPageComponent,
        resolve: {page: StoriesLandingPageResolver},
        data: {landing: true, locales: ["ar"]},
        canActivate: [LanguageGuard],
      },
    ],
    data: {
      group: $localize`مسابقة المحتوى الإثرائي للطفل`,
    },
  },
  {
    path: "",
    component: PortalComponent,
    children: [
      {
        path: "events",
        component: EventsPageComponent,
        resolve: {page: EventsPageResolver},
        data: {locales: ["ar"]},
        canActivate: [LanguageGuard],
      },
      {
        path: "events/:eventId",
        component: SingleEventPageComponent,
        resolve: {page: SingleEventPageResolver},
        data: {slug: "events", locales: ["ar"]},
        canActivate: [LanguageGuard],
      },
      {
        path: "conferences",
        component: EventCategoryPageComponent,
        resolve: {page: EventCategoryPageResolver},
        data: {slug: ["conferences"], locales: ["ar"]},
        canActivate: [LanguageGuard],
      },
      {
        path: "conferences/:eventId",
        component: SingleEventPageComponent,
        resolve: {page: SingleEventPageResolver},
        data: {slug: "conferences", locales: ["ar"]},
        canActivate: [LanguageGuard],
      },
      {
        path: "courses-workshops",
        component: EventCategoryPageComponent,
        resolve: {page: EventCategoryPageResolver},
        data: {slug: ["courses", "workshops"], locales: ["ar"]},
        canActivate: [LanguageGuard],
      },
      {
        path: "courses/:eventId",
        component: SingleEventPageComponent,
        resolve: {page: SingleEventPageResolver},
        data: {slug: "courses", locales: ["ar"]},
        canActivate: [LanguageGuard],
      },
      {
        path: "workshops/:eventId",
        component: SingleEventPageComponent,
        resolve: {page: SingleEventPageResolver},
        data: {slug: "workshops", locales: ["ar"]},
        canActivate: [LanguageGuard],
      },

      {
        path: "appointments-seminars",
        component: EventCategoryPageComponent,
        resolve: {page: EventCategoryPageResolver},
        data: {slug: ["appointments", "seminars"], locales: ["ar"]},
        canActivate: [LanguageGuard],
      },
      {
        path: "appointments/:eventId",
        component: SingleEventPageComponent,
        resolve: {page: SingleEventPageResolver},
        data: {slug: "appointments", locales: ["ar"]},
        canActivate: [LanguageGuard],
      },
      {
        path: "seminars/:eventId",
        component: SingleEventPageComponent,
        resolve: {page: SingleEventPageResolver},
        data: {slug: "seminars", locales: ["ar"]},
        canActivate: [LanguageGuard],
      },

      {
        path: "meetings",
        component: EventCategoryPageComponent,
        resolve: {page: EventCategoryPageResolver},
        data: {slug: ["meetings"], locales: ["ar"]},
        canActivate: [LanguageGuard],
      },
      {
        path: "meetings/:eventId",
        component: SingleEventPageComponent,
        resolve: {page: SingleEventPageResolver},
        data: {slug: "meetings", locales: ["ar"]},
        canActivate: [LanguageGuard],
      },

      {
        path: "days",
        component: EventCategoryPageComponent,
        resolve: {page: EventCategoryPageResolver},
        data: {slug: ["days"], locales: ["ar"]},
        canActivate: [LanguageGuard],
      },
      {
        path: "days/:eventId",
        component: SingleEventPageComponent,
        resolve: {page: SingleEventPageResolver},
        data: {slug: "days", locales: ["ar"]},
        canActivate: [LanguageGuard],
      },

      {
        path: "about",
        component: AboutPageComponent,
        resolve: {page: AboutUsPageResolver},
        data: {locales: ["ar", "en"], tts: true},
        canActivate: [LanguageGuard],
      },
      {
        path: "centers",
        component: CentersPageComponent,
        resolve: {page: CentersPageResolver},
        data: {locales: ["ar", "en"]},
        canActivate: [LanguageGuard],
      },
      {
        path: "contact-us",
        component: ContactUsPageComponent,
        resolve: {page: ContactUsPageResolver},
        data: {locales: ["ar", "en"]},
        canActivate: [LanguageGuard],
      },
      {
        path: "",
        component: LandingPageComponent,
        resolve: {page: LandingPageResolver},
        data: {landing: true, locales: ["ar", "en"]},
        canActivate: [LanguageGuard],
      },
      {
        path: "infographic-gallery",
        component: InfographicGalleryPageComponent,
        resolve: {page: InfographicGalleryPageResolver},
        data: {locales: ["ar"]},
        canActivate: [LanguageGuard],
      },
      {
        path: "manager",
        component: ManagerPageComponent,
        resolve: {page: ManagerPageResolver},
        data: {locales: ["ar", "en"]},
        canActivate: [LanguageGuard],
      },
      {
        path: "news",
        component: NewsPageComponent,
        resolve: {page: NewsPageResolver},
        data: {locales: ["ar", "en"]},
        canActivate: [LanguageGuard],
      },
      {
        path: "numbers",
        component: NumbersPageComponent,
        resolve: {page: NumbersPageResolver},
        data: {locales: ["ar", "en"]},
        canActivate: [LanguageGuard],
      },
      {
        path: "projects",
        component: ProjectsPageComponent,
        resolve: {page: ProjectsPageResolver},
        data: {locales: ["ar", "en"]},
        canActivate: [LanguageGuard],
      },
      {
        path: "publications",
        component: PublicationsPageComponent,
        resolve: {page: PublicationsPageResolver},
        data: {locales: ["ar"]},
        canActivate: [LanguageGuard],
      },
      {
        path: "reports",
        component: ReportsPageComponent,
        resolve: {page: ReportsPageResolver},
        data: {locales: ["ar"]},
        canActivate: [LanguageGuard],
      },
      {
        path: "media-reports",
        component: MediaReportsPageComponent,
        resolve: {page: MediaReportsPageResolver},
        data: {locales: ["ar"]},
        canActivate: [LanguageGuard],
      },
      {
        path: "news/:newsId",
        component: SingleNewsPageComponent,
        resolve: {page: SingleNewsPageResolver},
        data: {locales: ["ar", "en"], tts: true},
        canActivate: [LanguageGuard],
      },
      {
        path: "projects/:projectId",
        component: SingleProjectPageComponent,
        resolve: {page: SingleProjectPageResolver},
        data: {locales: ["ar", "en"]},
        canActivate: [LanguageGuard],
      },
      {
        path: "publications/:publicationId",
        component: SinglePublicationPageComponent,
        resolve: {page: SinglePublicationResolver},
        data: {locales: ["ar"]},
        canActivate: [LanguageGuard],
      },
      {
        path: "reports/:reportId",
        component: SingleReportPageComponent,
        resolve: {page: SingleReportPageResolver},
        data: {locales: ["ar"]},
        canActivate: [LanguageGuard],
      },
      {
        path: "strategic-plans",
        component: StrategicPlansPageComponent,
        resolve: {page: StrategicPlansPageResolver},
        data: {locales: ["ar", "en"]},
        canActivate: [LanguageGuard],
      },
      {
        path: "agreements",
        component: AgreementsPageComponent,
        resolve: {page: AgreementsPageResolver},
        data: {locales: ["ar", "en"]},
        canActivate: [LanguageGuard],
      },
      {
        path: "members",
        component: CountriesPageComponent,
        resolve: {page: CountriesPageResolver},
        data: {locales: ["ar", "en"]},
        canActivate: [LanguageGuard],
      },
      {
        path: "privacy",
        component: PrivacyPageComponent,
        resolve: {page: PrivacyPageResolver},
        data: {locales: ["ar", "en"]},
        canActivate: [LanguageGuard],
      },
      {
        path: "departments",
        component: DepartmentsPageComponent,
        resolve: {page: DepartmentsPageResolver},
        data: {locales: ["ar", "en"]},
        canActivate: [LanguageGuard],
      },
      {
        path: "departments/:departmentId",
        component: SingleDepartmentPageComponent,
        resolve: {page: SingleDepartmentPageResolver},
        data: {locales: ["ar", "en"]},
        canActivate: [LanguageGuard],
      },
      {
        path: "books",
        component: BooksPageComponent,
        resolve: {page: BooksPageResolver},
        data: {locales: ["ar"]},
        canActivate: [LanguageGuard],
      },
      {
        path: "resources",
        component: ResourcesPageComponent,
        resolve: {page: ResourcesPageResolver},
        data: {locales: ["ar"]},
        canActivate: [LanguageGuard],
      },
      {
        path: "unsubscribe/:userId",
        component: UnsubscribePageComponent,
        resolve: {page: UnsubscribePageResolver},
        data: {locales: ["ar", "en"]},
        canActivate: [LanguageGuard],
      },
      {
        path: "sitemap",
        component: SitemapPageComponent,
        resolve: {page: SitemapPageResolver},
        data: {locales: ["ar", "en"]},
        canActivate: [LanguageGuard],
      },
      {
        path: "search",
        component: SearchComponent,
        resolve: {page: SearchPageResolver},
        data: {locales: ["ar", "en"]},
        canActivate: [LanguageGuard],
      },
    ],
    data: {
      group: $localize`مكتب التربية العربي لدول الخليج`,
    },
  },
  {path: "**", redirectTo: ""},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: "enabledBlocking",
      anchorScrolling: "enabled",
      onSameUrlNavigation: "reload",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
