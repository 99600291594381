import {gql} from "apollo-angular";
import {I18nFunctions} from "src/app/interfaces/i18n";
import {NewsPage} from "src/app/interfaces/portal/news-page";
import {PieceOfNews} from "src/app/interfaces/portal/single-news-page";

export const GET_SINGLE_NEWS_PAGE = ({field, filter}: I18nFunctions) => gql<
  {page: NewsPage; news: PieceOfNews[]},
  {newsId: string}
>`
  query ($newsId: GraphQLStringOrFloat!) {
    page: news_page {
      title: ${field("title")}
      image {
        id
        filename: filename_download
        title
      }
    }
    news: news(filter: {_and: [{id: {_eq: $newsId}}, {status: {_eq: "published"}}, ${filter("title")}]}) {
      id
      title: ${field("title")}
      content: ${field("content")}
      date
      image {
        id
        filename: filename_download
        title
      }
      country: ${field("country")}
    }
  }
`;
