import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {faAnglesLeft, faSquare} from "@fortawesome/free-solid-svg-icons";
import {ReplaySubject, takeUntil} from "rxjs";
import {
  MediaReport,
  MediaReportsPage,
  MediaReportsPageData,
  MediaReportsTypes,
} from "src/app/interfaces/portal/media-reports-page";
import {SearchMenuField, SearchMenuResult} from "src/app/interfaces/search-menu";
import {PortalContentService} from "src/app/services/portal-content.service";

@Component({
  selector: "app-media-reports-page",
  templateUrl: "./media-reports-page.component.html",
  styleUrls: ["./media-reports-page.component.scss"],
})
export class MediaReportsPageComponent implements OnInit, OnDestroy {
  faSquare = faSquare;
  faAnglesLeft = faAnglesLeft;

  loading!: boolean;

  meta!: MediaReportsPage;

  mediaReports!: MediaReport[];
  mediaReportsTypes!: MediaReportsTypes[];

  emptyFilter = {
    title: "",
  };

  filter!: SearchMenuResult;
  filterFields!: SearchMenuField[];
  unsubscribe$ = new ReplaySubject(1);

  constructor(private route: ActivatedRoute, private cmsService: PortalContentService) {}

  ngOnInit(): void {
    const {meta, ...page} = this.route.snapshot.data["page"] as MediaReportsPageData;
    this.meta = meta;
    this.mediaReportsTypes = page.data.mediaReportsTypes;

    this.filterFields = [
      {key: "title", name: $localize`العنوان`, type: "text"},
      {
        key: "type",
        name: $localize`النوع`,
        type: "select",
        options: this.mediaReportsTypes.map((mediaReportsType) => ({
          text: mediaReportsType.name,
          value: mediaReportsType.id,
        })),
      },
    ];
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(1);
    this.unsubscribe$.complete();
  }

  onSubmit(filter: SearchMenuResult) {
    this.filter = filter;
    this.refreshData();
  }

  onClear() {
    this.filter = {...this.emptyFilter};
    this.refreshData();
  }

  refreshData() {
    this.mediaReports = [];
    this.getMediaReports();
  }

  getMediaReports() {
    this.loading = true;
    this.cmsService
      .listMediaReports(this.filter)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (result) => {
          this.mediaReports = result;
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });
  }
}
