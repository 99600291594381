import {Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {Hero} from "src/app/interfaces/hero";
import {PageData} from "src/app/interfaces/page-data";

@Component({
  selector: "app-page",
  templateUrl: "./page.component.html",
  styleUrls: ["./page.component.scss"],
})
export class PageComponent implements OnInit {
  hero!: Hero;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    const {title, breadcrumbs, meta, options} = this.route.snapshot.data["page"] as PageData;
    const landing = !!this.route.snapshot.data["landing"];
    this.hero = {
      title,
      breadcrumbs: landing ? [] : breadcrumbs,
      image: meta.image,
      height: options?.heroHeight,
    };
  }
}
