<app-page>
  <page-header></page-header>
  <page-content>
    <div class="mt-3">
      <div class="row justify-content-center">
        <div *ngFor="let country of countries; index as i" class="col-lg-2 mt-4 gulf-country">
          <a [href]="country.url" target="_blank" class="card h-100 rounded-0 cursor-pointer hover-border-primary">
            <img [src]="country.image | imageLink" class="card-img-top country-image" [alt]="country.image.title" />
            <div class="card-body d-flex align-items-center justify-content-center">
              <p class="card-text text-black text-center">
                {{ country.name }}
              </p>
            </div>
          </a>
        </div>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>
