<app-page>
  <page-header></page-header>
  <page-content>
    <div id="projects-page">
      <div class="container">
        <p *ngIf="projects[0]" class="alert p-4 text-center text-light" style="border-width: 2px; background-color: #278266">
          <span i18n>برامج ومشاريع المكتب للدورة المالية</span>
          <span>&nbsp;&nbsp;{{ projects[0].year }}</span>
        </p>
        <div class="d-flex justify-content-end mb-3">
          <app-search-menu [fields]="filterFields" (search)="onSubmit($event)" (clear)="onClear()"></app-search-menu>
        </div>
        <div class="row">
          <div *ngFor="let project of projects" class="col-lg-3 col-md-4 col-sm-6 my-4">
            <div class="card project-card">
              <div class="card-body p-4">
                <div class="d-flex justify-content-between gap-3 mb-4">
                  <a
                    [routerLink]="['/projects', project.id]"
                    class="h6 text-primary fw-bold project-name"
                    style="text-align: right; line-height: 1.5 !important; word-spacing: 3px"
                  >
                    {{ project.name }}
                  </a>

                  <span class="text-primary small project-arrows">
                    <fa-icon [icon]="faPlay"></fa-icon>
                    <fa-icon [icon]="faPlay" class="opacity-50"></fa-icon>
                    <fa-icon [icon]="faPlay" class="opacity-25"></fa-icon>
                  </span>
                </div>

                <img
                  [routerLink]="['/projects', project.id]"
                  [src]="project.image | imageLink"
                  class="cursor-pointer img-fluid rounded-3 project-thumbnail"
                  [alt]="project.name"
                />

                <div class="project-summary">
                  <p class="mt-4 fw-light tw-line-clamp-2 tw-leading-[1.5rem] tw-min-h-[3rem]" style="text-align: initial">
                    {{ project.summary }}
                  </p>
                </div>

                <div class="text-end mt-4">
                  <a i18n [routerLink]="['/projects', project.id]" class="fw-bold">التفاصيل</a>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="!loading && paginate" class="text-center mt-4">
            <button i18n class="btn btn-outline-dark rounded-0 px-5 py-4" (click)="getNextPage()">عرض المزيد</button>
          </div>
          <div
            i18n
            *ngIf="!loading && (!projects || !projects.length)"
            class="d-flex justify-content-center align-items-center"
            style="min-height: 300px"
          >
            لا توجد بيانات
          </div>
        </div>
        <div *ngIf="loading" class="d-flex align-items-center justify-content-center bg-light" style="min-height: 300px">
          <div class="spinner"></div>
        </div>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>
