import {gql} from "apollo-angular";
import {SitemapPage} from "src/app/interfaces/portal/sitemap-page";

export const GET_SITEMAP_PAGE = gql<{page: SitemapPage}, void>`
  query {
    page: contact_us_page {
      image {
        id
        filename: filename_download
        title
      }
    }
  }
`;
