import {Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {ImageFile} from "src/app/interfaces/image-file";
import {
  StrategicPlansPage,
  StrategicPlansPageData,
  StrategicPlansPageListItem,
} from "src/app/interfaces/portal/strategic-plans-page";
import {ImageLinkPipe} from "src/app/pipes/image-link.pipe";

@Component({
  selector: "app-strategic-plans-page",
  templateUrl: "./strategic-plans-page.component.html",
  styleUrls: ["./strategic-plans-page.component.scss"],
})
export class StrategicPlansPageComponent implements OnInit {
  meta!: StrategicPlansPage;
  plans!: StrategicPlansPageListItem[];

  modalData?: StrategicPlansPageListItem;

  constructor(private route: ActivatedRoute, private imageLinkPipe: ImageLinkPipe) {}

  ngOnInit(): void {
    const {meta, ...page} = this.route.snapshot.data["page"] as StrategicPlansPageData;

    this.meta = meta;
    this.plans = page.data;
  }

  getImageLink(image: ImageFile) {
    return this.imageLinkPipe.transform(image);
  }

  setModalData(plan: StrategicPlansPageListItem) {
    this.modalData = plan;
  }
}
