import {gql} from "apollo-angular";
import {BooksPage, BookStep} from "src/app/interfaces/portal/books-page";

export const GET_BOOKS_PAGE = gql<{page: BooksPage; bookSteps: BookStep[]}, void>`
  query {
    page: books_page {
      title
      image {
        id
        filename: filename_download
        title
      }
    }
    bookSteps: book_steps(filter: {status: {_eq: "published"}}, sort: ["order"]) {
      id
      title
      description
      order
      icon {
        id
        filename: filename_download
        title
      }
    }
  }
`;
