import {gql} from "apollo-angular";
import {I18nFunctions} from "src/app/interfaces/i18n";
import {NavbarItem} from "src/app/interfaces/navbar-item";

export const GET_NAVBAR_ITEMS = ({field, filter}: I18nFunctions) => gql<{services: NavbarItem[]}, void>`
  query {
    services(filter: {_and: [{status: {_eq: "published"}}, {hide_in_navbar: {_eq: false}}, {is_internal: {_eq: false}}, ${filter(
      "name",
    )}]}, sort: ["sort"]) {
      link: url
      title: ${field("name")}
      category
    }
  }
`;
