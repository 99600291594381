<app-page>
  <page-header></page-header>
  <page-content>
    <div class="container">
      <div class="row">
        <div class="col-lg-8 col-md-10 mx-auto">
          <div class="card rounded-0">
            <div class="card-body p-5">
              <h3 class="text-primary">{{ event.name }}</h3>

              <p class="fw-light small mb-0">
                <fa-icon [icon]="faCalendarMinus" class="me-2 text-light-gray"></fa-icon>
                {{ event.startDate | date : "YYYY/MM/dd" }}
                <ng-container i18n>حتى</ng-container>
                {{ event.endDate | date : "YYYY/MM/dd" }}
              </p>

              <p *ngIf="event.location" class="fw-light small mb-0">
                <fa-icon [icon]="faLocationDot" class="me-2 text-light-gray"></fa-icon>
                {{ event.location }}
              </p>

              <div class="row mt-4">
                <div class="col-12 d-flex justify-content-center">
                  <img
                    [src]="event.image | imageLink"
                    style="
                      max-height: 500px;
                      max-width: 100%;
                      width: auto;
                      border: 2px solid lightslategray;
                      border-radius: 5px;
                    "
                    [alt]="event.name"
                  />
                </div>
                <div class="col-12 mt-5 lead">
                  <div [innerHTML]="event.content"></div>
                </div>

                <ng-container *ngIf="event.participants && event.participants.length > 0">
                  <hr class="my-4" />
                  <h4 class="text-primary">المتحدثون</h4>
                  <div *ngFor="let participant of event.participants" class="d-flex gap-3 mt-4">
                    <div>
                      <img
                        [src]="participant.participant.image | imageLink"
                        style="width: 80px; aspect-ratio: 1/1; object-fit: cover"
                        class="rounded-circle border border-primary-subtle border-3"
                        [alt]="participant.participant.name"
                      />
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <div class="fw-bold">{{ participant.participant.name }}</div>
                      <div [innerText]="participant.participant.biography" class="lh-sm"></div>
                    </div>
                  </div>
                </ng-container>

                <div *ngIf="event.link" class="text-center mt-5">
                  <a i18n [href]="event.link" class="btn btn-primary btn-lg px-4">تعرف علي المزيد</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>
