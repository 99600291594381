<app-page>
  <page-header></page-header>
  <page-content>
    <div id="news-single">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-10 mx-auto">
            <div class="card rounded-0">
              <div class="card-body p-5">
                <h3 data-tts="title" class="text-primary">{{ pieceOfNews.title }}</h3>

                <p class="fw-light small mb-0">
                  <fa-icon [icon]="faCalendarMinus" class="me-2 text-light-gray"></fa-icon>
                  {{ pieceOfNews.date | date : "YYYY/MM/dd" }}
                </p>

                <p class="fw-light small mb-0">
                  <fa-icon [icon]="faLocationDot" class="me-2 text-light-gray"></fa-icon>
                  {{ pieceOfNews.country }}
                </p>

                <div class="row mt-4">
                  <div class="col-12 d-flex justify-content-center">
                    <img [src]="pieceOfNews.image | imageLink" class="news-image" [alt]="pieceOfNews.title" />
                  </div>
                  <div data-tts="content-section-title" hidden i18n>نص الخبر</div>
                  <div class="col-12 mt-5 lead">
                    <div data-tts="content" class="news-content" [innerHTML]="pieceOfNews.content"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>
