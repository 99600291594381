import {Component, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {faCalendarMinus} from "@fortawesome/free-regular-svg-icons";
import {faAnglesLeft, faDownload, faSquare} from "@fortawesome/free-solid-svg-icons";
import {Report, ReportPageData} from "src/app/interfaces/portal/reports-page";

@Component({
  selector: "app-single-report-page",
  templateUrl: "./single-report-page.component.html",
  styleUrls: ["./single-report-page.component.scss"],
})
export class SingleReportPageComponent implements OnInit {
  faCalendarMinus = faCalendarMinus;
  faDownload = faDownload;
  faSquare = faSquare;
  faAnglesLeft = faAnglesLeft;

  report!: Report;
  reports!: Report[];

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.route.params.subscribe(() => {
      const page = this.route.snapshot.data["page"] as ReportPageData;
      this.report = page.data.report;
      this.reports = page.data.reports;
    });
  }

  navigateToReportDetails(reportId: string) {
    this.router.navigateByUrl(`/reports/${reportId}`);
  }
}
