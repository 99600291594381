<app-page>
  <page-header></page-header>
  <page-content>
    <div class="container" style="min-height: 500px">
      <div class="row">
        <div class="col">
          <h3 i18n class="mb-4 mt-5">البحث في الموقع</h3>
          <form (submit)="onSubmit()">
            <div class="input-group">
              <input
                [(ngModel)]="searchKey"
                i18n-placeholder
                name="searchKey"
                type="text"
                class="form-control"
                aria-label="Search"
                placeholder="كلمة البحث"
              />
              <button i18n type="submit" class="btn btn-primary">بحث</button>
            </div>
          </form>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <ul class="list-group mt-4">
            <li class="list-group-item pt-3" *ngFor="let result of searchResults; let i = index">
              <div class="d-flex flex-column flex-sm-row">
                <div class="text-center"></div>
                <div class="ms-3 flex-grow-1 mt-4 mt-md-0">
                  <a *ngIf="result.__typename === 'news'" routerLink="/news/{{ result.id }}" class="h5 search-result-link">
                    {{ result.title || result.name }}
                  </a>
                  <a
                    *ngIf="result.__typename === 'events'"
                    [routerLink]="['/', result.type.slug, result.id]"
                    class="h5 search-result-link"
                  >
                    {{ result.title || result.name }}
                  </a>
                  <a
                    *ngIf="result.__typename === 'publications'"
                    routerLink="/publications//{{ result.id }}"
                    class="h5 search-result-link"
                  >
                    {{ result.title || result.name }}
                  </a>
                  <a
                    *ngIf="result.__typename === 'projects'"
                    routerLink="/projects/{{ result.id }}"
                    class="h5 search-result-link"
                  >
                    {{ result.title || result.name }}
                  </a>
                  <a
                    *ngIf="result.__typename === 'reports'"
                    routerLink="/reports/{{ result.id }}"
                    class="h5 search-result-link"
                  >
                    {{ result.title || result.name }}
                  </a>
                  <div class="d-flex justify-content-end">
                    <a
                      *ngIf="result.__typename === 'news'"
                      routerLink="/news/{{ result.id }}"
                      class="btn btn-primary mt-2 float-left"
                      i18n
                    >
                      اقرأ
                    </a>
                    <a
                      *ngIf="result.__typename === 'events'"
                      [routerLink]="['/', result.type.slug, result.id]"
                      class="btn btn-primary mt-2 float-left"
                      i18n
                    >
                      اقرأ
                    </a>
                    <a
                      *ngIf="result.__typename === 'publications'"
                      routerLink="/publications//{{ result.id }}"
                      class="btn btn-primary mt-2 float-left"
                      i18n
                    >
                      اقرأ
                    </a>
                    <a
                      *ngIf="result.__typename === 'projects'"
                      routerLink="/projects/{{ result.id }}"
                      class="btn btn-primary mt-2 float-left"
                      i18n
                    >
                      اقرأ
                    </a>
                    <a
                      *ngIf="result.__typename === 'reports'"
                      routerLink="/reports/{{ result.id }}"
                      class="btn btn-primary mt-2 float-left"
                      i18n
                    >
                      اقرأ
                    </a>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <nav aria-label="Page navigation" class="mt-4">
            <ul class="pagination">
              <li class="page-item" [ngClass]="{disabled: currentPage === 0}">
                <a class="page-link cursor-pointer" (click)="onPageChange(currentPage - 1)" i18n>السابق</a>
              </li>
              <li class="page-item">
                <a class="page-link active">{{ currentPage + 1 }}</a>
              </li>
              <li class="page-item" [ngClass]="{disabled: currentPage + 1 >= totalPages}">
                <a class="page-link cursor-pointer" (click)="onPageChange(currentPage + 1)" i18n>التالى</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>
