import {Inject, Injectable, LOCALE_ID} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from "@angular/router";
import {Observable} from "rxjs";

import {Locale} from "../enums/locale";

@Injectable({
  providedIn: "root",
})
export class LanguageGuard implements CanActivate {
  constructor(@Inject(LOCALE_ID) private locale: Locale, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const locales = route.data["locales"] as Locale | Locale[] | undefined | null;
    if (!locales) {
      return this.router.parseUrl("/");
    }

    if (Array.isArray(locales)) {
      const allow = locales.includes(this.locale);
      if (allow) {
        return true;
      } else {
        return this.router.parseUrl("/");
      }
    }

    if (this.locale !== locales) {
      return this.router.parseUrl("/");
    }

    return true;
  }
}
