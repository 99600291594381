import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Apollo} from "apollo-angular";
import {map, Observable} from "rxjs";
import {PageType} from "src/app/enums/page-type";
import {EventCategoryPage, EventCategoryPageData} from "src/app/interfaces/portal/event-category-page";
import {GET_EVENT_CATEGORY_PAGE} from "src/app/queries/portal/event-category-page.query";
import {PortalContentService} from "src/app/services/portal-content.service";
import {SeoService} from "src/app/services/seo.service";
import {environment} from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class EventCategoryPageResolver implements Resolve<EventCategoryPageData> {
  constructor(private apollo: Apollo, private seoService: SeoService, private cmsService: PortalContentService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<EventCategoryPageData> {
    const {slug} = route.data;
    return this.apollo
      .query({
        query: GET_EVENT_CATEGORY_PAGE,
        variables: {
          currentDate: new Date().toISOString().split("T")[0],
          upcomingEventsLimit: environment.limit.upcomingEvents,
          slug,
        },
      })
      .pipe(
        map((result): EventCategoryPageData => {
          const {page: meta, ...page} = result.data;
          const trueMeta: EventCategoryPage = {
            title: this.cmsService.mapEventSlugToCategoryPageName(slug[0]),
            image: meta.image,
          };

          return this.seoService.patchTitleAndMetaTags(route, {
            title: trueMeta.title,
            breadcrumbs: [
              {title: $localize`البوابة`, routerLink: ""},
              {title: trueMeta.title, routerLink: this.cmsService.mapEventSlugToCategoryPageRoute(slug[0])},
            ],
            data: page.upcomingEvents,
            meta: trueMeta,
            type: PageType.INTERIOR,
          });
        }),
      );
  }
}
