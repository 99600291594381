<div class="d-flex align-items-center gap-2">
  <div class="dropdown">
    <button
      #filterMenuToggle
      class="btn btn-secondary dropdown-toggle"
      type="button"
      data-bs-toggle="dropdown"
      data-bs-auto-close="outside"
      aria-expanded="false"
      [matBadge]="appliedFields"
      [matBadgeHidden]="appliedFields <= 0"
      matBadgeSize="small"
      matBadgeColor="warn"
      i18n
    >
      بحث
    </button>

    <div class="dropdown-menu dropdown-menu-end" style="width: 300px">
      <form
        class="px-4 py-3"
        [formGroup]="filterFormGroup"
        (ngSubmit)="onFilterFormSubmit()"
        (keydown.enter)="onFilterFormSubmit($event)"
        aria-hidden="true"
      >
        <ng-container *ngFor="let field of fields">
          <div class="input-group mb-3">
            <input
              *ngIf="field.type === 'text'"
              type="text"
              class="form-control"
              [placeholder]="field.name"
              [formControlName]="field.key"
            />
            <select
              *ngIf="field.type === 'select'"
              class="form-select"
              [attr.aria-label]="field.name"
              [formControlName]="field.key"
            >
              <option [ngValue]="undefined" disabled selected>{{ field.name }}</option>
              <option *ngFor="let option of getOptions(field.options)" [value]="option.value">{{ option.text }}</option>
            </select>
            <ng-container *ngIf="field.type === 'tristate'">
              <div class="form-check">
                <input
                  class="form-check-input"
                  [id]="id + '-' + field.key"
                  type="checkbox"
                  (click)="rotateState(field.key)"
                  [checked]="getControlValue(field.key) === true"
                  [indeterminate]="getControlValue(field.key) === undefined"
                />
                <label class="form-check-label" [for]="id + '-' + field.key">{{ field.name }}</label>
              </div>
            </ng-container>

            <button *ngIf="field.type !== 'tristate'" class="btn btn-outline-danger" (click)="resetField(field.key)">
              <fa-icon [icon]="faXmark"></fa-icon>
            </button>
          </div>
        </ng-container>
        <div class="d-flex justify-content-end">
          <button i18n type="submit" class="btn btn-primary" [disabled]="filterFormGroup.invalid">بحث</button>
        </div>
      </form>
    </div>
  </div>
  <button class="small-icon-button" mat-icon-button title="إزالة البحث" i18n-title (click)="resetFilter()">
    <mat-icon>close</mat-icon>
  </button>
</div>
