<app-page>
  <page-header></page-header>
  <page-content>
    <h1 class="text-center mt-5 text-primary">{{ userEmail }}</h1>
    <h1 *ngIf="!success && userEmail" class="text-center mt-5">
      <ng-container i18n>لإلغاء الاشتراك في النشرة البريدية اضغط</ng-container>
      <a i18n class="text-danger cursor-pointer" (click)="cancelSubscription()">هنا</a>
    </h1>
    <h3 i18n *ngIf="success" class="alert alert-success text-center w-50 mx-auto mt-5">تم إلغاء الاشتراك بنجاح</h3>
    <h3 i18n *ngIf="hasError" class="alert alert-danger text-center w-50 mx-auto mt-5">
      حدثت مشكلة برجاء التواصل مع الدعم الفني
    </h3>
  </page-content>
  <page-footer></page-footer>
</app-page>
