import {gql} from "apollo-angular";
import {I18nFunctions} from "src/app/interfaces/i18n";
import {NumbersPage, OfficeNumber} from "src/app/interfaces/portal/numbers-page";

export const GET_NUMBERS_PAGE = ({field, filter}: I18nFunctions) => gql<{page: NumbersPage; numbers: OfficeNumber[]}, void>`
  query {
    page: numbers_page {
      title: ${field("title")}
      image {
        id
        filename: filename_download
        title
      }
    }
    numbers(filter: {_and: [{status: {_eq: "published"}}, ${filter("description")}]}, sort: ["-date_created"]) {
      value: number
      description: ${field("description")}
    }
  }
`;
