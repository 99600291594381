import {DOCUMENT} from "@angular/common";
import {Component, Inject, OnDestroy} from "@angular/core";
import {NavigationEnd, Router} from "@angular/router";
import {filter, ReplaySubject, takeUntil} from "rxjs";

@Component({
  selector: "app-portal",
  templateUrl: "./portal.component.html",
  styleUrls: ["./portal.component.scss"],
})
export class PortalComponent implements OnDestroy {
  unsubscribe$ = new ReplaySubject(1);

  constructor(private router: Router, @Inject(DOCUMENT) private document: Document) {
    this.subscribeToRouterEvents();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(1);
    this.unsubscribe$.complete();
    this.clearBodyClass();
  }

  subscribeToRouterEvents() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(() => {
        this.updateBodyClass();
      });
  }

  updateBodyClass() {
    const isRoot = ["", "/"].includes(this.router.url);
    if (isRoot) {
      this.document.body.classList.remove("subPage");
      this.document.body.classList.remove("world-bg");
    } else {
      this.document.body.classList.add("subPage");
      this.document.body.classList.add("world-bg");
    }
  }

  clearBodyClass() {
    this.document.body.classList.remove("subPage");
    this.document.body.classList.remove("world-bg");
  }
}
