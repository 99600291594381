import {gql} from "apollo-angular";
import {NewsLetter} from "src/app/interfaces/newsletters";
import {UnsubscribePage} from "src/app/interfaces/portal/unsubscribe-page";

export const SUBSCRIBE_TO_NEWSLETTERS = gql<void, {email: string}>`
  mutation ($email: String!) {
    create_newsletters_item(data: {email: $email}) {
      id
      email
    }
  }
`;

export const GET_SUBSCRIBED_USER_BY_EMAIL = gql<{newsletters: NewsLetter}, {email: string}>`
  query ($email: String!) {
    newsletters(filter: {email: {_eq: $email}}) {
      id
      email
    }
  }
`;

export const GET_UNSUBSCRIBED_PAGE_DATA = gql<{page: UnsubscribePage; newsletters: NewsLetter}, {userId: string}>`
  query ($userId: ID!) {
    page: contact_us_page {
      image {
        id
        filename: filename_download
        title
      }
    }
    newsletters: newsletters_by_id(id: $userId) {
      id
      email
    }
  }
`;
