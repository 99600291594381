import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Apollo} from "apollo-angular";
import {map, Observable} from "rxjs";
import {PageType} from "src/app/enums/page-type";
import {PublicationPageData} from "src/app/interfaces/portal/publications-page";
import {GET_PUBLICATION_PAGE} from "src/app/queries/portal/publications-page.query";
import {SeoService} from "src/app/services/seo.service";
import {WindowService} from "src/app/services/window.service";

@Injectable({
  providedIn: "root",
})
export class SinglePublicationResolver implements Resolve<PublicationPageData> {
  constructor(private apollo: Apollo, private windowService: WindowService, private seoService: SeoService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<PublicationPageData> {
    const publicationId = route.params["publicationId"];
    return this.apollo
      .query({
        query: GET_PUBLICATION_PAGE,
        variables: {
          publicationId,
        },
      })
      .pipe(
        map((result): PublicationPageData => {
          const {page: meta, ...page} = result.data;
          return this.seoService.patchTitleAndMetaTags(route, {
            title: page.publications[0].name,
            description: this.windowService.getFriendlyDescription(page.publications[0].summary),
            breadcrumbs: [
              {title: $localize`البوابة`, routerLink: ""},
              {title: meta.title, routerLink: "publications"},
              {title: page.publications[0].name, routerLink: `publications/${page.publications[0].id}`},
            ],
            data: {publication: page.publications[0], infographics: page.infographics},
            meta,
            type: PageType.INTERIOR,
          });
        }),
      );
  }
}
