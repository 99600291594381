import {gql} from "apollo-angular";
import {I18nFunctions} from "src/app/interfaces/i18n";
import {ProjectFilter, ProjectSort, ProjectsPage, ProjectsPageListItem} from "src/app/interfaces/portal/projects-page";

export const GET_PROJECTS_PAGE = ({field, filter}: I18nFunctions) => gql<{page: ProjectsPage}, void>`
  query {
    page: projects_page {
      title: ${field("title")}
      image {
        id
        filename: filename_download
        title
      }
    }
  }
`;

export const getProjectsByFilterFactory =
  (filter?: ProjectFilter, sort: ProjectSort = "-date_created") =>
  ($t: I18nFunctions) => {
    const variables: string[] = [];
    const expressions: string[] = ['{status: {_eq: "published"}}', $t.filter("name")];

    variables.push("$page: Int!");
    variables.push("$limit: Int!");

    if (filter) {
      if (filter.year) {
        variables.push("$year: String!");
        expressions.push(`{year: {_icontains: $year}}`);
      }
      if (filter.center) {
        variables.push("$center: String!");
        expressions.push(`{${$t.field("center_name")}: {_icontains: $center}}`);
      }
    }

    const queryVariables = variables.length > 0 ? `(${variables.join(", ")})` : "";
    const queryFilter = expressions.length > 1 ? `{_and: [${expressions.join(", ")}]}` : expressions.join("");

    return gql<{projects: ProjectsPageListItem[]}, {limit: number; page: number; year?: string; center?: string}>`
    query ${queryVariables} {
      projects(
        filter: ${queryFilter}
        sort: ["${sort}"]
        page: $page
        limit: $limit
      ) {
        id
        name: ${$t.field("name")}
        summary: ${$t.field("summary")}
        year
        image {
          id
          filename: filename_download
          title
        }
        goals: ${$t.field("goals")}
        center: ${$t.field("center_name")}
        links
        sponsors {
          sponsor: sponsors_id {
            name: ${$t.field("name")}
            image {
              id
              filename: filename_download
              title
            }
            link
          }
        }
      }
    }
  `;
  };
