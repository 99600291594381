import {gql} from "apollo-angular";
import {I18nFunctions} from "src/app/interfaces/i18n";
import {AgreementsPage, AgreementsPageListItem} from "src/app/interfaces/portal/agreements-page";

export const GET_AGREEMENTS_PAGE = ({field, filter}: I18nFunctions) => gql<
  {page: AgreementsPage; agreements: AgreementsPageListItem[]},
  {currentDate: Date}
>`
  query {
    page: agreements_page {
      title: ${field("title")}
      image {
        id
        filename: filename_download
        title
      }
      content: ${field("content")}
    }
    agreements(filter: {_and: [{status: {_eq: "published"}}]}) {
      id
      image {
        id
        filename: filename_download
        title
      }
    }
  }
`;
