<!-- eslint-disable @angular-eslint/template/elements-content -->
<div id="home-projects-carousel" class="pb-3 pt-3">
  <div class="container">
    <div class="d-none d-lg-flex justify-content-center align-items-center">
      <h3 class="text-secondary fw-bold text-center" style="font-size: var(--home-section-title-fontSize)">
        {{ title }}
      </h3>
    </div>
    <div class="d-flex justify-content-between align-items-center justify-content-lg-end m-2">
      <h3 class="text-secondary fw-bold text-center d-lg-none m-0" style="font-size: var(--home-section-title-fontSize)">
        {{ title }}
      </h3>
      <div class="landing-swiper-controls">
        <button class="btn navigation-btn prev" (click)="next()">
          <fa-icon [icon]="faArrowLeft" class="fa-lg"></fa-icon>
        </button>

        <button class="btn navigation-btn next" (click)="previous()">
          <fa-icon [icon]="faArrowRight" class="fa-lg"></fa-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="carousel mb-5">
    <swiper-container swiper #swiperRef [config]="swiperOptions" init="false">
      <swiper-slide *ngFor="let slide of projects; let i = index">
        <div class="single-project-card-item">
          <div class="card rounded-0 border-0">
            <img
              [routerLink]="['/projects', slide.id]"
              [src]="slide.image | imageLink"
              class="card-img rounded-0 cursor-pointer"
              [alt]="slide.name"
            />
            <div class="card-img-overlay rounded-0">
              <h5
                style="font-size: var(--projects-card-title-fontSize)"
                class="card-title fw-bold mb-3 tw-line-clamp-1 tw-leading-[1.5rem] tw-min-h-[1.5rem]"
              >
                {{ slide.name }}
              </h5>
              <p
                style="font-size: var(--projects-card-description-fontSize)"
                class="card-text tw-line-clamp-2 tw-leading-[1.5rem] tw-min-h-[3rem]"
              >
                {{ slide.summary }}
              </p>
              <a [routerLink]="['/projects', slide.id]" class="stretched-link"></a>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper-container>
  </div>
</div>
