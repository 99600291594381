import {gql} from "apollo-angular";
import {I18nFunctions} from "src/app/interfaces/i18n";
import {NewsFilter, NewsPage, NewsPageListItem, NewsSort} from "src/app/interfaces/portal/news-page";

export const GET_NEWS_PAGE = ({field, filter}: I18nFunctions) => gql<{page: NewsPage}, void>`
  query {
    page: news_page {
      title: ${field("title")}
      image {
        id
        filename: filename_download
        title
      }
    }
  }
`;

export const getNewsByFilterFactory =
  (filter?: NewsFilter, sort: NewsSort = "-date") =>
  ($t: I18nFunctions) => {
    const variables: string[] = [];
    const expressions: string[] = ['{status: {_eq: "published"}}', $t.filter("title")];

    variables.push("$page: Int!");
    variables.push("$limit: Int!");

    if (filter) {
      if (filter.title) {
        variables.push("$title: String!");
        expressions.push(`{${$t.field("title")}: {_icontains: $title}}`);
      }
      if (filter.content) {
        variables.push("$content: String!");
        expressions.push(`{${$t.field("content")}: {_icontains: $content}}`);
      }
    }

    const queryVariables = variables.length > 0 ? `(${variables.join(", ")})` : "";
    const queryFilter = expressions.length > 1 ? `{_and: [${expressions.join(", ")}]}` : expressions.join("");

    return gql<{news: NewsPageListItem[]}, {limit: number; page: number; title?: string; content?: string}>`
    query ${queryVariables} {
      news(
        filter: ${queryFilter}
        sort: ["${sort}"]
        page: $page
        limit: $limit
      ) {
        id
        title: ${$t.field("title")}
        date
        thumbnail {
          id
          filename: filename_download
          title
        }
        image {
          id
          filename: filename_download
          title
        }
        country: ${$t.field("country")}
      }
    }
  `;
  };
