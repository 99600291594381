import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Apollo} from "apollo-angular";
import {map, Observable} from "rxjs";
import {PageType} from "src/app/enums/page-type";
import {ReportPageData} from "src/app/interfaces/portal/reports-page";
import {GET_REPORT_PAGE} from "src/app/queries/portal/reports-page.query";
import {SeoService} from "src/app/services/seo.service";
import {WindowService} from "src/app/services/window.service";
import {environment} from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class SingleReportPageResolver implements Resolve<ReportPageData> {
  constructor(private apollo: Apollo, private windowService: WindowService, private seoService: SeoService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<ReportPageData> {
    const reportId = route.params["reportId"];
    return this.apollo
      .query({
        query: GET_REPORT_PAGE,
        variables: {
          reportId,
          reportsLimit: environment.limit.reportsSidebar,
        },
      })
      .pipe(
        map((result) => {
          const {page: meta, ...page} = result.data;
          return this.seoService.patchTitleAndMetaTags(route, {
            title: page.report.title,
            description: this.windowService.getFriendlyDescription(page.report.content),
            breadcrumbs: [
              {title: $localize`البوابة`, routerLink: ""},
              {title: meta.title, routerLink: "reports"},
              {title: page.report.title, routerLink: `reports/${page.report.id}`},
            ],
            data: page,
            meta,
            type: PageType.INTERIOR,
          });
        }),
      );
  }
}
