import {Injectable} from "@angular/core";
import {Meta, Title} from "@angular/platform-browser";
import {ActivatedRouteSnapshot} from "@angular/router";
import {environment} from "src/environments/environment";

import {PageData} from "../interfaces/page-data";

@Injectable({
  providedIn: "root",
})
export class SeoService {
  constructor(private titleService: Title, private metaTagService: Meta) {}

  patchTitleAndMetaTags<Data, Meta>(route: ActivatedRouteSnapshot, data: PageData<Data, Meta>) {
    const {title, description} = data;

    let parent = route;
    while (parent.parent) {
      parent = parent.parent;
    }

    const routeTitle = parent.data["group"] || $localize`مكتب التربية العربي لدول الخليج`;

    const pageTitle = `${title} | ${routeTitle}`;

    this.titleService.setTitle(pageTitle);
    const metaTags: Record<string, string> = {
      description: description || pageTitle,
      author: $localize`مكتب التربية العربي لدول الخليج`,
      keywords: [
        $localize`مكتب التربية العربي لدول الخليج`,
        $localize`التربية`,
        $localize`التعليم`,
        $localize`الخليج`,
        $localize`وزارات التعليم`,
        $localize`تطوير`,
        $localize`المناهج التعليمية`,
        $localize`المعلمين`,
        $localize`الطلاب`,
        $localize`أولياء الأمور`,
        $localize`دولة الإمارات العربية المتحدة`,
        $localize`مملكة البحرين`,
        $localize`الجمهورية اليمنية`,
        $localize`دولة الكويت`,
        $localize`المملكة العربية السعودية`,
        $localize`سلطنة عمان`,
        $localize`دولة قطر`,
      ].join(", "),
    };
    const openGraphTags: Record<string, string> = {
      "og:type": "website",
      "og:title": pageTitle,
      "og:url": `${environment.hostURI}/${route.url.toString()}`,
      "og:description": description || pageTitle,
      "og:image": `${environment.hostURI}/assets/images/abegs.svg`,
      "og:image:type": "image/svg+xml",
      "og:image:alt": $localize`مكتب التربية العربي لدول الخليج`,
      "og:site_name": $localize`مكتب التربية العربي لدول الخليج`,
    };

    Object.keys(metaTags).forEach((key) => {
      this.metaTagService.updateTag({
        name: key,
        content: metaTags[key],
      });
    });
    Object.keys(openGraphTags).forEach((key) => {
      this.metaTagService.updateTag({
        property: key,
        content: openGraphTags[key],
      });
    });

    return data;
  }
}
