import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ModalService {
  private _openModal = new BehaviorSubject<boolean>(false);
  isModalOpen() {
    return this._openModal.asObservable();
  }

  setModalState(isOpen: boolean) {
    return this._openModal.next(isOpen);
  }
}
