import {Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {faEllipsisVertical} from "@fortawesome/free-solid-svg-icons";
import {Department, DepartmentsPageData} from "src/app/interfaces/portal/departments-page";

@Component({
  selector: "app-departments-page",
  templateUrl: "./departments-page.component.html",
  styleUrls: ["./departments-page.component.scss"],
})
export class DepartmentsPageComponent implements OnInit {
  faElilipsisVertical = faEllipsisVertical;

  loading = false;
  departments!: Department[];

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    const page = this.route.snapshot.data["page"] as DepartmentsPageData;
    this.departments = page.data.departments;
  }
}
