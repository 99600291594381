import {DOCUMENT} from "@angular/common";
import {HttpStatusCode} from "@angular/common/http";
import {Component, Inject, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {faCircleNotch, faEarListen, faPause, faPlay, faRotate, faWarning} from "@fortawesome/free-solid-svg-icons";
import {ReplaySubject, takeUntil} from "rxjs";
import {environment} from "src/environments/environment";

interface Alignment {
  words: string[];
  offsets_in_seconds: number[];
}

interface Speech {
  audio_file: {
    url: string;
    duration_in_seconds: number;
  };
  alignments: Record<string, Alignment>;
}

@Component({
  selector: "app-tts-control",
  templateUrl: "./tts-control.component.html",
  styleUrls: ["./tts-control.component.scss"],
})
export class TtsControlComponent implements OnInit, OnDestroy {
  unsubscribe$ = new ReplaySubject<void>();

  faEarListen = faEarListen;
  faPlay = faPlay;
  faPause = faPause;
  faRotate = faRotate;
  faWarning = faWarning;
  faCircleNotch = faCircleNotch;

  enabled = false;
  loading = false;
  show = false;
  playing = false;
  paused = false;
  error = false;

  keys: string[] = [];
  alignments: {word: string; offset_in_seconds: number; key: string; index: number}[] = [];

  audio?: HTMLAudioElement;

  constructor(private route: ActivatedRoute, @Inject(DOCUMENT) private document: Document) {}

  ngOnInit(): void {
    this.route.data.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.reset();
      this.enabled = data["tts"] === true;
      if (this.enabled) {
        this.load();
      }
    });
  }

  ngOnDestroy(): void {
    this.reset();

    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  reset() {
    this.enabled = false;
    this.loading = false;
    this.show = false;
    this.playing = false;
    this.paused = false;
    this.error = false;

    this.keys = [];
    this.alignments = [];

    this.audio?.pause();
    delete this.audio;
  }

  async load() {
    this.loading = true;
    const speech = await this.fetchData();
    if (!speech) {
      this.loading = false;
      this.show = false;
      this.error = true;
      return;
    }

    this.keys = Object.keys(speech.alignments);
    this.alignments = [];
    for (const key in speech.alignments) {
      const {words, offsets_in_seconds} = speech.alignments[key];
      for (let i = 0; i < words.length; i++) {
        const word = words[i];
        const offset_in_seconds = offsets_in_seconds[i];
        this.alignments.push({
          word,
          offset_in_seconds,
          key,
          index: i,
        });
      }
    }
    this.alignments.sort((a, b) => a.offset_in_seconds - b.offset_in_seconds);

    this.prepareDom();

    this.prepareAudio(speech.audio_file);

    this.loading = false;
    this.show = true;
  }

  pause() {
    this.paused = true;
    this.audio?.pause();
  }

  play() {
    this.paused = false;
    this.audio
      ?.play()
      .then(() => {
        this.playing = true;
      })
      .catch((error) => {
        console.error(error);
        this.error = true;
      });
  }

  restart() {
    if (this.audio) {
      this.audio.currentTime = 0;
      this.play();
    }
  }

  prepareAudio(audio_file: Speech["audio_file"]) {
    this.audio = new Audio(audio_file.url);

    let prevElement: Element | null;
    this.audio.addEventListener("timeupdate", () => {
      if (!this.audio) return;

      const currentTime = this.audio.currentTime;
      for (let i = 0; i < this.alignments.length; i++) {
        const {offset_in_seconds: currentOffset, key, index} = this.alignments[i];
        const nextOffset =
          i === this.alignments.length - 1 ? audio_file.duration_in_seconds : this.alignments[i + 1].offset_in_seconds;

        if (currentTime >= currentOffset && currentTime < nextOffset) {
          const element = document.querySelector(`[data-tts="${key}"] [data-tts-index="${index}"]`);
          element?.classList.add("highlight");
          // element?.scrollIntoView({behavior: "smooth", inline: "center", block: "center"});
          if (prevElement && prevElement !== element) {
            prevElement.classList.remove("highlight");
          }
          prevElement = element;
        }
      }
    });

    this.audio.addEventListener("ended", () => {
      document.querySelectorAll(`[data-tts-index]`).forEach((element) => {
        element.classList.remove("highlight");
      });
    });
  }

  prepareDom() {
    const ranges: {range: Range; index: number}[] = [];

    for (const key of this.keys) {
      const container = this.document.querySelector(`[data-tts="${key}"]`);
      if (!container) continue;

      const walker = this.document.createTreeWalker(container, NodeFilter.SHOW_TEXT);
      const words: {node: Text; value: string; start: number}[] = [];

      let currentNode: Node | null;
      while ((currentNode = walker.nextNode())) {
        const node = currentNode as Text;

        let value = "";
        for (let i = 0; i < node.data.length; i++) {
          const c = node.data[i];

          if (!/\s/g.test(c)) {
            value += c;
          }

          if ((/\s/g.test(c) || i === node.data.length - 1) && value.length > 0) {
            const end = i === node.data.length - 1 ? i : i - 1;
            words.push({
              node,
              value,
              start: end - value.length + 1,
            });
            value = "";
          }
        }
      }

      const alignments = this.alignments.filter((alignment) => alignment.key === key);

      let lastIndex = 0;
      for (const alignment of alignments) {
        for (let i = lastIndex; i < words.length; i++) {
          const word = words[i];
          if (word.value === alignment.word) {
            const range = document.createRange();
            range.setStart(word.node, word.start);
            range.setEnd(word.node, word.start + word.value.length);
            ranges.push({range, index: alignment.index});
            lastIndex++;
            break;
          }
        }
      }
    }

    for (const {range, index} of ranges) {
      const span = document.createElement("span");
      span.dataset["ttsIndex"] = index.toString();
      range.surroundContents(span);
    }
  }

  async fetchData(): Promise<Speech | null> {
    const result = await fetch(`${environment.tts.host}/tts`, {
      method: "GET",
      referrerPolicy: "unsafe-url",
    }).catch((error) => {
      console.error(error);
      return null;
    });
    if (!result || !result.ok || result.status === HttpStatusCode.NoContent) return null;

    const json = await result.json();
    return json as Speech;
  }
}
