<app-page>
  <page-header></page-header>
  <page-content>
    <div class="container">
      <div class="row my-5">
        <div class="col-md-6 my-4" *ngFor="let bookStep of bookSteps">
          <div class="d-flex align-items-start gap-4 mb-5">
            <img class="icon-img" [src]="bookStep.icon | imageLink" [alt]="bookStep.title" />
            <div class="d-flex flex-column">
              <h5 class="mt-4 fw-bold">{{ bookStep.title }}</h5>
              <div class="p mt-3 text-light-gray" [innerHTML]="bookStep.description"></div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="mt-4">
          <a
            href="https://booktrans.abegs.org/"
            target="_blank"
            class="d-flex align-items-center justify-content-center mb-5"
          >
            <fa-icon [icon]="faGlobe" class="me-2"></fa-icon>
            <ng-container i18n>تسجيل الدخول في الموقع</ng-container>
          </a>
        </div>
      </div>
    </div>
  </page-content>

  <page-footer></page-footer>
</app-page>
