<app-page>
  <page-header></page-header>
  <page-content>
    <div class="container">
      <div class="row">
        <h3 data-tts="intro" class="fw-bold text-secondary my-3">
          {{ meta.intro }}
        </h3>
        <div data-tts="content" [innerHTML]="meta.content"></div>
        <div class="d-flex justify-content-center align-items-center my-5">
          <youtube-player [videoId]="videoId"></youtube-player>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 my-4">
          <div class="d-flex align-items-start gap-4 mb-5">
            <div
              style="min-width: 60px; height: 60px"
              class="h4 bg-primary text-light rounded-4 d-flex align-items-center justify-content-center"
            >
              <fa-icon [icon]="faEye"></fa-icon>
            </div>
            <div class="d-flex flex-column">
              <h5 data-tts="vision-section-title" i18n class="mt-4 fw-bold">الرؤية</h5>
              <div data-tts="vision" class="p mt-3 text-light-gray" [innerHTML]="meta.vision"></div>
            </div>
          </div>

          <div class="d-flex align-items-start gap-4 mb-5">
            <div
              style="min-width: 60px; height: 60px"
              class="h4 bg-primary text-light rounded-4 d-flex align-items-center justify-content-center"
            >
              <fa-icon [icon]="faLightbulb"></fa-icon>
            </div>
            <div class="d-flex flex-column">
              <h5 data-tts="mission-section-title" i18n class="mt-4 fw-bold">الرسالة</h5>
              <div data-tts="mission" class="p mt-3 text-light-gray" [innerHTML]="meta.mission"></div>
            </div>
          </div>

          <div class="d-flex align-items-start gap-4 mb-5">
            <div
              style="min-width: 60px; height: 60px"
              class="h4 bg-primary text-light rounded-4 d-flex align-items-center justify-content-center"
            >
              <fa-icon [icon]="faSun"></fa-icon>
            </div>
            <div class="d-flex flex-column">
              <h5 data-tts="values-section-title" i18n class="mt-4 fw-bold">القيم</h5>
              <div data-tts="values" class="mt-3 text-light-gray" style="max-width: 381px">
                <button
                  *ngFor="let value of values"
                  type="button"
                  class="btn btn-outline-dark me-3 mb-3 px-4 py-2 hover-bg-primary hover-border-primary"
                  style="min-width: 115px"
                >
                  {{ value.text }}
                </button>
              </div>
            </div>
          </div>

          <div class="d-flex align-items-start gap-4 mb-5">
            <div
              style="min-width: 60px; height: 60px"
              class="h4 bg-primary text-light rounded-4 d-flex align-items-center justify-content-center"
            >
              <fa-icon [icon]="faPen"></fa-icon>
            </div>
            <div class="d-flex flex-column">
              <h5 data-tts="goals-section-title" i18n class="mt-4 fw-bold">الأهداف</h5>
              <div id="goals" data-tts="goals" class="p mt-3 text-light-gray" [innerHTML]="meta.goals"></div>
            </div>
          </div>
        </div>

        <div class="col-md-6 my-4">
          <div *ngFor="let document of documents; index as index" class="row mt-4">
            <div class="col-md-4">
              <img class="img-fluid" [src]="document.image | imageLink" [alt]="document.title" />
              <a [href]="document.file | fileLink" target="_blank" class="mt-2 d-inline-block small">
                <fa-icon [icon]="faCloudArrowDown" class="me-1"></fa-icon>
                <ng-container i18n>تحميل</ng-container>
              </a>
            </div>
            <div class="col-md-8">
              <h6 [attr.data-tts]="'document-' + index + '-title'" class="fw-bold">{{ document.title }}</h6>
              <p [attr.data-tts]="'document-' + index + '-summary'" class="small text-light-gray">
                {{ document.summary }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>
