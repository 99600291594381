import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {ReCaptchaV3Service} from "ng-recaptcha";
import {ReplaySubject, switchMap, takeUntil} from "rxjs";
import {UnsubscribePage, UnsubscribePageData} from "src/app/interfaces/portal/unsubscribe-page";
import {ApiService} from "src/app/services/api.service";
import {environment} from "src/environments/environment";

@Component({
  selector: "app-unsubscribe-page",
  templateUrl: "./unsubscribe-page.component.html",
  styleUrls: ["./unsubscribe-page.component.scss"],
})
export class UnsubscribePageComponent implements OnInit, OnDestroy {
  page!: UnsubscribePage;
  userEmail!: string;
  userId!: string;

  sending!: boolean;
  success!: boolean;
  hasError!: boolean;

  unsubscribe$ = new ReplaySubject(1);

  constructor(
    private route: ActivatedRoute,
    private recapatchaV3Service: ReCaptchaV3Service,
    private apiService: ApiService,
  ) {}

  ngOnInit(): void {
    const {meta, ...page} = this.route.snapshot.data["page"] as UnsubscribePageData;
    this.page = meta;
    this.userEmail = page.data.email;
    this.userId = page.data.id;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(1);
    this.unsubscribe$.complete();
  }

  cancelSubscription() {
    this.sending = true;
    this.success = this.hasError = false;
    this.recapatchaV3Service
      .execute(environment.recaptcha.cancelSubscriptionAction)
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap((token) => {
          return this.apiService.submitAction(environment.recaptcha.cancelSubscriptionAction, token, {userId: this.userId});
        }),
      )
      .subscribe({
        next: () => {
          this.sending = false;
          this.success = true;
        },
        error: () => {
          this.sending = false;
          this.hasError = true;
        },
      });
  }
}
