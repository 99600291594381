import {Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {faCalendarMinus, faLocationDot} from "@fortawesome/free-solid-svg-icons";
import {PieceOfNews, SingleNewsPage, SingleNewsPageData} from "src/app/interfaces/portal/single-news-page";

@Component({
  selector: "app-single-news-page",
  templateUrl: "./single-news-page.component.html",
  styleUrls: ["./single-news-page.component.scss"],
})
export class SingleNewsPageComponent implements OnInit {
  faCalendarMinus = faCalendarMinus;
  faLocationDot = faLocationDot;

  meta!: SingleNewsPage;
  pieceOfNews!: PieceOfNews;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    const {meta, ...page} = this.route.snapshot.data["page"] as SingleNewsPageData;

    this.meta = meta;
    this.pieceOfNews = page.data;
  }
}
