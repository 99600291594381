import {Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {CarouselSlide} from "src/app/interfaces/carousel-slide";
import {DirectusFile} from "src/app/interfaces/directus-file";
import {ContactUsPage} from "src/app/interfaces/portal/contact-us-page";
import {
  LandingEventSlide,
  LandingHeroType,
  LandingNewsSlide,
  LandingPage,
  LandingPageData,
  LandingProjectSlide,
  LandingServiceSlide,
} from "src/app/interfaces/portal/landing-page";

@Component({
  selector: "app-landing-page",
  templateUrl: "./landing-page.component.html",
  styleUrls: ["./landing-page.component.scss"],
})
export class LandingPageComponent implements OnInit {
  LandingHeroType = LandingHeroType;

  meta!: LandingPage;

  carouselSlides!: CarouselSlide[];
  newsSlides!: LandingNewsSlide[];
  eventsSlides!: LandingEventSlide[];
  servicesSlides!: LandingServiceSlide[];
  projectsSlides!: LandingProjectSlide[];
  contactus!: ContactUsPage;
  heroVideo!: DirectusFile | null;
  heroType!: LandingHeroType;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    const {meta, ...page} = this.route.snapshot.data["page"] as LandingPageData;
    this.meta = meta;
    this.carouselSlides = page.data.carousel;
    this.newsSlides = page.data.news;
    this.eventsSlides = page.data.events || [];
    this.servicesSlides = page.data.services;
    this.projectsSlides = page.data.projects;
    this.heroVideo = meta.heroVideo;
    this.heroType = meta.heroType;
  }
}
