import {Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {CountriesPageData, CountriesPageListItem} from "src/app/interfaces/portal/countries-page";

@Component({
  selector: "app-countries-page",
  templateUrl: "./countries-page.component.html",
  styleUrls: ["./countries-page.component.scss"],
})
export class CountriesPageComponent implements OnInit {
  countries!: CountriesPageListItem[];

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    const page = this.route.snapshot.data["page"] as CountriesPageData;
    this.countries = page.data;
  }
}
