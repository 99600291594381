<app-page>
  <page-header></page-header>
  <page-content>
    <div id="reports">
      <div class="container">
        <div class="d-flex justify-content-end mb-3">
          <app-search-menu [fields]="filterFields" (search)="onSubmit($event)" (clear)="onClear()"></app-search-menu>
        </div>
        <div class="row">
          <div *ngFor="let mediaReport of mediaReports" class="col-md-6 col-12 mt-lg-0">
            <div class="single-report-card card mb-5 rounded-0">
              <div class="row g-0">
                <div class="col-lg-5">
                  <img
                    [src]="mediaReport.image | imageLink"
                    class="img-fluid w-100 cover report-image"
                    style="height: 250px; cursor: initial"
                    [alt]="mediaReport.image.title"
                  />
                </div>
                <div class="col">
                  <div class="card-body d-flex flex-column h-100">
                    <div class="d-flex justify-content-between align-items-center">
                      <span class="d-flex align-items-center gap-2 text-secondary">
                        <fa-icon [icon]="faSquare"></fa-icon>
                        <ng-container>{{ mediaReport.type.name }}</ng-container>
                      </span>

                      <span class="small gr">{{ mediaReport.date | date : "YYYY/MM/dd" }}</span>
                    </div>
                    <h5 class="card-title fw-bold text-light-gray mt-3">{{ mediaReport.title }}</h5>
                    <h6 class="card-title text-light-gray mt-3 tw-line-clamp-6" style="line-height: 1.5rem">
                      {{ mediaReport.description }}
                    </h6>

                    <div class="d-flex justify-content-between align-items-center mt-auto">
                      <a i18n class="btn btn-primary btn-sm rounded-3" [href]="mediaReport.file | fileLink" target="_blank">
                        حمل التقرير
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            i18n
            *ngIf="!loading && (!mediaReports || !mediaReports.length)"
            class="d-flex justify-content-center align-items-center"
            style="min-height: 300px"
          >
            لا توجد بيانات
          </div>
        </div>
        <div *ngIf="loading" class="d-flex align-items-center justify-content-center bg-light" style="min-height: 300px">
          <div class="spinner"></div>
        </div>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>
