/* eslint-disable @typescript-eslint/no-explicit-any */
import {IMAGE_LOADER, ImageLoaderConfig, NgOptimizedImage, registerLocaleData} from "@angular/common";
import {HttpClientModule} from "@angular/common/http";
import ar from "@angular/common/locales/ar";
import {CUSTOM_ELEMENTS_SCHEMA, InjectionToken, makeStateKey, NgModule, TransferState} from "@angular/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatBadgeModule} from "@angular/material/badge";
import {MatButtonModule} from "@angular/material/button";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {MatTooltipModule} from "@angular/material/tooltip";
import {DateFnsAdapter, MatDateFnsModule} from "@angular/material-date-fns-adapter";
import {BrowserModule, provideClientHydration} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {YouTubePlayerModule} from "@angular/youtube-player";
import {ApolloClientOptions, InMemoryCache} from "@apollo/client/core";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {APOLLO_OPTIONS, ApolloModule} from "apollo-angular";
import {HttpLink} from "apollo-angular/http";
import {arEG} from "date-fns/locale";
import {LightgalleryModule} from "lightgallery/angular";
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from "ng-recaptcha";
import {NgChartsModule} from "ng2-charts";
import {environment} from "src/environments/environment";
import {register} from "swiper/element/bundle";

import {AppComponent} from "./app.component";
import {AppRoutingModule} from "./app-routing.module";
import {AboutPageComponent} from "./components/portal/pages/about-page/about-page.component";
import {AgreementsPageComponent} from "./components/portal/pages/agreements-page/agreements-page.component";
import {BooksPageComponent} from "./components/portal/pages/books-page/books-page.component";
import {CentersPageComponent} from "./components/portal/pages/centers-page/centers-page.component";
import {ContactUsPageComponent} from "./components/portal/pages/contact-us-page/contact-us-page.component";
import {CountriesPageComponent} from "./components/portal/pages/countries-page/countries-page.component";
import {DepartmentsPageComponent} from "./components/portal/pages/departments-page/departments-page.component";
import {SingleDepartmentPageComponent} from "./components/portal/pages/departments-page/single-department-page/single-department-page.component";
import {EventCategoryPageComponent} from "./components/portal/pages/events-page/event-category-page/event-category-page.component";
import {EventsPageComponent} from "./components/portal/pages/events-page/events-page.component";
import {SingleEventPageComponent} from "./components/portal/pages/events-page/single-event-page/single-event-page.component";
import {InfographicGalleryPageComponent} from "./components/portal/pages/infographic-gallery-page/infographic-gallery-page.component";
import {LandingCarouselComponent} from "./components/portal/pages/landing-page/landing-carousel/landing-carousel.component";
import {LandingEventsComponent} from "./components/portal/pages/landing-page/landing-events/landing-events.component";
import {LandingHeroComponent} from "./components/portal/pages/landing-page/landing-hero/landing-hero.component";
import {LandingNewsComponent} from "./components/portal/pages/landing-page/landing-news/landing-news.component";
import {LandingPageComponent} from "./components/portal/pages/landing-page/landing-page.component";
import {LandingProjectsComponent} from "./components/portal/pages/landing-page/landing-projects/landing-projects.component";
import {LandingServicesComponent} from "./components/portal/pages/landing-page/landing-services/landing-services.component";
import {ManagerPageComponent} from "./components/portal/pages/manager-page/manager-page.component";
import {MediaReportsPageComponent} from "./components/portal/pages/media-reports-page/media-reports-page.component";
import {NewsPageComponent} from "./components/portal/pages/news-page/news-page.component";
import {SingleNewsPageComponent} from "./components/portal/pages/news-page/single-news-page/single-news-page.component";
import {NumbersPageComponent} from "./components/portal/pages/numbers-page/numbers-page.component";
import {PrivacyPageComponent} from "./components/portal/pages/privacy-page/privacy-page.component";
import {ProjectsPageComponent} from "./components/portal/pages/projects-page/projects-page.component";
import {SingleProjectPageComponent} from "./components/portal/pages/projects-page/single-project-page/single-project-page.component";
import {PublicationsPageComponent} from "./components/portal/pages/publications-page/publications-page.component";
import {PublicationsSidebarComponent} from "./components/portal/pages/publications-page/publications-sidebar/publications-sidebar.component";
import {SinglePublicationPageComponent} from "./components/portal/pages/publications-page/single-publication-page/single-publication-page.component";
import {ReportsPageComponent} from "./components/portal/pages/reports-page/reports-page.component";
import {SingleReportPageComponent} from "./components/portal/pages/reports-page/single-report-page/single-report-page.component";
import {ResourcesPageComponent} from "./components/portal/pages/resources-page/resources-page.component";
import {SearchComponent} from "./components/portal/pages/search/search.component";
import {SitemapPageComponent} from "./components/portal/pages/sitemap-page/sitemap-page.component";
import {StrategicPlansPageComponent} from "./components/portal/pages/strategic-plans-page/strategic-plans-page.component";
import {UnsubscribePageComponent} from "./components/portal/pages/unsubscribe-page/unsubscribe-page.component";
import {PortalComponent} from "./components/portal/portal.component";
import {PublishingLandingAboutComponent} from "./components/publishing/pages/publishing-landing-page/publishing-landing-about/publishing-landing-about.component";
import {PublishingLandingCarouselComponent} from "./components/publishing/pages/publishing-landing-page/publishing-landing-carousel/publishing-landing-carousel.component";
import {PublishingLandingCtaComponent} from "./components/publishing/pages/publishing-landing-page/publishing-landing-cta/publishing-landing-cta.component";
import {PublishingLandingPageComponent} from "./components/publishing/pages/publishing-landing-page/publishing-landing-page.component";
import {PublishingLandingRegulationsComponent} from "./components/publishing/pages/publishing-landing-page/publishing-landing-regulations/publishing-landing-regulations.component";
import {PublishingLandingSubjectsComponent} from "./components/publishing/pages/publishing-landing-page/publishing-landing-subjects/publishing-landing-subjects.component";
import {PublishingComponent} from "./components/publishing/publishing.component";
import {CalendarComponent} from "./components/shared/calendar/calendar.component";
import {CarouselComponent} from "./components/shared/carousel/carousel.component";
import {CopyrightComponent} from "./components/shared/copyright/copyright.component";
import {FontsShareComponent} from "./components/shared/fonts-share/fonts-share.component";
import {FooterLinksComponent} from "./components/shared/footer-links/footer-links.component";
import {HeroComponent} from "./components/shared/hero/hero.component";
import {LoaderComponent} from "./components/shared/loader/loader.component";
import {NavbarComponent} from "./components/shared/navbar/navbar.component";
import {NewsletterComponent} from "./components/shared/newsletter/newsletter.component";
import {PageComponent} from "./components/shared/page/page.component";
import {PageContentComponent} from "./components/shared/page/page-content/page-content.component";
import {PageFooterComponent} from "./components/shared/page/page-footer/page-footer.component";
import {PageHeaderComponent} from "./components/shared/page/page-header/page-header.component";
import {SearchMenuComponent} from "./components/shared/search-menu/search-menu.component";
import {SearchPopupComponent} from "./components/shared/search-popup/search-popup.component";
import {TtsControlComponent} from "./components/shared/tts-control/tts-control.component";
import {StoriesLandingAboutComponent} from "./components/stories/pages/stories-landing-page/stories-landing-about/stories-landing-about.component";
import {StoriesLandingCarouselComponent} from "./components/stories/pages/stories-landing-page/stories-landing-carousel/stories-landing-carousel.component";
import {StoriesLandingCtaComponent} from "./components/stories/pages/stories-landing-page/stories-landing-cta/stories-landing-cta.component";
import {StoriesLandingPageComponent} from "./components/stories/pages/stories-landing-page/stories-landing-page.component";
import {StoriesLandingRegulationsComponent} from "./components/stories/pages/stories-landing-page/stories-landing-regulations/stories-landing-regulations.component";
import {StoriesLandingSubjectsComponent} from "./components/stories/pages/stories-landing-page/stories-landing-subjects/stories-landing-subjects.component";
import {StoriesComponent} from "./components/stories/stories.component";
import {CounterDirective} from "./directives/counter.directive";
import {SwiperDirective} from "./directives/swiper.directive";
import {FileLinkPipe} from "./pipes/file-link.pipe";
import {FileTypePipe} from "./pipes/file-type.pipe";
import {ImageLinkPipe} from "./pipes/image-link.pipe";
import {MasonryPipe} from "./pipes/masonry.pipe";

registerLocaleData(ar);

const APOLLO_CACHE = new InjectionToken<InMemoryCache>("apollo-cache");
const STATE_KEY = makeStateKey<any>("apollo.state");

register();

@NgModule({
  declarations: [
    CounterDirective,
    ImageLinkPipe,
    FileLinkPipe,
    FileTypePipe,
    MasonryPipe,
    AppComponent,
    CopyrightComponent,
    FooterLinksComponent,
    NewsletterComponent,
    FontsShareComponent,
    LoaderComponent,
    PageComponent,
    PageContentComponent,
    PageHeaderComponent,
    PageFooterComponent,
    HeroComponent,
    CalendarComponent,
    CarouselComponent,
    NavbarComponent,
    SearchMenuComponent,
    PortalComponent,
    PublicationsSidebarComponent,
    AboutPageComponent,
    CentersPageComponent,
    ContactUsPageComponent,
    EventsPageComponent,
    LandingPageComponent,
    LandingHeroComponent,
    LandingCarouselComponent,
    LandingNewsComponent,
    LandingServicesComponent,
    LandingProjectsComponent,
    LandingEventsComponent,
    InfographicGalleryPageComponent,
    ManagerPageComponent,
    NewsPageComponent,
    NumbersPageComponent,
    ProjectsPageComponent,
    PublicationsPageComponent,
    ReportsPageComponent,
    MediaReportsPageComponent,
    SingleEventPageComponent,
    SingleNewsPageComponent,
    SingleProjectPageComponent,
    SinglePublicationPageComponent,
    SingleReportPageComponent,
    StrategicPlansPageComponent,
    AgreementsPageComponent,
    CountriesPageComponent,
    PrivacyPageComponent,
    DepartmentsPageComponent,
    SingleDepartmentPageComponent,
    BooksPageComponent,
    ResourcesPageComponent,
    PublishingComponent,
    PublishingLandingPageComponent,
    PublishingLandingCarouselComponent,
    PublishingLandingAboutComponent,
    PublishingLandingSubjectsComponent,
    PublishingLandingRegulationsComponent,
    PublishingLandingCtaComponent,
    StoriesComponent,
    StoriesLandingPageComponent,
    StoriesLandingCarouselComponent,
    StoriesLandingAboutComponent,
    StoriesLandingSubjectsComponent,
    StoriesLandingRegulationsComponent,
    StoriesLandingCtaComponent,
    UnsubscribePageComponent,
    SitemapPageComponent,
    EventCategoryPageComponent,
    SwiperDirective,
    SearchComponent,
    SearchPopupComponent,
    TtsControlComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatDateFnsModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    MatBadgeModule,
    MatTooltipModule,
    YouTubePlayerModule,
    ApolloModule,
    LightgalleryModule,
    RecaptchaV3Module,
    AppRoutingModule,
    FontAwesomeModule,
    NgOptimizedImage,
    NgChartsModule,
  ],
  providers: [
    provideClientHydration(),
    ImageLinkPipe,
    FileLinkPipe,
    FileTypePipe,
    MasonryPipe,
    {
      provide: APOLLO_CACHE,
      useValue: new InMemoryCache(),
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory(httpLink: HttpLink, cache: InMemoryCache, transferState: TransferState) {
        const isBrowser = transferState.hasKey<any>(STATE_KEY);

        if (isBrowser) {
          const state = transferState.get<any>(STATE_KEY, null);
          cache.restore(state);
        } else {
          transferState.onSerialize(STATE_KEY, () => {
            return cache.extract();
          });
          // Reset cache after extraction to avoid sharing between requests
          cache.reset();
        }

        return {
          link: httpLink.create({uri: environment.graphqlURI}),
          cache,
          connectToDevTools: false,
        } as ApolloClientOptions<any>;
      },
      deps: [HttpLink, APOLLO_CACHE, TransferState],
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: arEG,
    },
    {
      provide: DateAdapter,
      useClass: DateFnsAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: ["l", "LL"],
        },
        display: {
          dateInput: "YYYY-MM-dd",
          monthYearLabel: "MMMM yyyy",
          monthLabel: "MMMM",
          dateA11yLabel: "LL",
          monthYearA11yLabel: "MMMM yyyy",
        },
      },
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.siteKey,
    },
    {
      provide: IMAGE_LOADER,
      useValue: (config: ImageLoaderConfig) => {
        const url = new URL(`${environment.assetsURI}/${config.src}`);

        const {fit, width} =
          (config.loaderParams as {fit?: "cover" | "contain" | "inside" | "outside"; width?: number}) || {};

        if (fit) {
          url.searchParams.set("fit", fit);
        }
        if (width) {
          url.searchParams.set("width", width.toString());
        }

        return url.toString();
      },
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
