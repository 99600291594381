import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {faPlay} from "@fortawesome/free-solid-svg-icons";
import {ReplaySubject, takeUntil} from "rxjs";
import {ProjectsPage, ProjectsPageData, ProjectsPageListItem} from "src/app/interfaces/portal/projects-page";
import {SearchMenuField, SearchMenuResult} from "src/app/interfaces/search-menu";
import {PortalContentService} from "src/app/services/portal-content.service";
import {environment} from "src/environments/environment";

@Component({
  selector: "app-projects-page",
  templateUrl: "./projects-page.component.html",
  styleUrls: ["./projects-page.component.scss"],
})
export class ProjectsPageComponent implements OnInit, OnDestroy {
  faPlay = faPlay;

  loading!: boolean;

  meta!: ProjectsPage;
  projects: ProjectsPageListItem[] = [];

  page = 0;
  paginate = true;

  emptyFilter = {
    year: "",
    center: "",
  };

  filterFields: SearchMenuField[] = [
    {key: "year", name: $localize`السنة`, type: "text"},
    {key: "center", name: $localize`الجهة المنفذة`, type: "text"},
  ];

  filter!: SearchMenuResult;

  unsubscribe$ = new ReplaySubject(1);

  constructor(private route: ActivatedRoute, private cmsService: PortalContentService) {}

  ngOnInit(): void {
    const {meta} = this.route.snapshot.data["page"] as ProjectsPageData;
    this.meta = meta;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(1);
    this.unsubscribe$.complete();
  }

  getNextPage() {
    this.page++;
    this.loading = true;
    this.cmsService
      .listProjects(this.page, this.filter, "-date_created")
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (projects) => {
          this.projects.push(...projects);
          if (projects.length < environment.limit.projects) {
            this.paginate = false;
          }
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  onSubmit(filter: SearchMenuResult) {
    this.filter = filter;
    this.refreshData();
  }

  onClear() {
    this.filter = {...this.emptyFilter};
    this.refreshData();
  }

  refreshData() {
    this.page = 0;
    this.paginate = true;
    this.projects = [];
    this.getNextPage();
  }
}
