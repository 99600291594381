<div class="footer-font-override">
  <footer class="footer bg-dark text-light text-muted text-center small py-3">
    <div class="container">
      <p class="mb-0">
        <ng-container i18n>جميع الحقوق محفوظة &copy; لمكتب التربية العربي لدول الخليج</ng-container>
        &nbsp;{{ currentYear }}
      </p>
    </div>
  </footer>
</div>
