import {Inject, Injectable, LOCALE_ID} from "@angular/core";
import {Direction} from "src/app/enums/direction";
import {Locale} from "src/app/enums/locale";

import {I18nFunctions} from "../interfaces/i18n";

@Injectable({
  providedIn: "root",
})
export class LocalizationService {
  constructor(@Inject(LOCALE_ID) private locale: Locale) {}

  getLocale() {
    return this.locale;
  }

  getDirection() {
    if (this.locale === Locale.ARABIC) return Direction.RIGHT_TO_LEFT;
    return Direction.LEFT_TO_RIGHT;
  }

  private getLocalizedField(field: string) {
    return this.getLocale() === Locale.ARABIC ? field : `${field}_en`;
  }

  private getFieldFn() {
    const $ = (field: string) => {
      return this.getLocalizedField(field);
    };
    $.bind(this);
    return $;
  }

  private getFilterFn() {
    const $ = (field: string) => {
      return `{${this.getLocalizedField(field)}: {_nempty: true}}`;
    };
    $.bind(this);
    return $;
  }

  getI18nFns(): I18nFunctions {
    return {
      field: this.getFieldFn(),
      filter: this.getFilterFn(),
      ar: () => this.getLocale() === Locale.ARABIC,
      en: () => this.getLocale() === Locale.ENGLISH,
    };
  }
}
