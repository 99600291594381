<app-page>
  <page-header></page-header>
  <page-content>
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-4 my-2 px-4 position-relative text-center">
          <img [src]="project.image | imageLink" class="img-fluid mx-auto mt-5 w-100 manager" [alt]="project.name" />
        </div>
        <div class="col my-4">
          <div *ngIf="getFiscalYear(project.year)" class="col my-4">
            <h5 i18n class="fw-bold">الدورة المالية</h5>
            <p class="text-light-gray">{{ getFiscalYear(project.year) }}</p>
          </div>

          <div *ngIf="project.summary" class="col my-4">
            <h5 i18n class="fw-bold">وصف البرنامج</h5>
            <p class="text-light-gray" [innerHTML]="project.summary"></p>
          </div>

          <div *ngIf="project.goals" class="col my-4 my-4">
            <h5 i18n class="fw-bold">أهداف البرنامج</h5>
            <p class="text-light-gray" [innerHTML]="project.goals"></p>
          </div>

          <div *ngIf="project.links && project.links.length > 0" class="col my-4 my-4">
            <h5 i18n class="fw-bold" *ngIf="project.links.length === 1">رابط البرنامج</h5>
            <h5 i18n class="fw-bold" *ngIf="project.links.length > 1">روابط البرنامج</h5>
            <div *ngFor="let record of project.links">
              <a *ngIf="project.links.length === 1" target="_blank" i18n [href]="record.link">اضغط هنا</a>
              <a *ngIf="project.links.length > 1" target="_blank" [href]="record.link">{{ record.link }}</a>
            </div>
          </div>

          <div *ngIf="project.center" class="col my-4 my-4">
            <h5 i18n class="fw-bold">الجهة المنفذة</h5>
            <p class="text-light-gray">{{ project.center }}</p>
          </div>

          <div *ngIf="project.sponsors && project.sponsors.length > 0" class="col my-4 my-4">
            <h5 i18n class="fw-bold">رعاة البرنامج</h5>
            <div class="row">
              <div *ngFor="let sponsor of project.sponsors; index as i" class="col-lg-2 col-md-3 col-sm-4 col-6 mt-4">
                <div class="card h-100 rounded-0">
                  <div class="card-body d-flex align-items-center justify-content-center">
                    <img height="80" [src]="sponsor.sponsor.image | imageLink" class="img-fluid" alt="" />
                    <a *ngIf="sponsor.sponsor.link" [href]="sponsor.sponsor.link" class="stretched-link"></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>
