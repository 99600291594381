import {gql} from "apollo-angular";
import {I18nFunctions} from "src/app/interfaces/i18n";
import {Project, SingleProjectPage} from "src/app/interfaces/portal/single-project-page";

export const GET_SINGLE_PROJECT_PAGE = ({field, filter}: I18nFunctions) => gql<
  {page: SingleProjectPage; projects: Project[]},
  {projectId: string}
>`
  query ($projectId: GraphQLStringOrFloat!) {
    page: projects_page {
      title: ${field("title")}
      image {
        id
        filename: filename_download
        title
      }
    }
    projects: projects(filter: {_and: [{id: {_eq: $projectId}}, {status: {_eq: "published"}}, ${filter("name")}]}) {
      id
      name: ${field("name")}
      summary: ${field("summary")}
      year
      image {
        id
        filename: filename_download
        title
      }
      goals: ${field("goals")}
      center: ${field("center_name")}
      links
      sponsors {
        sponsor: sponsors_id {
          name: ${field("name")}
          image {
            id
            filename: filename_download
            title
          }
          link
        }
      }
    }
  }
`;
