import {Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {PrivacyPage, PrivacyPageData} from "src/app/interfaces/portal/privacy-page";

@Component({
  selector: "app-privacy-page",
  templateUrl: "./privacy-page.component.html",
  styleUrls: ["./privacy-page.component.scss"],
})
export class PrivacyPageComponent implements OnInit {
  page!: PrivacyPage;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    const {meta: page} = this.route.snapshot.data["page"] as PrivacyPageData;
    this.page = page;
  }
}
