import {Component, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {Department, DepartmentPageData} from "src/app/interfaces/portal/departments-page";

@Component({
  selector: "app-single-department-page",
  templateUrl: "./single-department-page.component.html",
  styleUrls: ["./single-department-page.component.scss"],
})
export class SingleDepartmentPageComponent implements OnInit {
  loading = false;
  department!: Department;

  constructor(private router: Router, private route: ActivatedRoute) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit(): void {
    const page = this.route.snapshot.data["page"] as DepartmentPageData;
    this.department = page.data;
  }
}
