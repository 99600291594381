<app-page>
  <page-header></page-header>
  <page-content>
    <div id="standing">
      <div class="container">
        <div class="bg-white p-5">
          <h3 class="text-primary" [innerHTML]="meta.content"></h3>

          <div class="row justify-content-center">
            <div *ngFor="let agreement of agreements; index as i" class="col-lg-2 col-md-3 col-sm-4 col-6 mt-4">
              <div class="card h-100 rounded-0" matTooltipPosition="above" matTooltipClass="text-center">
                <div class="card-body d-flex align-items-center justify-content-center">
                  <img height="80" [src]="agreement.image | imageLink" class="img-fluid" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>
