<div class="calendar-container">
  <mat-calendar
    #calendar
    [startView]="startView"
    [dir]="direction"
    [selected]="selected"
    (selectedChange)="onSelectedChange($event)"
    (monthSelected)="onMonthSelected($event)"
    (yearSelected)="onYearSelected($event)"
    (viewChanged)="onViewChange()"
  ></mat-calendar>
</div>
