<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<!-- eslint-disable @angular-eslint/template/mouse-events-have-key-events -->
<div style="visibility: none" [ngStyle]="{'height.px': (isLogoBreakpoint ? 85 : 60) + 48}"></div>
<div class="navbar-font-override">
  <div class="fixed-top fade-in-down fast" [ngClass]="{'nav-shadow': !heightFlag}">
    <app-ribbon></app-ribbon>
    <div class="d-block py-2 bg-success">
      <div class="container-fluid container-xxl">
        <div class="hstack gap-2">
          <div class="d-flex justify-content-center align-items-center">
            <button (click)="toggleGrayscale()" class="btn btn-success">
              <fa-icon [icon]="grayscale ? faDroplet : faDropletSlash"></fa-icon>
            </button>
            <div i18n class="d-none d-md-block text-white mx-2">الألوان</div>
          </div>
          <div class="vr bg-white opacity-50 d-none d-md-block"></div>
          <div class="d-flex justify-content-center align-items-center">
            <button class="btn btn-success" (click)="increaseFontSize()">
              <fa-icon [icon]="faPlusSquare"></fa-icon>
            </button>
            <button class="btn btn-success" (click)="decreaseFontSize()">
              <fa-icon [icon]="faMinusSquare"></fa-icon>
            </button>
            <div i18n class="d-none d-md-block text-white mx-2">الخطوط</div>
          </div>
          <div class="vr bg-white opacity-50 d-none d-md-block"></div>
          <div class="d-flex justify-content-center align-items-center">
            <a class="btn btn-success" [routerLink]="['/sitemap']">
              <fa-icon [icon]="faSitemap"></fa-icon>
            </a>
            <div i18n class="d-none d-md-block text-white mx-2">خريطة الموقع</div>
          </div>

          <div class="d-flex justify-content-center align-items-center ms-auto">
            <div i18n class="beta text-white" style="padding-left: 0.75em; padding-right: 0.75em">إطلاق تجريبي</div>
            <div class="beta vr bg-white opacity-50"></div>
            <a class="d-none d-md-block btn btn-success" [routerLink]="['/contact-us']" i18n>تواصل معنا</a>
            <div class="d-none d-md-block vr bg-white opacity-50"></div>
            <a class="btn btn-success" [href]="localizedPortalAnchor.href">{{ localizedPortalAnchor.text }}</a>
            <div class="d-none d-md-block vr bg-white opacity-50"></div>
            <a class="btn btn-success" (click)="openSearchPopup()" id="search">
              <fa-icon id="search-button" [icon]="faSearch"></fa-icon>
            </a>
          </div>
        </div>
      </div>
    </div>
    <nav class="navbar navbar-expand-xxl py-0 px-0 bg-white">
      <div class="container-fluid container-xxl justify-content-between align-items-stretch">
        <a class="navbar-brand py-0" routerLink="/">
          <img class="logo-block logo-sm-none" src="./assets/images/abegs.svg" height="60px" alt="Logo" />
          <img class="logo-none logo-sm-block logo-lg-none" [src]="logo" height="60px" alt="Logo" />
          <img class="logo-none logo-lg-block" [src]="logo" height="85px" alt="Logo" />
        </a>

        <div *ngIf="!loading" [ngClass]="{'fade-in fast': !loading}" class="d-flex align-items-center">
          <button
            class="navbar-toggler focus-shadow-none px-4 px-lg-0 collapsed"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <fa-icon [icon]="faBars" class="fw-bold"></fa-icon>
          </button>
          <div
            #offcanvas
            class="offcanvas offcanvas-start justify-content-center"
            tabindex="-1"
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            style="height: 100% !important"
          >
            <div class="offcanvas-header">
              <img [src]="logo" height="60px" alt="Logo" />
              <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body fw-bold p-0 offcanvas-bg h-100">
              <ng-container *ngIf="isXXLarge">
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0 align-items-center">
                  <ng-container *ngFor="let item of menu">
                    <li
                      *ngIf="item.type === MenuItemType.LINK"
                      class="nav-item mx-1 h-100 d-flex justify-content-center align-items-center"
                    >
                      <a *ngIf="item.href" class="nav-link" aria-current="page" [href]="item.link" target="_blank">
                        {{ item.title }}
                      </a>
                      <a *ngIf="!item.href" class="nav-link" aria-current="page" [routerLink]="item.link">
                        {{ item.title }}
                      </a>
                    </li>
                    <li
                      *ngIf="item.type === MenuItemType.FRAGMENT"
                      class="nav-item mx-1 h-100 d-flex justify-content-center align-items-center"
                    >
                      <a class="nav-link" aria-current="page" routerLink="." [fragment]="item.link">
                        {{ item.title }}
                      </a>
                    </li>
                    <ng-container *ngIf="item.type === MenuItemType.GROUP">
                      <li
                        *ngIf="!item.custom"
                        class="nav-item dropdown mx-1 h-100 d-flex justify-content-center align-items-center"
                        (mouseover)="onMouseOver(parentTrigger, parentMenu)"
                        (mouseleave)="onMouseLeave(parentTrigger, parentMenu)"
                      >
                        <a
                          class="nav-link dropdown-toggle"
                          #parentTrigger
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {{ item.title }}
                        </a>
                        <div
                          #parentMenu
                          class="dropdown-menu mega-menu py-3 px-4 mt-md-4 zoom-in fast"
                          [ngClass]="{
                            'dropdown-menu-end': item.arrowPosition !== MenuArrowPosition.START,
                            'dropdown-menu-start': item.arrowPosition === MenuArrowPosition.START
                          }"
                        >
                          <div
                            class="menu-grid"
                            [ngClass]="{
                              'grid-3': item.children && item.children.length > 2,
                              'grid-2': item.children && item.children.length === 2
                            }"
                          >
                            <div *ngFor="let child of item.children" class="menu-item">
                              <ng-container *ngIf="child.type === MenuItemType.LINK">
                                <a
                                  *ngIf="child.href"
                                  [href]="child.link"
                                  target="_blank"
                                  class="navbar-megamenu-url d-flex align-items-center"
                                  [ngClass]="{
                                    vertical: child.isVertical,
                                    'py-3': !child.isVertical,
                                    'py-5': child.isVertical
                                  }"
                                >
                                  <span
                                    style="width: 2rem"
                                    [ngClass]="{'me-4': !child.isVertical}"
                                    class="text-center d-flex justify-content-center align-items-center"
                                  >
                                    <fa-icon [icon]="child.icon || faGlobe" style="font-size: 24px"></fa-icon>
                                  </span>
                                  {{ child.title }}
                                </a>
                                <a
                                  *ngIf="!child.href"
                                  [routerLink]="child.link"
                                  [state]="{name: child.state}"
                                  class="navbar-megamenu-url d-flex align-items-center"
                                  [ngClass]="{
                                    vertical: child.isVertical,
                                    'py-3': !child.isVertical,
                                    'py-5': child.isVertical
                                  }"
                                >
                                  <span
                                    style="width: 2rem"
                                    [ngClass]="{'me-4': !child.isVertical}"
                                    class="text-center d-flex justify-content-center align-items-center"
                                  >
                                    <fa-icon [icon]="child.icon || faGlobe" style="font-size: 24px"></fa-icon>
                                  </span>
                                  {{ child.title }}
                                </a>
                              </ng-container>
                              <ng-container *ngIf="child.type === MenuItemType.FRAGMENT">
                                <a
                                  routerLink="."
                                  [fragment]="child.link"
                                  class="navbar-megamenu-url d-flex align-items-center"
                                  [ngClass]="{
                                    vertical: child.isVertical,
                                    'py-3': !child.isVertical,
                                    'py-5': child.isVertical
                                  }"
                                >
                                  <span
                                    style="width: 2rem"
                                    [ngClass]="{'me-4': !child.isVertical}"
                                    class="text-center d-flex justify-content-center align-items-center"
                                  >
                                    <fa-icon [icon]="child.icon || faGlobe" style="font-size: 24px"></fa-icon>
                                  </span>
                                  {{ child.title }}
                                </a>
                              </ng-container>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li
                        *ngIf="item.custom"
                        class="nav-item dropdown mx-1 h-100 d-flex justify-content-center align-items-center"
                        (mouseover)="onMouseOver(parentTrigger, parentMenu)"
                        (mouseleave)="onMouseLeave(parentTrigger, parentMenu)"
                      >
                        <a
                          class="nav-link dropdown-toggle"
                          #parentTrigger
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {{ item.title }}
                        </a>
                        <div
                          #parentMenu
                          class="dropdown-menu mega-menu py-3 px-4 mt-md-4 zoom-in fast"
                          [ngClass]="{
                            'dropdown-menu-end': item.arrowPosition !== MenuArrowPosition.START,
                            'dropdown-menu-start': item.arrowPosition === MenuArrowPosition.START
                          }"
                        >
                          <div class="row">
                            <div class="col-6">
                              <h4 i18n class="fw-bold text-primary">جوائز و مسابقات</h4>
                              <div class="services-grid">
                                <div
                                  *ngFor="let child of filterByCategory(item.children, 'competitions_awards')"
                                  class="menu-item"
                                >
                                  <ng-container *ngIf="child.type === MenuItemType.LINK">
                                    <a
                                      *ngIf="child.href"
                                      [href]="child.link"
                                      target="_blank"
                                      class="navbar-megamenu-url d-flex align-items-center py-3"
                                    >
                                      <span class="text-center d-flex justify-content-center align-items-center me-4">
                                        <fa-icon [icon]="child.icon || faGlobe" style="font-size: 24px"></fa-icon>
                                      </span>
                                      {{ child.title }}
                                    </a>
                                    <a
                                      *ngIf="!child.href"
                                      [routerLink]="child.link"
                                      [state]="{name: child.state}"
                                      class="navbar-megamenu-url d-flex align-items-center py-3"
                                    >
                                      <span
                                        style="width: 2rem"
                                        class="text-center d-flex justify-content-center align-items-center me-4"
                                      >
                                        <fa-icon [icon]="child.icon || faGlobe" style="font-size: 24px"></fa-icon>
                                      </span>
                                      {{ child.title }}
                                    </a>
                                  </ng-container>
                                  <ng-container *ngIf="child.type === MenuItemType.FRAGMENT">
                                    <a
                                      routerLink="."
                                      [fragment]="child.link"
                                      class="navbar-megamenu-url d-flex align-items-center"
                                      [ngClass]="{
                                        vertical: child.isVertical,
                                        'py-3': !child.isVertical,
                                        'py-5': child.isVertical
                                      }"
                                    >
                                      <span
                                        style="width: 2rem"
                                        [ngClass]="{'me-4': !child.isVertical}"
                                        class="text-center d-flex justify-content-center align-items-center"
                                      >
                                        <fa-icon [icon]="child.icon || faGlobe" style="font-size: 24px"></fa-icon>
                                      </span>
                                      {{ child.title }}
                                    </a>
                                  </ng-container>
                                </div>
                              </div>
                            </div>
                            <div class="col-6">
                              <h4 i18n class="fw-bold text-primary">منصات إلكترونية</h4>
                              <div class="services-grid">
                                <div *ngFor="let child of filterByCategory(item.children, 'services')" class="menu-item">
                                  <ng-container *ngIf="child.type === MenuItemType.LINK">
                                    <a
                                      *ngIf="child.href"
                                      [href]="child.link"
                                      target="_blank"
                                      class="navbar-megamenu-url d-flex align-items-center"
                                      [ngClass]="{
                                        vertical: child.isVertical,
                                        'py-3': !child.isVertical,
                                        'py-5': child.isVertical
                                      }"
                                    >
                                      <span
                                        style="width: 2rem"
                                        [ngClass]="{'me-4': !child.isVertical}"
                                        class="text-center d-flex justify-content-center align-items-center"
                                      >
                                        <fa-icon [icon]="child.icon || faGlobe" style="font-size: 24px"></fa-icon>
                                      </span>
                                      {{ child.title }}
                                    </a>
                                    <a
                                      *ngIf="!child.href"
                                      [routerLink]="child.link"
                                      [state]="{name: child.state}"
                                      class="navbar-megamenu-url d-flex align-items-center"
                                      [ngClass]="{
                                        vertical: child.isVertical,
                                        'py-3': !child.isVertical,
                                        'py-5': child.isVertical
                                      }"
                                    >
                                      <span
                                        style="width: 2rem"
                                        [ngClass]="{'me-4': !child.isVertical}"
                                        class="text-center d-flex justify-content-center align-items-center"
                                      >
                                        <fa-icon [icon]="child.icon || faGlobe" style="font-size: 24px"></fa-icon>
                                      </span>
                                      {{ child.title }}
                                    </a>
                                  </ng-container>
                                  <ng-container *ngIf="child.type === MenuItemType.FRAGMENT">
                                    <a
                                      routerLink="."
                                      [fragment]="child.link"
                                      class="navbar-megamenu-url d-flex align-items-center"
                                      [ngClass]="{
                                        vertical: child.isVertical,
                                        'py-3': !child.isVertical,
                                        'py-5': child.isVertical
                                      }"
                                    >
                                      <span
                                        style="width: 2rem"
                                        [ngClass]="{'me-4': !child.isVertical}"
                                        class="text-center d-flex justify-content-center align-items-center"
                                      >
                                        <fa-icon [icon]="child.icon || faGlobe" style="font-size: 24px"></fa-icon>
                                      </span>
                                      {{ child.title }}
                                    </a>
                                  </ng-container>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ng-container>
                  </ng-container>
                </ul>
              </ng-container>

              <ul *ngIf="!isXXLarge" class="navbar-nav ms-auto mb-2 mb-lg-0 offcanvas-fix">
                <ng-container *ngFor="let item of menu">
                  <li *ngIf="item.type === MenuItemType.LINK" class="nav-item">
                    <a *ngIf="item.href" class="nav-link ps-2 py-3" aria-current="page" [href]="item.link" target="_blank">
                      {{ item.title }}
                    </a>
                    <a *ngIf="!item.href" class="nav-link ps-2 py-3" aria-current="page" [routerLink]="item.link">
                      {{ item.title }}
                    </a>
                  </li>
                  <li *ngIf="item.type === MenuItemType.FRAGMENT" class="nav-item" (click)="dismissOffcanvas()">
                    <a class="nav-link ps-2 py-3" aria-current="page" routerLink="." [fragment]="item.link">
                      {{ item.title }}
                    </a>
                  </li>
                  <li *ngIf="item.type === MenuItemType.GROUP" class="nav-item dropdown" #offcanvasMenu>
                    <a
                      class="nav-link dropdown-toggle ps-2 py-3"
                      role="button"
                      data-bs-auto-close="false"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {{ item.title }}
                    </a>
                    <ul class="dropdown-menu dark-dropdown-menu py-0">
                      <li *ngFor="let child of item.children">
                        <ng-container *ngIf="child.type === MenuItemType.LINK">
                          <a
                            *ngIf="child.href"
                            [href]="child.link"
                            target="_blank"
                            class="dropdown-item dark-dropdown-menu-item text-white py-3 child-item fade-in-down fast"
                          >
                            {{ child.title }}
                          </a>
                          <a
                            *ngIf="!child.href"
                            [state]="{name: child.state}"
                            [routerLink]="child.link"
                            class="dropdown-item dark-dropdown-menu-item text-white py-3 child-item fade-in-down fast"
                          >
                            {{ child.title }}
                          </a>
                        </ng-container>
                        <ng-container *ngIf="child.type === MenuItemType.FRAGMENT">
                          <a
                            routerLink="."
                            [fragment]="child.link"
                            class="dropdown-item dark-dropdown-menu-item text-white py-3 child-item fade-in-down fast"
                            (click)="dismissOffcanvas()"
                          >
                            {{ child.title }}
                          </a>
                        </ng-container>
                        <div *ngIf="child.type === MenuItemType.GROUP" class="dropdown dark-dropdown-menu">
                          <a
                            class="dropdown-toggle dropdown-item dark-dropdown-menu-item text-white py-3 child-item fw-normal w-100 fade-in-down fast"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            {{ child.title }}
                          </a>
                          <ul class="dropdown-menu gray-dropdown-menu py-0">
                            <li *ngFor="let grandChild of child.children">
                              <ng-container *ngIf="grandChild.type === MenuItemType.LINK">
                                <a
                                  *ngIf="grandChild.href"
                                  [href]="grandChild.link"
                                  target="_blank"
                                  class="dropdown-item gray-dropdown-menu-item text-white py-3 child-item fade-in-down fast"
                                >
                                  {{ grandChild.title }}
                                </a>
                                <a
                                  *ngIf="!grandChild.href"
                                  [routerLink]="grandChild.link"
                                  class="dropdown-item gray-dropdown-menu-item text-white py-3 child-item fade-in-down fast"
                                >
                                  {{ grandChild.title }}
                                </a>
                              </ng-container>
                              <ng-container *ngIf="grandChild.type === MenuItemType.FRAGMENT">
                                <a
                                  routerLink="."
                                  [fragment]="grandChild.link"
                                  class="dropdown-item gray-dropdown-menu-item text-white py-3 child-item fade-in-down fast"
                                  (click)="dismissOffcanvas()"
                                >
                                  {{ grandChild.title }}
                                </a>
                              </ng-container>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </li>
                </ng-container>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</div>

<app-search-popup></app-search-popup>
