import {Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {faEllipsisVertical, faGlobe} from "@fortawesome/free-solid-svg-icons";
import {Center, CentersPageData} from "src/app/interfaces/portal/centers-page";

@Component({
  selector: "app-centers-page",
  templateUrl: "./centers-page.component.html",
  styleUrls: ["./centers-page.component.scss"],
})
export class CentersPageComponent implements OnInit {
  faEllipsisVertical = faEllipsisVertical;
  faGlobe = faGlobe;

  centers!: Center[];

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    const page = this.route.snapshot.data["page"] as CentersPageData;
    this.centers = page.data.centers;
  }
}
