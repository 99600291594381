import {AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit} from "@angular/core";
import {icon} from "@fortawesome/fontawesome-svg-core";
import {faCircleLeft, faCircleRight} from "@fortawesome/free-regular-svg-icons";
import {InitDetail} from "lightgallery/lg-events";
import {LightGallery} from "lightgallery/lightgallery";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import {ReplaySubject, takeUntil} from "rxjs";
import {InfographicGalleryPageListItem} from "src/app/interfaces/portal/infographic-gallery-page";
import {SearchMenuField, SearchMenuResult} from "src/app/interfaces/search-menu";
import {PortalContentService} from "src/app/services/portal-content.service";
import {WindowService} from "src/app/services/window.service";
import {environment} from "src/environments/environment";

@Component({
  selector: "app-infographic-gallery-page",
  templateUrl: "./infographic-gallery-page.component.html",
  styleUrls: ["./infographic-gallery-page.component.scss"],
})
export class InfographicGalleryPageComponent implements OnInit, AfterViewChecked, OnDestroy {
  faCircleRight = faCircleRight;
  faCircleLeft = faCircleLeft;

  lightGallery!: LightGallery;
  needRefresh = false;
  filter!: SearchMenuResult;

  filterFields: SearchMenuField[] = [
    {key: "title", name: $localize`عنوان الانفوجراف`, type: "text"},
    {key: "publicationName", name: $localize`عنوان الإصدار`, type: "text"},
  ];
  emptyFilter = {
    title: "",
    publicationName: "",
  };
  infographics: InfographicGalleryPageListItem[] = [];
  loading!: boolean;
  page = 0;
  paginate = true;

  mode: "search" | "list" = "list";

  settings = {
    licenseKey: "123",
    thumbnail: true,
    counter: false,
    selector: ".item",
    plugins: [lgThumbnail],
    nextHtml: icon(faCircleRight).html.join(""),
    prevHtml: icon(faCircleLeft).html.join(""),
  };

  onInit = (detail: InitDetail): void => {
    this.lightGallery = detail.instance;
  };

  isBrowser!: boolean;

  unsubscribe$ = new ReplaySubject(1);

  constructor(
    private cmsService: PortalContentService,
    private windowService: WindowService,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.isBrowser = this.windowService.isBrowser;
    // this.fetchInfographics();
  }

  ngAfterViewChecked(): void {
    if (this.needRefresh) {
      this.lightGallery.refresh();
      this.needRefresh = false;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(1);
    this.unsubscribe$.complete();
  }

  fetchInfographics() {
    this.page++;
    this.loading = true;
    this.cmsService
      .listInfographics(this.page, {...this.filter})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (result) => {
          this.infographics.push(...result);
          if (result.length < environment.limit.infographicsGallery) {
            this.paginate = false;
          }
          this.loading = false;
          this.needRefresh = true;
        },
        error: () => {
          this.loading = false;
          this.infographics = [];
        },
      });
  }

  onSubmit(filter: SearchMenuResult) {
    const fieldsCount = Object.values(filter).filter((value) => !!value).length;
    this.mode = fieldsCount > 0 ? "search" : "list";
    this.page = 0;
    this.filter = filter;
    this.refreshData();
    this.cd.detectChanges();
  }

  onClear() {
    this.filter = {...this.emptyFilter};
    this.page = 0;
    this.mode = "list";
    this.paginate = true;
    this.refreshData();
  }
  refreshData() {
    this.infographics = [];
    this.fetchInfographics();
  }
}
