import {gql} from "apollo-angular";
import {Center} from "src/app/interfaces/portal/centers-page";
import {InfographicGalleryPageListItem} from "src/app/interfaces/portal/infographic-gallery-page";
import {
  Publication,
  PublicationCenters,
  PublicationFilter,
  PublicationsClassification,
  PublicationSort,
  PublicationsPage,
} from "src/app/interfaces/portal/publications-page";

export const GET_PUBLICATIONS_PAGE = gql<
  {
    page: PublicationsPage;
    classifications: PublicationsClassification[];
    centers: Center[];
    publicationCenters: PublicationCenters[];
  },
  void
>`
  query {
    page: publications_page {
      title
      image {
        id
        filename: filename_download
        title
      }
    }
    classifications: publications_classifications {
      id
      name
    }
    centers(filter: {status: {_eq: "published"}}, sort: ["order"]) {
      id
      name
      origin
      url
      image {
        id
        filename: filename_download
        title
      }
    }
    publicationCenters: puplication_centers {
      id
      name
      description
    }
  }
`;

export const GET_PUBLICATION_PAGE = gql<
  {
    page: PublicationsPage;
    publications: Publication[];
    infographics: InfographicGalleryPageListItem[];
  },
  {publicationId: string | number}
>`
  query ($publicationId: GraphQLStringOrFloat!) {
    page: publications_page {
      title
      image {
        id
        filename: filename_download
        title
      }
    }
    publications: publications(filter: {status: {_eq: "published"}, id: {_eq: $publicationId}}) {
      id
      name
      summary
      classification {
        id
        name
      }
      author {
        id
        name
        image {
          id
          filename: filename_download
          title
        }
      }
      publishDate: publish_date
      releaseDate: release_date
      purchaseLink: purchase_link
      pages
      image {
        id
        filename: filename_download
        title
      }
      views
      videoUrl: video_url
      audioSummary: audio_summary {
        id
        filename: filename_download
        title
        type
      }
      file {
        id
        filename: filename_download
        title
        type
      }
    }
    infographics(filter: {status: {_eq: "published"}, publication: {id: {_eq: $publicationId}}}, sort: ["-date_created"]) {
      id
      title
      image {
        id
        filename: filename_download
        title
      }
    }
  }
`;

export const UPDATE_PUBLICATION_VIEWS_COUNT_BY_ID = gql<
  {publication: Publication},
  {publicationId: string | number; views: number}
>`
  mutation ($publicationId: ID!, $views: Int!) {
    update_publications_item(id: $publicationId, data: {views: $views}) {
      id
      views
    }
  }
`;

export const getPublicationsByFilterFactory = (filter?: PublicationFilter, sort: PublicationSort = "-release_date") => {
  const variables: string[] = [];
  const expressions: string[] = ['{status: {_eq: "published"}}'];

  variables.push("$page: Int!");
  variables.push("$limit: Int!");

  if (filter) {
    if (filter.title) {
      variables.push("$title: String!");
      expressions.push(`{name: {_icontains: $title}}`);
    }
    if (filter.author) {
      variables.push("$author: String!");
      expressions.push(`{author: {name: {_icontains: $author}}}`);
    }
    if (filter.center) {
      variables.push("$center: GraphQLStringOrFloat!");
      expressions.push(`{center:  {id: {_eq: $center}}}`);
    }
    if (filter.summary) {
      variables.push("$summary: String!");
      expressions.push(`{summary: {_icontains: $summary}}`);
    }
    if (filter.classification) {
      variables.push("$classification: GraphQLStringOrFloat!");
      expressions.push(`{classification: {id: {_eq: $classification}}}`);
    }
    if (typeof filter.audio === "boolean") {
      if (filter.audio) {
        expressions.push("{audio_summary: {id: {_nnull: true}}}");
      } else {
        expressions.push("{audio_summary: {id: {_null: true}}}");
      }
    }
    if (typeof filter.infographics === "boolean") {
      if (filter.infographics) {
        expressions.push("{infographics: {id: {_nnull: true}}}");
      } else {
        expressions.push("{infographics: {id: {_null: true}}}");
      }
    }
  }

  const queryVariables = variables.length > 0 ? `(${variables.join(", ")})` : "";
  const queryFilter = expressions.length > 1 ? `{_and: [${expressions.join(", ")}]}` : expressions.join("");

  return gql<
    {publications: Publication[]},
    {
      limit: number;
      page: number;
      title?: string;
      author?: string;
      center?: string | number;
      summary?: string;
      classification?: string | number;
    }
  >`
    query ${queryVariables} {
      publications: publications(
        filter: ${queryFilter}
        sort: ["${sort}"]
        page: $page
        limit: $limit
      ) {
        id
        name
        summary
        classification {
          id
          name
        }
        author {
          id
          name
          image {
            id
            filename: filename_download
            title
          }
        }
        publishDate: release_date
        pages
        purchaseLink: purchase_link
        views
        image {
          id
          filename: filename_download
          title
        }
        center {
          id
          name
        }
      }
    }
  `;
};
