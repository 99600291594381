import {gql} from "apollo-angular";
import {EventFilter, EventsPage, EventsPageListItem} from "src/app/interfaces/portal/events-page";

export const GET_EVENTS_PAGE = gql<{page: EventsPage}, void>`
  query {
    page: calendar_page {
      title
      image {
        id
        filename: filename_download
        title
      }
    }
  }
`;

export const getEventsByFilterFactory = (filter?: EventFilter) => {
  const variables: string[] = [];
  const args: string[] = [];
  let cursor = "";

  if (filter?.startDate && filter?.endDate && !(filter?.name || filter?.content)) {
    variables.push("$startDate: String!");
    args.push("{end_date: {_gte: $startDate}}");

    variables.push("$endDate: String!");
    args.push("{start_date: {_lte: $endDate}}");
  } else {
    if (filter?.name) {
      variables.push("$name: String!");
      args.push("{name: {_icontains: $name}}");
    }

    if (filter?.content) {
      variables.push("$content: String!");
      args.push("{content: {_icontains: $content}}");
    }

    variables.push("$page: Int!");
    variables.push("$limit: Int!");

    cursor = `
      page: $page
      limit: $limit
    `;
  }

  const variablesExpr = variables.length > 0 ? `(${variables.join(", ")})` : "";
  const filterExpr = args.length > 0 ? `filter: {_and: [${args.join(", ")}]}` : "";

  return gql<
    {
      events: EventsPageListItem[];
    },
    {startDate: Date; endDate: Date; name?: string; content?: string; page?: number; limit?: number}
  >`
    query ${variablesExpr} {
      events(
        ${filterExpr}
        sort: ["-start_date"]
        ${cursor}
      ) {
        id
        name
        image {
          id
          filename: filename_download
          title
        }
        startDate: start_date
        endDate: end_date
        type {
          slug
          name
        }
      }
    }
  `;
};
