import {Pipe, PipeTransform} from "@angular/core";
import {ImageFile} from "src/app/interfaces/image-file";
import {environment} from "src/environments/environment";

@Pipe({
  name: "imageLink",
})
export class ImageLinkPipe implements PipeTransform {
  transform(value: ImageFile): string {
    const ext = value.filename.split(".").at(-1);
    const url = `${environment.assetsURI}/${value.id}.${ext}`;
    return url;
  }
}
