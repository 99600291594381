<video
  #videoRef
  id="video"
  autoplay
  muted
  loop
  playsinline
  class="hero-video"
  [ngClass]="{'animate-pulse': !videoLoaded}"
  (loadeddata)="loaded()"
>
  <!-- <source src="assets/videos/hero.mp4" type="video/mp4" /> -->
  <source [src]="storageLink" type="video/mp4" />
</video>
