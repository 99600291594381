import {gql} from "apollo-angular";
import {EventCategoryPage, EventCategoryPageListItem} from "src/app/interfaces/portal/event-category-page";
import {EventSlug} from "src/app/interfaces/portal/events-page";

export const GET_EVENT_CATEGORY_PAGE = gql<
  {page: EventCategoryPage; upcomingEvents: EventCategoryPageListItem[]},
  {currentDate: string; upcomingEventsLimit: number; slug: EventSlug[]}
>`
  query ($currentDate: String, $upcomingEventsLimit: Int!, $slug: [String]!) {
    page: calendar_page {
      title
      image {
        id
        filename: filename_download
        title
      }
    }
    upcomingEvents: events(
      filter: {_and: [{status: {_eq: "published"}}, {start_date: {_gte: $currentDate}}, {type: {slug: {_in: $slug}}}]}
      sort: ["-start_date"]
      limit: $upcomingEventsLimit
    ) {
      id
      name
      startDate: start_date
      endDate: end_date
      image {
        id
        filename: filename_download
        title
      }
      summary
      location
      type {
        slug
        name
      }
    }
  }
`;

export const GET_EVENTS_BY_SLUG = gql<
  {events: EventCategoryPageListItem[]},
  {startDate: Date; endDate: Date; slug: EventSlug[]}
>`
  query ($startDate: String!, $endDate: String!, $slug: [String]!) {
    events: events(
      filter: {
        _and: [
          {status: {_eq: "published"}}
          {start_date: {_lte: $endDate}}
          {end_date: {_gte: $startDate}}
          {type: {slug: {_in: $slug}}}
        ]
      }
      sort: ["-date_created"]
    ) {
      id
      name
      startDate: start_date
      endDate: end_date
      image {
        id
        filename: filename_download
        title
      }
      summary
      location
      type {
        slug
        name
      }
    }
  }
`;
