import {gql} from "apollo-angular";
import {I18nFunctions} from "src/app/interfaces/i18n";
import {PrivacyPage} from "src/app/interfaces/portal/privacy-page";

export const GET_PRIVACY_PAGE = ({field, filter}: I18nFunctions) => gql<{page: PrivacyPage}, void>`
  query {
    page: privacy_page {
      title: ${field("title")}
      intro: ${field("intro")}
      image {
        id
        filename: filename_download
        title
      }
      content: ${field("content")}
    }
  }
`;
