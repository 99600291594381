import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Apollo} from "apollo-angular";
import {map, Observable} from "rxjs";
import {PageType} from "src/app/enums/page-type";
import {ReportsPageData} from "src/app/interfaces/portal/reports-page";
import {GET_REPORTS_PAGE} from "src/app/queries/portal/reports-page.query";
import {SeoService} from "src/app/services/seo.service";

@Injectable({
  providedIn: "root",
})
export class ReportsPageResolver implements Resolve<ReportsPageData> {
  constructor(private apollo: Apollo, private seoService: SeoService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<ReportsPageData> {
    return this.apollo
      .query({
        query: GET_REPORTS_PAGE,
      })
      .pipe(
        map((result): ReportsPageData => {
          const {page: meta} = result.data;
          return this.seoService.patchTitleAndMetaTags(route, {
            title: meta.title,
            breadcrumbs: [
              {title: $localize`البوابة`, routerLink: ""},
              {title: meta.title, routerLink: "reports"},
            ],
            data: null,
            meta,
            type: PageType.INTERIOR,
          });
        }),
      );
  }
}
