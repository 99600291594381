import {gql} from "apollo-angular";
import {I18nFunctions} from "src/app/interfaces/i18n";
import {ContactUsPage, RequestForm} from "src/app/interfaces/portal/contact-us-page";

export const GET_CONTACT_US_PAGE = ({field, filter, ar}: I18nFunctions) => gql<
  {page: ContactUsPage; forms?: RequestForm[]},
  void
>`
  query {
    page: contact_us_page {
      title: ${field("title")}
      image {
        id
        filename: filename_download
        title
      }
      location: ${field("location")}
      phone
      email
    }
    ${
      ar()
        ? `
        forms: portal_requests_forms(filter: {status: {_eq: "published"}}) {
          id
          title
          description
          file {
            id
            filename: filename_download
            title
          }
        }
    `
        : ""
    }
  }
`;
