import {Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {faCloudArrowDown, faEye, faLightbulb, faPen, faSun} from "@fortawesome/free-solid-svg-icons";
import {AboutPageDocument, AboutPageValues, AboutUsPage, AboutUsPageData} from "src/app/interfaces/portal/about-us-page";

@Component({
  selector: "app-about-page",
  templateUrl: "./about-page.component.html",
  styleUrls: ["./about-page.component.scss"],
})
export class AboutPageComponent implements OnInit {
  faEye = faEye;
  faLightbulb = faLightbulb;
  faSun = faSun;
  faPen = faPen;
  faCloudArrowDown = faCloudArrowDown;

  meta!: AboutUsPage;
  values!: AboutPageValues[];
  documents!: AboutPageDocument[];

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    const {meta, ...page} = this.route.snapshot.data["page"] as AboutUsPageData;

    this.meta = meta;
    this.values = page.data.values;
    this.documents = page.data.documents;
  }

  get videoId(): string {
    try {
      if (!this.meta.videoLink) return "";
      const url = new URL(this.meta.videoLink);
      return url.searchParams.get("v") ?? "";
    } catch (error) {
      return "";
    }
  }
}
