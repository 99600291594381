import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Apollo} from "apollo-angular";
import {map, Observable} from "rxjs";
import {PageType} from "src/app/enums/page-type";
import {UnsubscribePageData} from "src/app/interfaces/portal/unsubscribe-page";
import {GET_UNSUBSCRIBED_PAGE_DATA} from "src/app/queries/portal/newsletters.query";

@Injectable({
  providedIn: "root",
})
export class UnsubscribePageResolver implements Resolve<UnsubscribePageData> {
  constructor(private apollo: Apollo) {}

  resolve(route: ActivatedRouteSnapshot): Observable<UnsubscribePageData> {
    return this.apollo
      .query({
        query: GET_UNSUBSCRIBED_PAGE_DATA,
        variables: {
          userId: route.params["userId"],
        },
      })
      .pipe(
        map((result): UnsubscribePageData => {
          const {page: meta, ...page} = result.data;
          return {
            title: $localize`إلغاء الاشتراك في النشرة البريدية`,
            breadcrumbs: [
              {title: $localize`البوابة`, routerLink: ""},
              {title: $localize`إلغاء الاشتراك في النشرة البريدية`, routerLink: "unsubscribe"},
            ],
            data: page.newsletters,
            meta,
            type: PageType.INTERIOR,
          };
        }),
      );
  }
}
