import {AfterViewInit, Directive, ElementRef, Input, OnDestroy} from "@angular/core";

import {WindowService} from "../services/window.service";

@Directive({
  selector: "[counter]",
})
export class CounterDirective implements AfterViewInit, OnDestroy {
  readonly MAX_DURATION = 2000;

  @Input() trigger!: Element;
  @Input() stop!: number;

  private observer!: IntersectionObserver;

  private start!: number;
  private previous!: number;

  private done = false;

  constructor(private elementRef: ElementRef<Element>, private windowService: WindowService) {}

  ngAfterViewInit(): void {
    this.windowService.run(() => {
      this.observer = new IntersectionObserver(
        (entries) => {
          const entry = entries[0];

          if (entry && entry.isIntersecting) {
            window.requestAnimationFrame(this.step.bind(this));
          }
        },
        {threshold: 0},
      );

      this.observer.observe(this.trigger);
    });
  }

  ngOnDestroy(): void {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  step(timestamp: number) {
    this.start = this.start >= 0 ? this.start : timestamp;
    const elapsed = timestamp - this.start;

    const speed = this.stop / this.MAX_DURATION;

    if (this.previous !== timestamp) {
      const count = Math.min(Math.ceil(speed * elapsed), this.stop);
      this.elementRef.nativeElement.textContent = String(count);
      if (count === this.stop) {
        this.done = true;
      }
    }

    if (elapsed < this.MAX_DURATION) {
      this.previous = timestamp;
      if (!this.done) {
        window.requestAnimationFrame(this.step.bind(this));
      }
    }
  }
}
