import {gql} from "apollo-angular";
import {I18nFunctions} from "src/app/interfaces/i18n";
import {Center, CentersPage} from "src/app/interfaces/portal/centers-page";

export const GET_CENTERS_PAGE = ({field, filter}: I18nFunctions) => gql<{page: CentersPage; centers: Center[]}, void>`
  query {
    page: centers_page {
      title: ${field("title")}
      image {
        id
        filename: filename_download
        title
      }
    }
    centers(filter: {status: {_eq: "published"}}, sort: ["order"]) {
      id
      name: ${field("name")}
      origin: ${field("origin")}
      url
      image {
        id
        filename: filename_download
        title
      }
    }
  }
`;
