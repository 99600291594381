import {gql} from "apollo-angular";
import {ResourcesPage, ResourcesPageListItem} from "src/app/interfaces/portal/resources-page";

export const GET_RESOURCES_PAGE = gql<{page: ResourcesPage; resources: ResourcesPageListItem[]}, void>`
  query {
    page: resources_page {
      title
      image {
        id
        filename: filename_download
        title
      }
    }
    resources(filter: {status: {_eq: "published"}}, sort: ["date_created"]) {
      title
      description
      image {
        id
        filename: filename_download
        title
      }
      link
    }
  }
`;
