<app-page>
  <page-header></page-header>
  <page-content>
    <div class="container">
      <div class="row">
        <div class="col-lg-8 col-md-10 mx-auto">
          <h3 class="fw-bold text-secondary mt-3 mb-5">
            {{ page.intro }}
          </h3>
          <div [innerHTML]="page.content"></div>
        </div>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>
