import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {faCalendar} from "@fortawesome/free-regular-svg-icons";
import {faFlag} from "@fortawesome/free-solid-svg-icons";
import {ReplaySubject, takeUntil} from "rxjs";
import {NewsPage, NewsPageData, NewsPageListItem} from "src/app/interfaces/portal/news-page";
import {SearchMenuField, SearchMenuResult} from "src/app/interfaces/search-menu";
import {PortalContentService} from "src/app/services/portal-content.service";
import {environment} from "src/environments/environment";

@Component({
  selector: "app-news-page",
  templateUrl: "./news-page.component.html",
  styleUrls: ["./news-page.component.scss"],
})
export class NewsPageComponent implements OnInit, OnDestroy {
  faCalendar = faCalendar;
  faFlag = faFlag;

  loading!: boolean;

  meta!: NewsPage;
  news: NewsPageListItem[] = [];

  page = 0;
  paginate = true;
  emptyFilter = {
    title: "",
    content: "",
  };
  filterFields: SearchMenuField[] = [
    {key: "title", name: $localize`العنوان`, type: "text"},
    {key: "content", name: $localize`النص`, type: "text"},
  ];
  filter!: SearchMenuResult;

  unsubscribe$ = new ReplaySubject(1);

  constructor(private route: ActivatedRoute, private cmsService: PortalContentService) {}

  ngOnInit(): void {
    const {meta} = this.route.snapshot.data["page"] as NewsPageData;
    this.meta = meta;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(1);
    this.unsubscribe$.complete();
  }

  getNextPage() {
    this.page++;
    this.loading = true;
    this.cmsService
      .listNews(this.page, this.filter, "-date")
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (result) => {
          console.log(result)
          this.news.push(...result);
          if (result.length < environment.limit.news) {
            this.paginate = false;
          }
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  onSubmit(filter: SearchMenuResult) {
    this.filter = filter;
    this.refreshData();
  }

  onClear() {
    this.filter = {...this.emptyFilter};
    this.refreshData();
  }

  refreshData() {
    this.page = 0;
    this.paginate = true;
    this.news = [];
    this.getNextPage();
  }
}
