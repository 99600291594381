import {BreakpointObserver} from "@angular/cdk/layout";
import {DOCUMENT} from "@angular/common";
import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import {NavigationEnd, Router} from "@angular/router";
import {faMinusSquare, faPlusSquare} from "@fortawesome/free-regular-svg-icons";
import {faBars, faDroplet, faDropletSlash, faGlobe, faSearch, faSitemap} from "@fortawesome/free-solid-svg-icons";
import {filter, fromEvent, ReplaySubject, takeUntil} from "rxjs";
import {Direction} from "src/app/enums/direction";
import {Locale} from "src/app/enums/locale";
import {NavbarItem} from "src/app/interfaces/navbar-item";
import {AccessibilityService} from "src/app/services/accessiblity.service";
import {LocalizationService} from "src/app/services/localization.service";
import {ModalService} from "src/app/services/modal.service";
import {MenuArrowPosition, MenuItem, MenuItemType, NavbarService} from "src/app/services/navbar.service";
import {WindowService} from "src/app/services/window.service";
import {environment} from "src/environments/environment";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit, OnDestroy {
  faBars = faBars;
  faGlobe = faGlobe;
  faDroplet = faDroplet;
  faDropletSlash = faDropletSlash;
  faMinusSquare = faMinusSquare;
  faPlusSquare = faPlusSquare;
  faSitemap = faSitemap;
  faSearch = faSearch;
  Direction = Direction;
  MenuItemType = MenuItemType;
  MenuArrowPosition = MenuArrowPosition;

  menu!: MenuItem[];

  @ViewChild("searchForm") searchForm!: ElementRef<HTMLFormElement>;
  @ViewChild("offcanvas") offcanvasRef!: ElementRef<HTMLDivElement>;
  @ViewChildren("offcanvasMenu")
  offcanvasMenus!: QueryList<ElementRef<HTMLElement>>;

  direction = Direction.RIGHT_TO_LEFT;

  isXXLarge!: boolean;
  isLogoBreakpoint!: boolean;
  heightFlag = true;

  searchKeyword = "";
  showSearchInput!: boolean;

  loading!: boolean;

  unsubscribe$ = new ReplaySubject(1);

  constructor(
    private breakpointObserver: BreakpointObserver,
    private localizationService: LocalizationService,
    private navbarService: NavbarService,
    private router: Router,
    private modalService: ModalService,
    @Inject(DOCUMENT) private document: Document,
    private windowService: WindowService,
    private accessibilityService: AccessibilityService,
  ) {
    this.clearOffcanvasStyles();
  }

  ngOnInit(): void {
    this.breakpointObserver
      .observe(["(min-width: 1400px)", "(min-width: 992px)"])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => {
        this.isXXLarge = value.breakpoints["(min-width: 1400px)"];
        this.isLogoBreakpoint = value.breakpoints["(min-width: 992px)"];
      });

    this.windowService.run(() => {
      fromEvent(window, "scroll")
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => {
          this.heightFlag = this.document.documentElement.scrollTop <= ((this.isXXLarge ? 85 : 60) + 48) / 2;
        });
    });

    this.subscribeToLocalizationChange();

    this.loadNavBarItems();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(1);
    this.unsubscribe$.complete();
  }

  get logo() {
    return `assets/images/abegs-vertical${
      this.localizationService.getDirection() === Direction.RIGHT_TO_LEFT ? "" : "-ltr"
    }.svg`;
  }

  get localizedPortalAnchor() {
    return this.localizationService.getLocale() === Locale.ARABIC
      ? {href: `${environment.hostURI}/en`, text: "EN"}
      : {href: `${environment.hostURI}`, text: "العربية"};
  }

  get grayscale() {
    return this.accessibilityService.grayscale;
  }

  toggleGrayscale() {
    this.accessibilityService.toggleGrayscale();
  }

  increaseFontSize() {
    this.accessibilityService.increaseFontSize();
  }

  decreaseFontSize() {
    this.accessibilityService.decreaseFontSize();
  }

  @HostListener("document:click", ["$event"])
  onDocumentClick(event: PointerEvent) {
    this.handleSearchFormOnDocumentClick(event);
    this.handleOffcanvasOnDocumentClick(event);
  }

  handleSearchFormOnDocumentClick(event: PointerEvent) {
    const target = event.target as HTMLElement | null;
    if (!target || !this.searchForm || !this.searchForm.nativeElement) return;
    if (!this.searchForm.nativeElement.contains(target)) {
      this.showSearchInput = false;
    }
  }

  handleOffcanvasOnDocumentClick(event: PointerEvent) {
    const target = event.target as HTMLElement | null;
    if (!target || !target.parentElement || !this.offcanvasMenus || !this.offcanvasMenus.length) {
      return;
    }

    this.offcanvasMenus.forEach((elementRef) => {
      if (!target.parentElement) return;
      const element = elementRef.nativeElement;
      if (!element.contains(target)) {
        const dropdownTrigger = element.querySelector(".dropdown-toggle");
        if (!dropdownTrigger) return;
        this.windowService.run(() => {
          const Dropdown = (window as any).bootstrap.Dropdown;
          const dropdownRef = Dropdown.getOrCreateInstance(dropdownTrigger);
          if (dropdownRef) {
            dropdownRef.hide();
          }
        });
      }
    });
  }

  subscribeToLocalizationChange() {
    this.direction = this.localizationService.getDirection();
  }

  clearOffcanvasStyles() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(() => {
        this.document.body.removeAttribute("style");
      });
  }

  // TODO the navbar component reloads each time a new page is visited. this is fine for now due to gql caching but we would like to optimize in the future
  loadNavBarItems() {
    this.loading = true;
    this.navbarService
      .getMenu()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (menu) => {
          this.menu = menu;
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  onMouseOver(trigger: HTMLAnchorElement, menu: HTMLDivElement) {
    if (!trigger || !menu) return;
    this.windowService.run(() => {
      const Dropdown = (window as any).bootstrap.Dropdown;
      const dropdownRef = Dropdown.getOrCreateInstance(trigger);
      if (dropdownRef) {
        dropdownRef.show();
      }
    });
  }

  onMouseLeave(trigger: HTMLAnchorElement, menu: HTMLDivElement) {
    if (!trigger || !menu) return;
    this.windowService.run(() => {
      const Dropdown = (window as any).bootstrap.Dropdown;
      const dropdownRef = Dropdown.getOrCreateInstance(trigger);
      if (dropdownRef) {
        dropdownRef.hide();
      }
    });
  }

  dismissOffcanvas() {
    if (!this.offcanvasRef || !this.offcanvasRef.nativeElement) return;
    this.windowService.run(() => {
      const Offcanvas = (window as any).bootstrap.Offcanvas;
      const offcanvas = Offcanvas.getOrCreateInstance(this.offcanvasRef.nativeElement);
      if (offcanvas) {
        offcanvas.hide();
      }
    });
  }

  onSearch() {
    // TODO
    if (this.searchKeyword) {
      this.searchKeyword = "";
      this.showSearchInput = false;
    }
  }

  openSearchPopup() {
    this.modalService.setModalState(true);
  }

  filterByCategory(items: MenuItem[] | undefined, category: string) {
    if (!items) return [];
    return items.filter((item) => item.category === category);
  }
}
