import {Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {faGlobe} from "@fortawesome/free-solid-svg-icons";
import {BooksPageData, BookStep} from "src/app/interfaces/portal/books-page";

@Component({
  selector: "app-books-page",
  templateUrl: "./books-page.component.html",
  styleUrls: ["./books-page.component.scss"],
})
export class BooksPageComponent implements OnInit {
  faGlobe = faGlobe;

  bookSteps!: BookStep[];

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    const page = this.route.snapshot.data["page"] as BooksPageData;
    this.bookSteps = page.data.bookSteps;
  }
}
