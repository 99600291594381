import {gql as gqlapollo} from "apollo-angular";
import gql from "graphql-tag";
import {I18nFunctions} from "src/app/interfaces/i18n";
import {SearchPage} from "src/app/interfaces/portal/search-page";

export const GET_SEARCH_PAGE = ({field}: I18nFunctions) => gqlapollo<{page: SearchPage}, void>`
  query {
    page: search_page {
      title: ${field("title")}
      image {
        id
        filename: filename_download
        title
      }
    }
  }
`;

export const searchPageQueryAr = ($t: I18nFunctions) => {
  return gql`
  query search(
    $searchKey: String!
    $newsLimit: Int!
    $newsOffset: Int!
    $eventsLimit: Int!
    $eventsOffset: Int!
    $publicationsLimit: Int!
    $publicationsOffset: Int!
    $projectsLimit: Int!
    $projectsOffset: Int!
    $reportsLimit: Int!
    $reportsOffset: Int!
  ) {
    news(
      limit: $newsLimit
      offset: $newsOffset
      filter: {status: {_eq: "published"}, _or: [
        { ${$t.field("title")}: { _icontains: $searchKey } },
        { ${$t.field("content")}: { _icontains: $searchKey } },
      ]}
      sort: ["-date_created"]
    ) {
      id
      title: ${$t.field("title")}
      date
      image {
        id
        filename: filename_download
        title
      }
      country: ${$t.field("country")}
    }
    events(
      limit: $eventsLimit
      offset: $eventsOffset
      filter: {status: {_eq: "published"}, _or: [
        { name: { _icontains: $searchKey } },
        { content: { _icontains: $searchKey } },
        { summary: { _icontains: $searchKey } },
      ]}
      sort: ["-date_created"]
    ) {
      id
      name
      image {
        id
        filename: filename_download
        title
      }
      startDate: start_date
      endDate: end_date
      type {
        slug
        name
      }
    }
    projects(
      limit: $projectsLimit
      offset: $projectsOffset
      filter: {status: {_eq: "published"}, _or: [
        { ${$t.field("name")}: { _icontains: $searchKey } },
        { ${$t.field("summary")}: { _icontains: $searchKey } },
        { ${$t.field("goals")}: { _icontains: $searchKey } },
      ]}
      sort: ["-date_created"]
    ) {
      id
      name: ${$t.field("name")}
      summary: ${$t.field("summary")}
      year
      image {
        id
        filename: filename_download
        title
      }
      goals: ${$t.field("goals")}
      center: ${$t.field("center_name")}
      link
      sponsors {
        sponsor: sponsors_id {
          name: ${$t.field("name")}
          image {
            id
            filename: filename_download
            title
          }
          link
        }
      }
    }
    publications(
      limit: $publicationsLimit
      offset: $publicationsOffset
      filter: {status: {_eq: "published"}, _or: [
        { name: { _icontains: $searchKey } },
        { summary: { _icontains: $searchKey } },
      ]}
      sort: ["-date_created"]
    ) {
      id
      name
      summary
      classification {
        id
        name
      }
      author {
        id
        name
        image {
          id
          filename: filename_download
          title
        }
      }
      publishDate: publish_date
      releaseDate: release_date
      purchaseLink: purchase_link
      pages
      image {
        id
        filename: filename_download
        title
      }
      views
      videoUrl: video_url
      audioSummary: audio_summary {
        id
        filename: filename_download
        title
        type
      }
      file {
        id
        filename: filename_download
        title
        type
      }
    }

    reports(
      limit: $reportsLimit
      offset: $reportsOffset
      filter: {status: {_eq: "published"}, _or: [
        { title: { _icontains: $searchKey } },
        { content: { _icontains: $searchKey } },
      ]}
      sort: ["-date_created"]
    ) {
      id
      title
      content
      date
      file {
        id
        filename: filename_download
        title
        type
        filesize
      }
      image {
        id
        filename: filename_download
        title
      }
    }
  }
`;
};

export const searchPageQueryEn = ($t: I18nFunctions) => {
  return gql`
  query search(
    $searchKey: String!
    $newsLimit: Int!
    $newsOffset: Int!
    $projectsLimit: Int!
    $projectsOffset: Int!
  ) {
    news(
      limit: $newsLimit
      offset: $newsOffset
      filter: {status: {_eq: "published"}, _or: [
        { ${$t.field("title")}: { _icontains: $searchKey } },
        { ${$t.field("content")}: { _icontains: $searchKey } },
      ]}
      sort: ["-date_created"]
    ) {
      id
      title: ${$t.field("title")}
      date
      image {
        id
        filename: filename_download
        title
      }
      country: ${$t.field("country")}
    }
    projects(
      limit: $projectsLimit
      offset: $projectsOffset
      filter: {status: {_eq: "published"}, _or: [
        { ${$t.field("name")}: { _icontains: $searchKey } },
        { ${$t.field("summary")}: { _icontains: $searchKey } },
        { ${$t.field("goals")}: { _icontains: $searchKey } },
      ]}
      sort: ["-date_created"]
    ) {
      id
      name: ${$t.field("name")}
      summary: ${$t.field("summary")}
      year
      image {
        id
        filename: filename_download
        title
      }
      goals: ${$t.field("goals")}
      center: ${$t.field("center_name")}
      link
      sponsors {
        sponsor: sponsors_id {
          name: ${$t.field("name")}
          image {
            id
            filename: filename_download
            title
          }
          link
        }
      }
    }
  }
`;
};

export const getSearchPageTotalsQueryAr = ($t: I18nFunctions) => {
  return gql`
    query search($searchKey: String!) {
      eventsPaging: events_aggregated(filter: {status: {_eq: "published"}, _or: [
        { name: { _icontains: $searchKey } },
        { content: { _icontains: $searchKey } },
        { summary: { _icontains: $searchKey } },
      ]}) {
        count {
          id
        }
      }
      newsPaging: news_aggregated(filter: {status: {_eq: "published"}, _or: [
        { ${$t.field("title")}: { _icontains: $searchKey } },
        { ${$t.field("content")}: { _icontains: $searchKey } },
      ]}) {
        count {
          id
          newsEnCount: title_en
        }
      }

      projectsPaging: projects_aggregated(filter: {status: {_eq: "published"}, _or: [
        { ${$t.field("name")}: { _icontains: $searchKey } },
        { ${$t.field("summary")}: { _icontains: $searchKey } },
        { ${$t.field("goals")}: { _icontains: $searchKey } },
      ]}) {
        count {
          id
          projectsEnCount: name_en
        }
      }
      publicationsPaging: publications_aggregated(filter: {status: {_eq: "published"}, _or: [
        { name: { _icontains: $searchKey } },
        { summary: { _icontains: $searchKey } },
      ]}) {
        count {
          id
        }
      }
      reportsPaging: reports_aggregated(filter: {status: {_eq: "published"}, _or: [
        { title: { _icontains: $searchKey } },
        { content: { _icontains: $searchKey } },
      ]}) {
        count {
          id
        }
      }
    }
  `;
};

export const getSearchPageTotalsQueryEn = ($t: I18nFunctions) => {
  return gql`
    query search($searchKey: String!) {
      newsPaging: news_aggregated(filter: {status: {_eq: "published"}, _or: [
        { ${$t.field("title")}: { _icontains: $searchKey } },
        { ${$t.field("content")}: { _icontains: $searchKey } },
      ]}) {
        count {
          id
          newsEnCount: title_en
        }
      }

      projectsPaging: projects_aggregated(filter: {status: {_eq: "published"}, _or: [
        { ${$t.field("name")}: { _icontains: $searchKey } },
        { ${$t.field("summary")}: { _icontains: $searchKey } },
        { ${$t.field("goals")}: { _icontains: $searchKey } },
      ]}) {
        count {
          id
          projectsEnCount: name_en
        }
      }
    }
  `;
};
