import {AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild} from "@angular/core";
import {DirectusFile} from "src/app/interfaces/directus-file";
import {environment} from "src/environments/environment";

@Component({
  selector: "app-landing-hero",
  templateUrl: "./landing-hero.component.html",
  styleUrls: ["./landing-hero.component.scss"],
})
export class LandingHeroComponent implements OnInit, AfterViewInit {
  @Input() video!: DirectusFile;
  storageLink!: string;
  videoLoaded!: boolean;

  @ViewChild("videoRef", {static: true}) videoRef!: ElementRef<HTMLVideoElement>;

  ngOnInit(): void {
    this.setStorageLink();
  }

  ngAfterViewInit(): void {
    try {
      if (this.videoRef && this.videoRef.nativeElement) {
        this.videoRef.nativeElement.autoplay = true;
        this.videoRef.nativeElement.muted = true;
        this.videoRef.nativeElement.loop = true;
        this.videoRef.nativeElement.playsInline = true;
      }
    } catch (error) {
      console.error(error);
    }
  }

  play() {
    if (this.videoRef && this.videoRef.nativeElement) {
      this.videoRef.nativeElement.play().catch((error) => {
        console.error(error);
      });
    }
  }

  loaded() {
    this.videoLoaded = true;
  }

  @HostListener("document:mousedown", [])
  onMouseDown() {
    this.play();
  }

  @HostListener("document:mousemove", [])
  onMouseMove() {
    this.play();
  }

  @HostListener("document:touchstart", [])
  onTouchStart() {
    this.play();
  }

  @HostListener("document:scroll", [])
  onScroll() {
    this.play();
  }

  @HostListener("document:keydown", [])
  onKeyDown() {
    this.play();
  }

  setStorageLink() {
    const container = environment.production ? "abegs-portal-prod" : "abegs-portal-test";
    this.storageLink = `https://cdn-files.abegs.org/${container}/uploads/${this.video.filename_disk}`;
  }
}
