import {DOCUMENT} from "@angular/common";
import {Inject, Injectable} from "@angular/core";
import {environment} from "src/environments/environment";

import {WindowService} from "./window.service";

@Injectable({
  providedIn: "root",
})
export class AccessibilityService {
  private readonly SMALLEST_FONT_SIZE = 14;
  private readonly LARGEST_FONT_SIZE = 24;
  private readonly FONT_SIZE_STEP = 2;

  constructor(@Inject(DOCUMENT) private document: Document, private windowService: WindowService) {
    this.validateStorageVersion();
  }

  get grayscale() {
    const localStorage = this.windowService.getLocalStorage();
    if (localStorage) {
      const grayscale = localStorage.getItem("preferences.grayscale");
      return grayscale ? grayscale === "true" : true;
    } else {
      return false;
    }
  }

  set grayscale(value: boolean) {
    const localStorage = this.windowService.getLocalStorage();
    if (localStorage) {
      localStorage.setItem("preferences.grayscale", String(value));
    }
  }

  get fontSize() {
    const localStorage = this.windowService.getLocalStorage();
    if (window) {
      const fontSize = localStorage.getItem("preferences.fontsize");
      return fontSize ? parseInt(fontSize, 10) : this.SMALLEST_FONT_SIZE;
    } else {
      return this.SMALLEST_FONT_SIZE;
    }
  }

  set fontSize(value: number) {
    const localStorage = this.windowService.getLocalStorage();
    if (localStorage) {
      localStorage.setItem("preferences.fontsize", String(value));
    }
  }

  toggleGrayscale() {
    this.grayscale = !this.grayscale;
    this.updateGrayscale();
  }

  increaseFontSize() {
    this.fontSize = Math.min(this.LARGEST_FONT_SIZE, this.fontSize + this.FONT_SIZE_STEP);
    this.updateDocumentFontSize();
  }

  decreaseFontSize() {
    this.fontSize = Math.max(this.SMALLEST_FONT_SIZE, this.fontSize - this.FONT_SIZE_STEP);
    this.updateDocumentFontSize();
  }

  updateDocumentFontSize() {
    this.document.documentElement.style.fontSize = `${this.fontSize}px`;
  }

  updateGrayscale() {
    if (this.grayscale) {
      this.document.documentElement.classList.remove("grayscale");
    } else {
      this.document.documentElement.classList.add("grayscale");
    }
  }

  private validateStorageVersion() {
    const localStorage = this.windowService.getLocalStorage();
    if (localStorage) {
      const storageVersion = localStorage.getItem("storage.version");
      if (!storageVersion || parseInt(storageVersion, 10) !== environment.storage.version) {
        localStorage.removeItem("preferences.grayscale");
        localStorage.removeItem("preferences.fontsize");
        localStorage.setItem("storage.version", environment.storage.version.toString());
      }
    }
  }

  toggleDarkMode() {
    const localStorage = this.windowService.getLocalStorage();
    if (localStorage) {
      const theme = localStorage.getItem("theme");
      if (theme) {
        localStorage.setItem("theme", theme === "dark" ? "light" : "dark");
      } else {
        localStorage.setItem("theme", window.matchMedia("(prefers-color-scheme: dark)").matches ? "light" : "dark");
      }
      this.updateDarkMode();
    }
  }

  updateDarkMode() {
    const theme = localStorage.getItem("theme");
    if (theme === "dark" || (!theme && window.matchMedia("(prefers-color-scheme: dark)").matches)) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }
}
