import {Component, Input, ViewChild} from "@angular/core";
import {faCalendar} from "@fortawesome/free-regular-svg-icons";
import {faArrowLeft, faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {SwiperDirective} from "src/app/directives/swiper.directive";
import {EventSlug} from "src/app/interfaces/portal/events-page";
import {LandingEventSlide} from "src/app/interfaces/portal/landing-page";
import {PortalContentService} from "src/app/services/portal-content.service";
import {SwiperOptions} from "swiper/types";

@Component({
  selector: "app-landing-events",
  templateUrl: "./landing-events.component.html",
  styleUrls: ["./landing-events.component.scss"],
})
export class LandingEventsComponent {
  faArrowLeft = faArrowLeft;
  faArrowRight = faArrowRight;
  faCalendar = faCalendar;

  @Input() title!: string;
  @Input() events!: LandingEventSlide[];

  @ViewChild(SwiperDirective) swiper!: SwiperDirective;

  swiperOptions: SwiperOptions = {
    slidesPerView: 1,
    loop: true,
    autoplay: {
      delay: 10000,
    },
    breakpoints: {
      1400: {
        slidesPerView: 3,
      },
      992: {
        slidesPerView: 2,
      },
      // 768: {
      //   slidesPerView: 2,
      // },
    },
  };

  constructor(private cmsService: PortalContentService) {}

  next() {
    this.swiper.next();
  }

  previous() {
    this.swiper.previous();
  }

  identify(index: number, item: LandingEventSlide) {
    return item.id;
  }

  getSlugIcon(slug: EventSlug) {
    return this.cmsService.getEventSlugIcon(slug);
  }
}
