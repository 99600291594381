/* eslint-disable no-constant-condition */
import {gql} from "apollo-angular";
import {CarouselSlide} from "src/app/interfaces/carousel-slide";
import {I18nFunctions} from "src/app/interfaces/i18n";
import {
  LandingEventSlide,
  LandingNewsSlide,
  LandingPage,
  LandingProjectSlide,
  LandingServiceSlide,
} from "src/app/interfaces/portal/landing-page";

export const GET_LANDING_PAGE_DATA = ({field, filter, ar}: I18nFunctions) => {
  const variables = ["$carouselLimit: Int!", "$newsLimit: Int!", "$projectsLimit: Int!"];
  if (ar()) {
    variables.push("$eventsLimit: Int!");
  }
  return gql<
    {
      page: LandingPage;
      carousel: CarouselSlide[];
      news: LandingNewsSlide[];
      events: LandingEventSlide[];
      services: LandingServiceSlide[];
      projects: LandingProjectSlide[];
    },
    {carouselLimit: number; newsLimit: number; eventsLimit: number; projectsLimit: number}
  >`
  query (${variables.join(", ")}) {
    page: home_page {
      title: ${field("title")}
      newsSectionTitle: ${field("news_title")}
      eventsSectionTitle: ${field("events_title")}
      servicesSectionTitle: ${field("services_title")}
      projectsSectionTitle: ${field("projects_title")}
      numbersSectionTitle: ${field("numbers_title")}
      heroVideo: hero_video {
        id
        filename: filename_download
        filename_disk
        title
      }
      heroType: hero_type
    }
    carousel: home_slider(limit: $carouselLimit, filter: {_and: [{status: {_eq: "published"}}, ${filter(
      "title",
    )}]}, sort: ["-date_created"]) {
      title: ${field("title")}
      subtitle: ${field("sub_title")}
      image {
        id
        filename: filename_download
        title
      }
    }
    news(limit: $newsLimit, filter: {_and: [{status: {_eq: "published"}}, ${filter("title")}]}, sort: ["-date"]) {
      id
      title: ${field("title")}
      date
      thumbnail {
        id
        filename: filename_download
        title
      }
      image {
        id
        filename: filename_download
        title
      }
      country: ${field("country")}
    }
    ${
      ar()
        ? `
    events(limit: $eventsLimit, filter: {_and: [{status: {_eq: "published"}}, ${filter("name")}]}, sort: ["-start_date"]) {
      id
      name: ${field("name")}
      image {
        id
        filename: filename_download
        title
      }
      startDate: start_date
      endDate: end_date
      type {
        name: ${field("name")}
        slug: ${field("slug")}
      }
    }
    `
        : ""
    }
    services(filter: {_and: [{status: {_eq: "published"}}, {is_internal: {_eq: false}}, ${filter("name")}]}) {
      id
      name: ${field("name")}
      description: ${field("description")}
      url
      image {
        id
        filename: filename_download
        title
      }
    }
    projects(limit: $projectsLimit, filter: {_and: [{status: {_eq: "published"}}, ${filter(
      "name",
    )}]}, sort: ["-date_created"]) {
      id
      name: ${field("name")}
      summary: ${field("summary")}
      image {
        id
        filename: filename_download
        title
      }
    }
  }
`;
};
