import {gql} from "apollo-angular";
import {I18nFunctions} from "src/app/interfaces/i18n";
import {StrategicPlansPage, StrategicPlansPageListItem} from "src/app/interfaces/portal/strategic-plans-page";

export const GET_STRATEGIC_PLANS_PAGE = ({field, filter}: I18nFunctions) => gql<
  {page: StrategicPlansPage; plans: StrategicPlansPageListItem[]},
  void
>`
  query {
    page: strategic_plans_page {
      title: ${field("title")}
      image {
        id
        filename: filename_download
        title
      }
      pageImage: page_image {
        id
        filename: filename_download
        title
      }
      experienceInYears: experience_years
    }
    plans(filter: {_and: [{status: {_eq: "published"}}, ${filter("name")}]}, sort: ["order"]) {
      id
      order
      name: ${field("name")}
      image {
        id
        filename: filename_download
        title
      }
      summary: ${field("summary")}
    }
  }
`;
