import {Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {faCalendar, faLocationDot} from "@fortawesome/free-solid-svg-icons";
import {SingleEvent, SingleEventPage, SingleEventPageData} from "src/app/interfaces/portal/single-event-page";

@Component({
  selector: "app-single-event-page",
  templateUrl: "./single-event-page.component.html",
  styleUrls: ["./single-event-page.component.scss"],
})
export class SingleEventPageComponent implements OnInit {
  faCalendarMinus = faCalendar;
  faLocationDot = faLocationDot;

  meta!: SingleEventPage;
  event!: SingleEvent;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    const {meta, ...page} = this.route.snapshot.data["page"] as SingleEventPageData;

    this.meta = meta;
    this.event = page.data;
  }
}
