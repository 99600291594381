import {Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {AgreementsPage, AgreementsPageData, AgreementsPageListItem} from "src/app/interfaces/portal/agreements-page";

@Component({
  selector: "app-agreements-page",
  templateUrl: "./agreements-page.component.html",
  styleUrls: ["./agreements-page.component.scss"],
})
export class AgreementsPageComponent implements OnInit {
  meta!: AgreementsPage;
  agreements!: AgreementsPageListItem[];

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    const {meta, ...page} = this.route.snapshot.data["page"] as AgreementsPageData;

    this.meta = meta;
    this.agreements = page.data;
  }
}
