<app-page>
  <page-header></page-header>
  <page-content>
    <div id="contact-us">
      <div class="container">
        <div class="row gx-3">
          <div class="col-12 col-xl-6">
            <div class="shadow card rounded-0">
              <div class="card-body p-5">
                <h5 i18n class="text-primary fw-bold">يسعدنا التواصل معكم</h5>

                <hr style="border-style: dotted" />

                <form [formGroup]="formGroup">
                  <div class="row">
                    <div *ngIf="forms && forms.length > 0" class="col-md-12">
                      <div class="mb-3">
                        <label i18n for="reason" class="form-label small">سبب التواصل</label>
                        <select
                          class="form-select rounded-0 focus-shadow-none"
                          aria-label="سبب التواصل"
                          [ngClass]="{'is-invalid': reason.invalid && (reason.dirty || reason.touched)}"
                          id="reason"
                          formControlName="reason"
                        >
                          <option selected [ngValue]="null">شكوى / استفسار</option>
                          <option *ngFor="let form of forms" [ngValue]="form.id">{{ form.title }}</option>
                        </select>
                      </div>
                    </div>
                    <ng-container *ngIf="!reason.value; else formRef">
                      <div class="col-md-6">
                        <div class="mb-3">
                          <label i18n for="name" class="form-label small">الاسم</label>
                          <input
                            required
                            type="text"
                            class="form-control rounded-0 focus-shadow-none"
                            [ngClass]="{'is-invalid': name.invalid && (name.dirty || name.touched)}"
                            id="name"
                            formControlName="name"
                          />
                          <div i18n *ngIf="name.hasError('required')" class="invalid-feedback">الاسم مطلوب</div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="mb-3">
                          <label i18n for="email" class="form-label small">البريد الإلكتروني</label>
                          <input
                            required
                            type="email"
                            class="form-control rounded-0 focus-shadow-none"
                            [ngClass]="{'is-invalid': email.invalid && (email.dirty || email.touched)}"
                            id="email"
                            formControlName="email"
                          />
                          <div i18n *ngIf="email.hasError('required')" class="invalid-feedback">البريد الإلكتروني مطلوب</div>
                          <div i18n *ngIf="email.hasError('email')" class="invalid-feedback">البريد الإلكتروني غير صحيح</div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="mb-3">
                          <label i18n for="tel" class="form-label small">رقم الجوال</label>
                          <input
                            required
                            type="tel"
                            class="form-control rounded-0 focus-shadow-none"
                            [ngClass]="{'is-invalid': phone.invalid && (phone.dirty || phone.touched)}"
                            id="tel"
                            formControlName="phone"
                          />
                          <div i18n *ngIf="name.hasError('required')" class="invalid-feedback">رقم الجوال مطلوب</div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="mb-3">
                          <label i18n for="address" class="form-label small">عنوان الرسالة</label>
                          <input
                            required
                            type="text"
                            class="form-control rounded-0 focus-shadow-none"
                            [ngClass]="{'is-invalid': address.invalid && (address.dirty || address.touched)}"
                            id="address"
                            formControlName="address"
                          />
                          <div i18n *ngIf="name.hasError('required')" class="invalid-feedback">عنوان الرسالة مطلوب</div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="mb-3">
                          <label i18n for="message" class="form-label small">نص الرسالة</label>
                          <textarea
                            required
                            rows="6"
                            class="form-control rounded-0 focus-shadow-none"
                            [ngClass]="{'is-invalid': message.invalid && (message.dirty || message.touched)}"
                            id="message"
                            formControlName="message"
                          ></textarea>
                          <div i18n *ngIf="name.hasError('required')" class="invalid-feedback">نص الرسالة مطلوبة</div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <button
                          class="btn rounded-0 py-3 w-100"
                          (click)="onSubmit()"
                          [disabled]="sending || formGroup.invalid || success || hasError"
                          [ngClass]="{'btn-primary': !hasError, 'btn-danger': hasError}"
                        >
                          <span *ngIf="!success && !hasError">
                            <fa-icon *ngIf="sending" [icon]="faCircleNotch" animation="spin" class="me-2"></fa-icon>
                            <ng-container i18n>إرسال</ng-container>
                          </span>
                          <span *ngIf="success" class="fade-in-down">
                            <fa-icon [icon]="faCheck" class="me-2"></fa-icon>
                            <ng-container i18n>تم إرسال الرسالة بنجاح</ng-container>
                          </span>
                          <span *ngIf="hasError" class="fade-in-down">
                            <fa-icon [icon]="faTriangleExclamation" class="me-2"></fa-icon>
                            <ng-container i18n>حدث خطأ يرجى المحاولة لاحقًا</ng-container>
                          </span>
                        </button>
                      </div>
                    </ng-container>
                    <ng-template #formRef>
                      <div *ngIf="form">
                        <p *ngIf="form.description">{{ form.description }}</p>

                        <a [href]="form.file | fileLink" target="_blank" class="btn btn-primary rounded-0 py-3 w-100">
                          <ng-container i18n>تحميل النموذج</ng-container>
                        </a>
                      </div>
                    </ng-template>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="col-12 col-xl-6">
            <div class="shadow mt-5 mt-xl-0 card rounded-0">
              <div class="card-image">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3625.4406924822692!2d46.62238387876512!3d24.677373154954676!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f1c5aeb3823af%3A0xccf806fdbe26923a!2sABEGS!5e0!3m2!1sen!2seg!4v1672107424332!5m2!1sen!2seg"
                  width="100%"
                  height="300"
                  style="border: 0"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                  title="map"
                ></iframe>
              </div>
              <div class="card-body p-5">
                <div class="mt-4">
                  <p>
                    <fa-icon [icon]="faLocationDot" class="me-3"></fa-icon>
                    {{ page.location }}
                  </p>

                  <p>
                    <fa-icon [icon]="faPhone" flip="horizontal" class="me-3"></fa-icon>
                    <a dir="ltr" href="tel: {{ page.phone }}">{{ page.phone }}</a>
                  </p>

                  <p>
                    <fa-icon [icon]="faEnvelope" flip="horizontal" class="me-3"></fa-icon>
                    <a href="mailto: {{ page.email }}">{{ page.email }}</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>
