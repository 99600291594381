<div class="card sidebar-card style-1">
  <div class="card-header">{{ title }}</div>
  <div class="card-body">
    <div class="list-group list-group-flush">
      <a
        *ngFor="let publication of publications; index as i"
        (click)="navigateToPublicationDetails(publication.id)"
        class="list-group-item list-group-item-action publication-card"
      >
        <div class="d-flex flex-row gap-3">
          <img
            [src]="publication.image | imageLink"
            class="book-img img-fluid contain center d-block mx-auto"
            [alt]="publication.image.title"
          />

          <div class="flex-grow-1 content">
            <p class="">{{ publication.name }}</p>

            <p *ngIf="publication.releaseDate" class="fw-light small text-end mt-3">
              <ng-container i18n>سنة النشر</ng-container>
              : {{ publication.releaseDate }}
            </p>
          </div>
        </div>
      </a>
    </div>
  </div>
</div>
