import {Component, Input, ViewChild} from "@angular/core";
import {faCalendar} from "@fortawesome/free-regular-svg-icons";
import {faArrowLeft, faArrowRight, faFlag} from "@fortawesome/free-solid-svg-icons";
import {SwiperDirective} from "src/app/directives/swiper.directive";
import {LandingNewsSlide} from "src/app/interfaces/portal/landing-page";
import {SwiperOptions} from "swiper/types/swiper-options";

@Component({
  selector: "app-landing-news",
  templateUrl: "./landing-news.component.html",
  styleUrls: ["./landing-news.component.scss"],
})
export class LandingNewsComponent {
  faArrowLeft = faArrowLeft;
  faArrowRight = faArrowRight;
  faCalendar = faCalendar;
  faFlag = faFlag;

  @Input() title!: string;
  @Input() news!: LandingNewsSlide[];

  @ViewChild(SwiperDirective) swiper!: SwiperDirective;

  swiperOptions: SwiperOptions = {
    slidesPerView: 1,
    loop: true,
    autoplay: {
      delay: 10000,
    },
    breakpoints: {
      1400: {
        slidesPerView: 4,
      },
      992: {
        slidesPerView: 3,
      },
      768: {
        slidesPerView: 2,
      },
    },
  };

  next() {
    this.swiper.next();
  }

  previous() {
    this.swiper.previous();
  }
}
