<app-page>
  <page-header></page-header>
  <page-content>
    <div id="publications">
      <div class="container">
        <div class="filters my-4">
          <form>
            <div class="d-flex flex-column flex-md-row justify-content-between">
              <div class="d-flex flex-row gap-4">
                <div>
                  <input
                    name="filter-type"
                    type="radio"
                    class="btn-check"
                    id="filter-newest"
                    value="filter-newest"
                    autocomplete="off"
                    [checked]="activeView === PublicationView.NEW_PUBLICATIONS"
                  />
                  <label i18n (click)="selectNewPublications()" class="btn" for="filter-newest">جديد إصداراتنا</label>
                </div>
                <div>
                  <input
                    name="filter-type"
                    type="radio"
                    class="btn-check"
                    id="filter-all"
                    value="filter-all"
                    autocomplete="off"
                    [checked]="activeView === PublicationView.ALL_PUBLICATIONS"
                  />
                  <label i18n (click)="selectAllPublications()" class="btn" for="filter-all">جميع الإصدارات</label>
                </div>

                <div>
                  <input
                    name="filter-type"
                    type="radio"
                    class="btn-check"
                    id="filter-most-watched"
                    value="filter-most-watched"
                    autocomplete="off"
                    [checked]="activeView === PublicationView.MOST_VIEWED_PUBLICATIONS"
                  />
                  <label i18n (click)="selectMostViewedPublications()" class="btn" for="filter-most-watched">
                    الأكثر مشاهدة
                  </label>
                </div>
              </div>
              <app-search-menu [fields]="filterFields" (search)="onSubmit($event)" (clear)="onClear()"></app-search-menu>
            </div>
          </form>
        </div>
        <div i18n *ngIf="!isLoading && (!publications || publications.length === 0)" class="no-data text-center p-5">
          لا توجد بيانات
        </div>
        <div class="row">
          <div *ngFor="let publication of publications; index as i" class="col-lg-4 col-md-6 mt-4">
            <div class="item single-publication position-relative">
              <div class="img-wrapper">
                <div class="card">
                  <div class="card-body">
                    <img
                      [src]="publication.image | imageLink"
                      class="book-img img-fluid contain center d-block mx-auto"
                      [alt]="publication.image.title"
                      onerror="if (this.src !== './assets/images/tafawoq-student-avatar.png') this.src = './assets/images/tafawoq-student-avatar.png'"
                    />
                  </div>
                </div>
                <div class="mt-3">
                  <h6 class="fw-bold title">{{ publication.name }}</h6>
                  <div class="mt-2 author">
                    <img src="assets/images/author-avatar.jpg" alt="المؤلف" />
                    {{ publication.author.name }}
                  </div>
                </div>
              </div>
              <a [routerLink]="publication.id" class="stretched-link"></a>
            </div>
          </div>
          <div *ngIf="isLoading" class="d-flex align-items-center justify-content-center bg-light" style="min-height: 300px">
            <div class="spinner"></div>
          </div>
          <div *ngIf="!isLoading && paginate" class="text-center mt-4">
            <button i18n class="btn btn-outline-dark rounded-0 px-5 py-4" (click)="getNextPage()">عرض المزيد</button>
          </div>
        </div>
      </div>
      <div class="bg-secondary bg-opacity-10 text-center py-5 mt-5">
        <h4 i18n class="fw-bold text-primary">للحصول على إصدارات المكتب</h4>
        <p i18n class="text-width-800 text-light-gray">
          يمكنكم الحصول على إصدارات مكتب التربية العربي لدول الخليج عبر منافذ البيع الموجودة في مراكز المكتب في الدول الأعضاء
          وأيضا عن طريق شراء النسخة الورقية عن طريق خدمة واصل
        </p>

        <div class="container mt-4 mb-5">
          <div class="row">
            <div class="col-lg-3 col-md-4 col-sm-6 col-12">
              <div class="card border-0 text-center px-3 py-3">
                <img
                  class="img-fluid d-block mx-auto mb-3"
                  src="assets/images/abegs.svg"
                  style="width: 170px; height: 100px"
                  alt="متجر إصدارات المكتب"
                />
                <p>متجر إصدارات المكتب</p>
                <div>
                  <a i18n href="https://abegs.store/" class="btn btn-primary" target="_blank">الموقع الإلكتروني</a>
                </div>
              </div>
            </div>
            <div *ngFor="let center of centers; index as i" class="col-lg-3 col-md-4 col-sm-6 col-12">
              <div class="card border-0 text-center px-3 py-3">
                <img
                  class="img-fluid d-block mx-auto mb-3"
                  [src]="center.image | imageLink"
                  style="width: 170px; height: 100px"
                  [alt]="center.image.title"
                />
                <p>{{ center.name }}</p>
                <div>
                  <a i18n [href]="center.url" class="btn btn-primary" target="_blank">الموقع الإلكتروني</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>
