import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Apollo} from "apollo-angular";
import {map, Observable} from "rxjs";
import {PageType} from "src/app/enums/page-type";
import {SearchPageData} from "src/app/interfaces/portal/search-page";
import {GET_SEARCH_PAGE} from "src/app/queries/portal/search-page.query";
import {LocalizationService} from "src/app/services/localization.service";
import {SeoService} from "src/app/services/seo.service";

@Injectable({
  providedIn: "root",
})
export class SearchPageResolver implements Resolve<SearchPageData> {
  constructor(private apollo: Apollo, private seoService: SeoService, private localizationService: LocalizationService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<SearchPageData> {
    return this.apollo
      .query({
        query: GET_SEARCH_PAGE(this.localizationService.getI18nFns()),
      })
      .pipe(
        map((result): SearchPageData => {
          const {page: meta} = result.data;
          return this.seoService.patchTitleAndMetaTags(route, {
            title: meta.title,
            breadcrumbs: [
              {title: $localize`البحث`, routerLink: ""},
              {title: meta.title, routerLink: "search"},
            ],
            data: null,
            meta,
            type: PageType.INTERIOR,
          });
        }),
      );
  }
}
