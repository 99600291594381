import {Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {ResourcesPageData, ResourcesPageListItem} from "src/app/interfaces/portal/resources-page";

@Component({
  selector: "app-resources-page",
  templateUrl: "./resources-page.component.html",
  styleUrls: ["./resources-page.component.scss"],
})
export class ResourcesPageComponent implements OnInit {
  resources!: ResourcesPageListItem[];

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    const page = this.route.snapshot.data["page"] as ResourcesPageData;
    this.resources = page.data;
  }
}
