import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {FormControl, FormGroup} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import {SearchMenuField, SearchMenuResult} from "src/app/interfaces/search-menu";
import {WindowService} from "src/app/services/window.service";

@Component({
  selector: "app-search-menu",
  templateUrl: "./search-menu.component.html",
  styleUrls: ["./search-menu.component.scss"],
})
export class SearchMenuComponent implements OnInit {
  faXmark = faXmark;

  @Input() fields!: SearchMenuField[];

  @Output() search = new EventEmitter<{[key: string]: string | boolean | undefined}>();
  @Output() clear = new EventEmitter<void>();

  @ViewChild("filterMenuToggle") filterMenuToggleRef!: ElementRef<HTMLElement>;

  filterFormGroup!: FormGroup;

  appliedFields = 0;

  id = (Math.random() * 1000000).toString();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private windowService: WindowService,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.initFilterFormGroup();
    this.getFilterFromQueryParams();
    this.onFilterFormSubmit();
  }

  initFilterFormGroup() {
    const formGroupObj: {[key: string]: FormControl<string | boolean | undefined>} = {};
    this.fields.forEach((field) => {
      if (field.type === "tristate") {
        formGroupObj[field.key] = new FormControl(undefined, {nonNullable: true});
      } else {
        formGroupObj[field.key] = new FormControl("", {nonNullable: true});
      }
    });
    this.filterFormGroup = new FormGroup(formGroupObj);
  }

  onFilterFormSubmit(event?: Event) {
    if (event) event.preventDefault();
    if (this.filterFormGroup.invalid) return;
    this.hideFilterMenu();
    this.setQueryParams();
    this.search.next(this.filterFormGroup.value);
    this.appliedFields = Object.values(this.filterFormGroup.value).filter((value) => !!value).length;
  }

  hideFilterMenu() {
    if (!this.filterMenuToggleRef?.nativeElement) return;

    this.windowService.run(() => {
      const Dropdown = (window as any).bootstrap.Dropdown;
      const dropdown = Dropdown.getOrCreateInstance(this.filterMenuToggleRef.nativeElement);
      dropdown.hide();
    });
  }

  resetFilter() {
    const emptyFilter: SearchMenuResult = {};
    this.fields.forEach((field) => {
      if (field.type === "tristate") {
        emptyFilter[field.key] = undefined;
      } else {
        emptyFilter[field.key] = "";
      }
    });
    this.filterFormGroup.reset(emptyFilter);
    this.setQueryParams();
    this.clear.next();
    this.appliedFields = 0;
  }

  resetField(key: string) {
    this.filterFormGroup.controls[key].reset();
  }

  getOptions(options?: {value: string; text: string}[]) {
    return options ?? [];
  }

  getFilterFromQueryParams() {
    const queryParams = this.route.snapshot.queryParams ?? {};
    const normalizedValues: SearchMenuResult = {};
    this.fields.forEach((field) => {
      if (field.type === "tristate") {
        const fieldValue = queryParams[field.key];
        if (fieldValue === true || fieldValue === "true") {
          normalizedValues[field.key] = true;
        } else if (fieldValue === false || fieldValue === "false") {
          normalizedValues[field.key] = false;
        } else {
          normalizedValues[field.key] = undefined;
        }
      } else {
        normalizedValues[field.key] = queryParams[field.key]?.trim() || undefined;
      }
    });
    this.filterFormGroup.patchValue(normalizedValues);
  }

  setQueryParams() {
    if (this.filterFormGroup.invalid) return;

    const params = new URLSearchParams();
    const filter = this.filterFormGroup.value;
    Object.keys(filter).forEach((key) => {
      if (filter[key] !== undefined) {
        params.set(key, filter[key]);
      }
    });

    this.router.navigate([], {
      queryParams: Object.fromEntries(params.entries()),
      relativeTo: this.route,
    });
  }

  rotateState(key: string) {
    const control = this.filterFormGroup.get(key) as FormControl<boolean | undefined>;
    if (control.value === true) {
      control.setValue(false);
    } else if (control.value === false) {
      control.setValue(undefined);
    } else {
      control.setValue(true);
    }
  }

  getControlValue(key: string) {
    const control = this.filterFormGroup.get(key) as FormControl<boolean | undefined>;
    return control.value;
  }
}
