<app-page>
  <page-header></page-header>
  <page-content>
    <div #trigger id="home-numbers">
      <div class="container">
        <!-- <p class="alert p-4 text-center text-light" style="border-width: 2px; background-color: #278266">
          <span i18n>إحصائيات مكتب التربية العربي لدول الخليج لعام</span>
        </p> -->
        <div class="carousel my-5 row row-cols-lg-5 row-cols-md-3 row-cols-2 d-flex justify-content-center">
          <div *ngFor="let row of statistics; let i = index" class="col g-4">
            <div class="single-statistics-card hover-shadow rounded-3">
              <div class="card border-secondary card-effects w-100">
                <div class="card-body">
                  <span
                    style="font-size: var(--statistics-card-title-fontSize)"
                    class="d-flex justify-content-center align-items-center flex-nowrap text-center text-secondary fw-bold"
                  >
                    <span counter [stop]="row.value" [trigger]="trigger" class="counter"></span>
                    <span>+</span>
                  </span>
                  <p
                    style="font-size: var(--statistics-card-description-fontSize)"
                    class="text-center text-light-gray tw-line-clamp-2 tw-leading-6 tw-min-h-[3rem]"
                  >
                    {{ row.description }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>
