import {CarouselSlide} from "src/app/interfaces/carousel-slide";
import {DirectusFile} from "src/app/interfaces/directus-file";
import {ImageFile} from "src/app/interfaces/image-file";
import {PageData} from "src/app/interfaces/page-data";

import {EventSlug} from "./events-page";

export enum LandingHeroType {
  VIDEO = "video",
  PHOTOS = "photos",
}

export interface LandingPage {
  title: string;
  newsSectionTitle: string;
  eventsSectionTitle: string;
  servicesSectionTitle: string;
  projectsSectionTitle: string;
  numbersSectionTitle: string;
  applicationsSectionTitle: string;
  heroVideo: DirectusFile | null;
  heroType: LandingHeroType;
}

export interface LandingNewsSlide {
  id: string;
  title: string;
  thumbnail: ImageFile | null;
  image: ImageFile;
  date: string;
  country: string;
}

export interface LandingEventSlide {
  id: string;
  name: string;
  image: ImageFile;
  startDate: string;
  endDate: string;
  type: {
    name: string;
    slug: EventSlug;
  };
}

export interface LandingServiceSlide {
  id: string;
  name: string;
  description: string;
  image: ImageFile;
  url: string;
}

export interface LandingProjectSlide {
  id: string;
  name: string;
  summary: string;
  image: ImageFile;
}

export type LandingPageData = PageData<
  {
    carousel: CarouselSlide[];
    news: LandingNewsSlide[];
    events?: LandingEventSlide[];
    services: LandingServiceSlide[];
    projects: LandingProjectSlide[];
  },
  LandingPage
>;
