import {Component, Input} from "@angular/core";
import {Router} from "@angular/router";
import {parseISO} from "date-fns";
import {Publication} from "src/app/interfaces/portal/publications-page";

@Component({
  selector: "app-publications-sidebar",
  templateUrl: "./publications-sidebar.component.html",
  styleUrls: ["./publications-sidebar.component.scss"],
})
export class PublicationsSidebarComponent {
  @Input() title!: string;
  @Input() publications!: Publication[];

  constructor(private router: Router) {}

  navigateToPublicationDetails(publicationId: string) {
    this.router.navigateByUrl(`/publications/${publicationId}`);
  }

  // getPublicationDate(publication: Publication): string | undefined {
  //   if (publication.releaseDate) {
  //     let result = publication.releaseDate;
  //     const labels = ["سنة", "سنه"];
  //     labels.forEach((label) => {
  //       result = result.replace(label, "");
  //     });
  //     return result.trim();
  //   }

  //   if (publication.publishDate) {
  //     const date = parseISO(publication.publishDate.trim());
  //     return date.getFullYear().toString();
  //   }

  //   return undefined;
  // }
}
