import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Apollo} from "apollo-angular";
import {map, Observable} from "rxjs";
import {PageType} from "src/app/enums/page-type";
import {PublishingLandingPageData} from "src/app/interfaces/publishing/publishing-landing-page";
import {GET_PUBLISHING_LANDING_PAGE_DATA} from "src/app/queries/publishing/publishing-landing-page.query";
import {SeoService} from "src/app/services/seo.service";

@Injectable({
  providedIn: "root",
})
export class PublishingLandingPageResolver implements Resolve<PublishingLandingPageData> {
  constructor(private apollo: Apollo, private seoService: SeoService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<PublishingLandingPageData> {
    return this.apollo
      .query({
        query: GET_PUBLISHING_LANDING_PAGE_DATA,
      })
      .pipe(
        map((result): PublishingLandingPageData => {
          const {page: meta, ...page} = result.data;
          return this.seoService.patchTitleAndMetaTags(route, {
            title: meta.title,
            breadcrumbs: [
              {title: $localize`النشر`, routerLink: "publishing"},
              {
                title: meta.title,
                routerLink: "publishing",
              },
            ],
            data: page,
            meta,
            type: PageType.LANDING,
          });
        }),
      );
  }
}
