<app-page>
  <page-header></page-header>
  <page-content>
    <div class="container">
      <div class="row">
        <div class="col">
          <div [innerHTML]="department.content"></div>
        </div>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>
