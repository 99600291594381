import {DOCUMENT} from "@angular/common";
import {Component, Inject} from "@angular/core";
import {faFacebookF, faLinkedinIn, faWhatsapp, faXTwitter} from "@fortawesome/free-brands-svg-icons";
import {faMinus, faPlus, faShare} from "@fortawesome/free-solid-svg-icons";
import {AccessibilityService} from "src/app/services/accessiblity.service";

@Component({
  selector: "app-fonts-share",
  templateUrl: "./fonts-share.component.html",
  styleUrls: ["./fonts-share.component.scss"],
})
export class FontsShareComponent {
  faPlus = faPlus;
  faMinus = faMinus;
  faShare = faShare;
  faFacebookF = faFacebookF;
  faLinkedinIn = faLinkedinIn;
  faWhatsapp = faWhatsapp;
  faXTwitter = faXTwitter;

  constructor(@Inject(DOCUMENT) private document: Document, private accessibilityService: AccessibilityService) {}

  get url() {
    return this.document.location.href;
  }

  increaseFontSize() {
    this.accessibilityService.increaseFontSize();
  }

  decreaseFontSize() {
    this.accessibilityService.decreaseFontSize();
  }
}
