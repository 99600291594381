<button *ngIf="enabled && show && !playing && !error" [disabled]="loading" class="tts" (click)="play()">
  <div class="btn">
    <fa-icon [icon]="loading ? faCircleNotch : faEarListen" [animation]="loading ? 'spin' : undefined"></fa-icon>
  </div>
  <div class="instruction" i18n>الاستماع للصفحة</div>
</button>

<!-- <div *ngIf="error && show" class="tts">
  <div class="btn">
    <fa-icon [icon]="faWarning"></fa-icon>
  </div>
  <div class="instruction" i18n>الصوتيات غير متاحة حاليًا</div>
</div> -->

<div *ngIf="enabled && show && playing && !error" class="tts">
  <button *ngIf="!paused" class="btn" i18n-title title="إيقاف" (click)="pause()">
    <fa-icon [icon]="faPause"></fa-icon>
  </button>
  <button *ngIf="paused" class="btn" i18n-title title="تشغيل" (click)="play()">
    <fa-icon [icon]="faPlay"></fa-icon>
  </button>
  <button class="btn" i18n-title title="تشغيل من البداية" (click)="restart()">
    <fa-icon [icon]="faRotate"></fa-icon>
  </button>
</div>
