import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Apollo} from "apollo-angular";
import {map, Observable} from "rxjs";
import {PageType} from "src/app/enums/page-type";
import {SingleProjectPageData} from "src/app/interfaces/portal/single-project-page";
import {GET_SINGLE_PROJECT_PAGE} from "src/app/queries/portal/single-project.query";
import {LocalizationService} from "src/app/services/localization.service";
import {SeoService} from "src/app/services/seo.service";
import {WindowService} from "src/app/services/window.service";

@Injectable({
  providedIn: "root",
})
export class SingleProjectPageResolver implements Resolve<SingleProjectPageData> {
  constructor(
    private apollo: Apollo,
    private windowService: WindowService,
    private seoService: SeoService,
    private localizationService: LocalizationService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<SingleProjectPageData> {
    return this.apollo
      .query({
        query: GET_SINGLE_PROJECT_PAGE(this.localizationService.getI18nFns()),
        variables: {
          projectId: route.params["projectId"],
        },
      })
      .pipe(
        map((result): SingleProjectPageData => {
          const {page: meta, ...page} = result.data;
          return this.seoService.patchTitleAndMetaTags(route, {
            title: page.projects[0].name,
            description: this.windowService.getFriendlyDescription(page.projects[0].summary),
            breadcrumbs: [
              {title: $localize`البوابة`, routerLink: ""},
              {title: meta.title, routerLink: "projects"},
              {title: page.projects[0].name, routerLink: `projects/${page.projects[0].id}`},
            ],
            data: page.projects[0],
            meta,
            type: PageType.INTERIOR,
          });
        }),
      );
  }
}
