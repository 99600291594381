<!-- eslint-disable @angular-eslint/template/elements-content -->
<div id="home-services-slider" class="mb-5">
  <div class="container">
    <div class="d-none d-lg-flex justify-content-center align-items-center">
      <h3 class="text-secondary fw-bold text-center" style="font-size: var(--home-section-title-fontSize)">
        {{ title }}
      </h3>
    </div>
    <div class="d-flex justify-content-between align-items-center justify-content-lg-end m-2">
      <h3 class="text-secondary fw-bold text-center d-lg-none m-0" style="font-size: var(--home-section-title-fontSize)">
        {{ title }}
      </h3>
      <div class="landing-swiper-controls">
        <button class="btn navigation-btn prev" (click)="next()">
          <fa-icon [icon]="faArrowLeft" class="fa-lg"></fa-icon>
        </button>

        <button class="btn navigation-btn next" (click)="previous()">
          <fa-icon [icon]="faArrowRight" class="fa-lg"></fa-icon>
        </button>
      </div>
    </div>
    <swiper-container swiper #swiperRef [config]="swiperOptions" init="false">
      <swiper-slide *ngFor="let slide of services; let i = index" class="p-2">
        <div class="single-service-card-item card hover-shadow">
          <div class="card-body d-flex flex-column">
            <img class="d-block mx-auto mt-3 mb-4 w-100 blend" [src]="slide.image | imageLink" [alt]="slide.name" />
            <h6
              style="font-size: var(--services-card-title-fontSize)"
              class="title fw-bold text-center mb-3 tw-line-clamp-1 tw-leading-[1.5rem] tw-min-h-[1.5rem]"
            >
              {{ slide.name }}
            </h6>
            <p
              style="font-size: var(--services-card-description-fontSize)"
              class="text-center tw-line-clamp-3 tw-leading-[1.5rem] tw-min-h-[4.5rem]"
            >
              {{ slide.description }}
            </p>
          </div>
          <a [href]="slide.url" class="stretched-link"></a>
        </div>
      </swiper-slide>
    </swiper-container>
  </div>
</div>
