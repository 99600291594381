<section
  id="publishing-cta"
  class="py-5"
  [ngStyle]="{'background-image': 'url(' + (meta.ctaSectionImage | imageLink) + ')'}"
>
  <div class="h-100">
    <div class="container text-light d-flex flex-column align-items-center justify-content-center h-100 py-5 text-center">
      <h5 class="fs-1 fw-bold mt-4 mb-5">{{ meta.ctaSectionTitle }}</h5>
      <p class="">{{ meta.ctaSectionContent }}</p>
      <div class="d-flex justify-content-center align-items-center gap-5 mt-5">
        <a class="cta-btn" [href]="meta.applicationFormPdf | fileLink" target="_blank">
          <ng-container i18n>نموذج التقديم</ng-container>
          <fa-icon [icon]="faDownload"></fa-icon>
        </a>
        <a class="cta-btn" [href]="meta.acknowledgementPdf | fileLink" target="_blank">
          <ng-container i18n>إقرار بالملكية الفكرية</ng-container>
          <fa-icon [icon]="faDownload"></fa-icon>
        </a>
      </div>
    </div>
  </div>
</section>
