import {gql} from "apollo-angular";
import {I18nFunctions} from "src/app/interfaces/i18n";
import {ManagerPage} from "src/app/interfaces/portal/manager-page";

export const GET_MANAGER_PAGE = ({field, filter}: I18nFunctions) => gql<{page: ManagerPage}, void>`
  query {
    page: manager_page {
      title: ${field("title")}
      image {
        id
        filename: filename_download
        title
      }
      managerName: ${field("manager_name")}
      managerImage: manager_image {
        id
        filename: filename_download
        title
      }
      certificates: ${field("certificates")}
      experiences: ${field("experiences")}
      committees: ${field("committees")}
      studies: ${field("studies")}
    }
  }
`;
