import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Apollo} from "apollo-angular";
import {map, Observable} from "rxjs";
import {PageType} from "src/app/enums/page-type";
import {CountriesPageData} from "src/app/interfaces/portal/countries-page";
import {GET_COUNTRIES_PAGE} from "src/app/queries/portal/countries-page.query";
import {LocalizationService} from "src/app/services/localization.service";
import {SeoService} from "src/app/services/seo.service";

@Injectable({
  providedIn: "root",
})
export class CountriesPageResolver implements Resolve<CountriesPageData> {
  constructor(private apollo: Apollo, private seoService: SeoService, private localizationService: LocalizationService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<CountriesPageData> {
    return this.apollo
      .query({
        query: GET_COUNTRIES_PAGE(this.localizationService.getI18nFns()),
      })
      .pipe(
        map((result): CountriesPageData => {
          const {page: meta, ...page} = result.data;
          return this.seoService.patchTitleAndMetaTags(route, {
            title: meta.title,
            breadcrumbs: [
              {title: $localize`البوابة`, routerLink: ""},
              {title: meta.title, routerLink: "members"},
            ],
            data: page.countries,
            meta,
            type: PageType.INTERIOR,
          });
        }),
      );
  }
}
