<app-page>
  <page-header></page-header>
  <page-content>
    <div class="container">
      <ul class="nav nav-pills" id="" role="tablist">
        <li *ngFor="let center of centers; index as i" class="nav-item mx-2" role="presentation">
          <button
            class="nav-link px-4"
            [ngClass]="{active: i === 0}"
            id="tab{{ i }}-tab"
            data-bs-toggle="tab"
            attr.data-bs-target="#tab{{ i }}-tab-pane"
            type="button"
            role="tab"
            attr.aria-controls="tab{{ i }}-tab-pane"
            aria-selected="false"
          >
            <fa-icon [icon]="faEllipsisVertical" class="me-2"></fa-icon>
            {{ center.name }}
          </button>
        </li>
      </ul>
      <hr class="my-5 border-very-light-gray" />
      <div class="tab-content" id="manager-tabs">
        <div
          *ngFor="let center of centers; index as i"
          class="tab-pane fade show"
          [ngClass]="{active: i === 0}"
          id="tab{{ i }}-tab-pane"
          attr.role="tabpane{{ i }}"
          attr.aria-labelledby="tab{{ i }}-tab"
          tabindex="0"
        >
          <div class="row">
            <div class="col-lg-2 col-md-3 col-sm-4 image-with-url-btn">
              <img [src]="center.image | imageLink" class="w-100 img-fluid img-thumbnail" [alt]="center.image.title" />
              <div class="mt-4">
                <a [href]="center.url" target="_blank" class="d-flex align-items-center">
                  <span class="text-very-light-gray me-3">|</span>
                  <fa-icon [icon]="faGlobe" class="me-2"></fa-icon>
                  <ng-container i18n>الموقع الإلكتروني للمركز</ng-container>
                </a>
              </div>
            </div>
            <div class="col">
              <div [innerHTML]="center.origin"></div>
            </div>
          </div>
        </div>
        <div
          *ngFor="let center of centers; index as i"
          class="tab-pane fade"
          id="tab{{ i + 1 }}-tab-pane"
          role="tabpanel"
          attr.aria-labelledby="tab{{ i + 1 }}-tab"
          tabindex="0"
        >
          ...
        </div>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>
