import {gql} from "apollo-angular";
import {EventSlug} from "src/app/interfaces/portal/events-page";
import {SingleEvent, SingleEventPage} from "src/app/interfaces/portal/single-event-page";

export const GET_SINGLE_EVENT_PAGE = gql<{page: SingleEventPage; events: SingleEvent[]}, {eventId: string; slug: EventSlug}>`
  query ($eventId: GraphQLStringOrFloat!, $slug: String!) {
    page: calendar_page {
      title
      image {
        id
        filename: filename_download
        title
      }
    }
    events: events(filter: {_and: [{id: {_eq: $eventId}}, {type: {slug: {_eq: $slug}}}]}) {
      id
      name
      startDate: start_date
      endDate: end_date
      image {
        id
        filename: filename_download
        title
      }
      content
      location
      link
      participants(filter: {participants_id: {status: {_eq: "published"}}}) {
        participant: participants_id {
          id
          name
          biography
          image {
            id
            filename: filename_download
            title
          }
        }
      }
    }
  }
`;
