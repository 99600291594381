<div id="home-events-slider" class="py-5">
  <div class="container">
    <div class="d-none d-lg-flex justify-content-center align-items-center">
      <h3 class="text-secondary fw-bold text-center" style="font-size: var(--home-section-title-fontSize)">
        {{ title }}
      </h3>
    </div>
    <div class="d-flex justify-content-between align-items-center justify-content-lg-end m-2">
      <h3 class="text-secondary fw-bold text-center d-lg-none m-0" style="font-size: var(--home-section-title-fontSize)">
        {{ title }}
      </h3>
      <div class="landing-swiper-controls">
        <button class="btn navigation-btn prev" (click)="next()">
          <fa-icon [icon]="faArrowLeft" class="fa-lg"></fa-icon>
        </button>

        <button class="btn navigation-btn next" (click)="previous()">
          <fa-icon [icon]="faArrowRight" class="fa-lg"></fa-icon>
        </button>
      </div>
    </div>

    <swiper-container swiper #swiperRef [config]="swiperOptions" init="false">
      <swiper-slide *ngFor="let slide of events; trackBy: identify" class="p-2">
        <div
          [routerLink]="['/', slide.type.slug, slide.id]"
          class="d-flex align-items-center justify-content-start gap-3 cursor-pointer"
        >
          <div
            class="bg-primary fw-bold h5 text-white d-flex flex-column align-items-center justify-content-center mb-0"
            style="min-width: 90px; aspect-ratio: 1/1"
          >
            <p class="mb-0">{{ slide.startDate | date : "dd" }}</p>
            <p class="mb-0">{{ slide.startDate | date : "MMMM" }}</p>
          </div>
          <div class="text-light-gray mb-0 meeting-name d-flex flex-column gap-2">
            <div>
              <span class="d-flex align-items-center gap-2 text-secondary fw-bold">
                <fa-icon [icon]="getSlugIcon(slide.type.slug)"></fa-icon>
                <ng-container>{{ slide.type.name }}</ng-container>
              </span>
            </div>
            <a class="text-black tw-line-clamp-2 tw-leading-[1.5rem] tw-min-h-[3rem]">
              {{ slide.name }}
            </a>
          </div>
        </div>

        <!-- <div class="card-image">
          <img [src]="slide.image | imageLink" class="img-fluid w-100" style="height: 225px" [alt]="slide.name" />
        </div> -->
        <!-- <div class="card">
          <div class="card-body">
            <h6 class="title fs-5 mb-4 mt-3 tw-line-clamp-2 tw-leading-[1.5rem] tw-min-h-[3rem]">
              {{ slide.name }}
            </h6>
            <div style="font-size: var(--news-card-description-fontSize)" class="fw-light">
              <div class="d-flex align-items-center gap-2">
                <fa-icon [icon]="faCalendar"></fa-icon>
                <ng-container>{{ slide.startDate | date : "YYYY/MM/dd" }}</ng-container>
              </div>
              <div class="d-flex align-items-center gap-2">
                <fa-icon [icon]="getSlugIcon(slide.type.slug)"></fa-icon>
                <ng-container>{{ slide.type.name }}</ng-container>
              </div>
            </div>
            <a [routerLink]="['/', slide.type.slug, slide.id]" class="stretched-link"></a>
          </div> -->
        <!-- </div> -->
      </swiper-slide>
    </swiper-container>
  </div>
</div>
