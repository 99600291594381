/* eslint-disable @typescript-eslint/no-explicit-any */
import {Component, OnDestroy, OnInit} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {
  faCheck,
  faCircleNotch,
  faCloudArrowDown,
  faEnvelope,
  faLocationDot,
  faPhone,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import {ReCaptchaV3Service} from "ng-recaptcha";
import {ReplaySubject, switchMap, takeUntil} from "rxjs";
import {ContactUsPage, ContactUsPageData, RequestForm} from "src/app/interfaces/portal/contact-us-page";
import {ApiService} from "src/app/services/api.service";
import {environment} from "src/environments/environment";

@Component({
  selector: "app-contact-us-page",
  templateUrl: "./contact-us-page.component.html",
  styleUrls: ["./contact-us-page.component.scss"],
})
export class ContactUsPageComponent implements OnInit, OnDestroy {
  faCircleNotch = faCircleNotch;
  faCheck = faCheck;
  faTriangleExclamation = faTriangleExclamation;
  faLocationDot = faLocationDot;
  faPhone = faPhone;
  faEnvelope = faEnvelope;
  faCloudArrowDown = faCloudArrowDown;

  formGroup = new FormGroup({
    reason: new FormControl(null),
    name: new FormControl("", {nonNullable: true, validators: [Validators.required]}),
    email: new FormControl("", {nonNullable: true, validators: [Validators.required, Validators.email]}),
    phone: new FormControl("", {nonNullable: true, validators: [Validators.required]}),
    address: new FormControl("", {nonNullable: true, validators: [Validators.required]}),
    message: new FormControl("", {nonNullable: true, validators: [Validators.required]}),
  });

  sending!: boolean;
  success!: boolean;
  hasError!: boolean;

  page!: ContactUsPage;
  forms?: RequestForm[];

  unsubscribe$ = new ReplaySubject(1);

  constructor(
    private route: ActivatedRoute,
    private recaptchaV3Service: ReCaptchaV3Service,
    private apiService: ApiService,
  ) {}

  ngOnInit(): void {
    const page = this.route.snapshot.data["page"] as ContactUsPageData;
    this.page = page.meta;
    this.forms = page.data;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(1);
    this.unsubscribe$.complete();
  }

  get form() {
    if (!this.reason.value || !this.forms) return undefined;

    return this.forms.find((form) => form.id === this.reason.value);
  }

  get reason() {
    return this.formGroup.controls.reason;
  }

  get name() {
    return this.formGroup.controls.name;
  }

  get email() {
    return this.formGroup.controls.email;
  }

  get phone() {
    return this.formGroup.controls.phone;
  }

  get address() {
    return this.formGroup.controls.address;
  }

  get message() {
    return this.formGroup.controls.message;
  }

  onSubmit() {
    if (this.formGroup.invalid) return;
    this.sending = true;

    const {reason: _, ...form} = this.formGroup.getRawValue();

    this.recaptchaV3Service
      .execute(environment.recaptcha.contactUsAction)
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap((token) => {
          return this.apiService.submitAction(environment.recaptcha.contactUsAction, token, form);
        }),
      )
      .subscribe({
        next: () => {
          this.sending = false;
          this.success = true;
        },
        error: () => {
          this.sending = this.success = false;
          this.hasError = true;
        },
      });
  }
}
