import {Pipe, PipeTransform} from "@angular/core";
import {DirectusFile} from "src/app/interfaces/directus-file";
import {environment} from "src/environments/environment";

@Pipe({
  name: "fileLink",
})
export class FileLinkPipe implements PipeTransform {
  transform(value: DirectusFile): string {
    const ext = value.filename.split(".").at(-1);
    const url = `${environment.assetsURI}/${value.id}.${ext}`;
    return url;
  }
}
