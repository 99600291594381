export const environment = {
  production: false,
  type: "sandbox",
  hostURI: "https://testserver.abegs.org",
  graphqlURI: "https://testserver.abegs.org/cms/graphql",
  assetsURI: "https://cdn-files.abegs.org/abegs-portal-test/uploads",
  serverURI: "https://testserver.abegs.org/portal/api",
  limit: {
    landingCarousel: 4,
    landingNews: 8,
    landingEvents: 6,
    landingProjects: 8,

    publications: 12,
    news: 12,
    conferences: 12,
    projects: 12,
    events: 12,

    publicationsSidebar: 4,
    reportsSidebar: 4,

    upcomingEvents: 4,

    photosGallery: 15,
    videosGallery: 15,
    infographicsGallery: 15,
  },
  recaptcha: {
    siteKey: "6LcALQclAAAAAKMQAcyB5z0-e_4VLznW8MsWTTLU",
    contactUsAction: "portal_contact_us",
    newslettersAction: "portal_newsletters",
    cancelSubscriptionAction: "portal_cancel_subscription",
  },
  storage: {
    version: 1,
  },
  analytics: {
    measurementId: "G-NBP7HVYRPW",
  },
  tts: {
    host: "https://test-api-rest-tts.abegs.org",
  },
};
