import {gql} from "apollo-angular";
import {
  InfographFilter,
  InfographicGalleryPage,
  InfographicGalleryPageListItem,
} from "src/app/interfaces/portal/infographic-gallery-page";

export const GET_INFOGRAPHIC_GALLERY_PAGE = gql<{page: InfographicGalleryPage}, void>`
  query {
    page: infographic_page {
      title
      image {
        id
        filename: filename_download
        title
      }
    }
  }
`;

export const LIST_INFOGRAPHICS = gql<{infographics: InfographicGalleryPageListItem[]}, {page: number; limit: number}>`
  query ($limit: Int!, $page: Int!) {
    infographics(filter: {status: {_eq: "published"}}, sort: ["-date_created"], limit: $limit, page: $page) {
      id
      title
      image {
        id
        filename: filename_download
        title
      }
    }
  }
`;

export const getInfographocsByFilterFactory = (filter?: InfographFilter) => {
  const variables: string[] = [];
  const args: string[] = ['{status: {_eq: "published"}}'];
  let cursor = "";

  if (filter?.title) {
    variables.push("$title: String!");
    args.push("{title: {_icontains: $title}}");
  }
  if (filter?.publicationName) {
    variables.push("$publicationName: String!");
    args.push("{publication: {name: {_icontains: $publicationName}}}");
  }

  variables.push("$page: Int!");
  variables.push("$limit: Int!");

  cursor = `
      page: $page
      limit: $limit
    `;

  const variablesExpr = variables.length > 0 ? `(${variables.join(", ")})` : "";
  const filterExpr = args.length > 0 ? `filter: {_and: [${args.join(", ")}]}` : "";

  return gql<
    {
      infographics: InfographicGalleryPageListItem[];
    },
    {title?: string; publicationName?: string; page?: number; limit?: number}
  >`
    query ${variablesExpr} {
      infographics(
        ${filterExpr}
        sort: ["-date_created"]
        ${cursor}
      ) {
        id
        title
        image {
          id
          filename: filename_download
          title
        }
      }
    }
  `;
};
