<app-page>
  <page-header></page-header>
  <page-content>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-3 col-md-4 my-4 position-relative text-center">
          <img
            [src]="managerPageData.meta.managerImage | imageLink"
            class="img-fluid mx-auto mt-5 w-100 manager"
            [alt]="managerPageData.meta.image.title"
          />
          <h5 class="text-primary mt-4 fw-bold" style="font-size: 1.15rem">{{ managerPageData.meta.managerName }}</h5>
        </div>
      </div>
    </div>

    <div class="bg-secondary bg-opacity-10 mt-5 py-5">
      <div class="container">
        <div class="row">
          <div class="col-md-12 mx-auto">
            <ul class="nav nav-pills" id="" role="tablist">
              <li *ngFor="let skill of managerSkills; index as i" class="nav-item mx-2" role="presentation">
                <button
                  class="nav-link px-4"
                  [ngClass]="{active: i === 0}"
                  id="tab{{ i }}-tab"
                  data-bs-toggle="tab"
                  attr.data-bs-target="#tab{{ i }}-tab-pane"
                  type="button"
                  role="tab"
                  attr.aria-controls="tab{{ i }}-tab-pane"
                  aria-selected="false"
                >
                  <fa-icon [icon]="faEllipsisVertical" class="me-2"></fa-icon>
                  {{ skill.title }}
                </button>
              </li>
            </ul>
            <hr class="my-5 border-very-light-gray" />
            <div class="tab-content">
              <div
                *ngFor="let skill of managerSkills; index as i"
                class="tab-pane fade show"
                [ngClass]="{active: i === 0}"
                id="tab{{ i }}-tab-pane"
                attr.role="tabpane{{ i }}"
                attr.aria-labelledby="tab{{ i }}-tab"
                tabindex="0"
              >
                <p [innerHTML]="skill.value"></p>
              </div>
              <div
                *ngFor="let skill of managerSkills; index as i"
                class="tab-pane fade"
                id="tab{{ i + 1 }}-tab-pane"
                role="tabpanel"
                attr.aria-labelledby="tab{{ i + 1 }}-tab"
                tabindex="0"
              >
                ...
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>
