<div id="stories-subjects" class="py-5 my-5">
  <div class="container">
    <div class="text-center">
      <h6 class="fs-1 fw-bold mt-4 mb-5">{{ meta.subjectsSectionTitle }}</h6>
    </div>

    <div class="d-flex justify-content-center">
      <div class="accordion" id="accordionExample" style="width: 80%">
        <div *ngFor="let subject of subjects; let i = index" class="accordion-item">
          <h2 class="accordion-header" [id]="'heading-' + i">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              [attr.data-bs-target]="'#collapse-' + i"
              aria-expanded="true"
              [attr.aria-controls]="'collapse-' + i"
              [ngStyle]="{'background-image': 'url(' + (subject.image | imageLink) + ')'}"
            >
              {{ subject.title }}
            </button>
          </h2>
          <div
            [id]="'collapse-' + i"
            class="accordion-collapse collapse"
            [ngClass]="{show: i === 0}"
            [attr.aria-labelledby]="'heading-' + i"
            data-bs-parent="#accordionExample"
          >
            <div class="p-5 subject-content" [innerHTML]="subject.content"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
