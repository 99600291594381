import {gql} from "apollo-angular";
import {
  MediaReport,
  MediaReportsFilter,
  MediaReportsPage,
  MediaReportsSort,
  MediaReportsTypes,
} from "src/app/interfaces/portal/media-reports-page";

export const GET_MEDIA_REPORTS_PAGE = gql<{page: MediaReportsPage; mediaReportsTypes: MediaReportsTypes[]}, void>`
  query {
    page: media_reports_page {
      title
      image {
        id
        filename: filename_download
        title
      }
    }
    mediaReportsTypes: media_reports_types {
      id
      name
    }
  }
`;

export const getMediaReportsByFilterFactory = (filter?: MediaReportsFilter, sort: MediaReportsSort = "-date_created") => {
  const variables: string[] = [];
  const expressions: string[] = ['{status: {_eq: "published"}}'];

  if (filter) {
    if (filter.title) {
      variables.push("$title: String!");
      expressions.push(`{title: {_icontains: $title}}`);
    }

    if (filter.type) {
      variables.push("$type: GraphQLStringOrFloat!");
      expressions.push(`{type: {id: {_eq: $type}}}`);
    }
  }

  const queryVariables = variables.length > 0 ? `(${variables.join(", ")})` : "";
  const queryFilter = expressions.length > 1 ? `{_and: [${expressions.join(", ")}]}` : expressions.join("");

  return gql<
    {mediaReports: MediaReport[]},
    {
      title?: string;
      type?: string | number;
    }
  >`
    query ${queryVariables} {
      mediaReports : media_reports(
        filter: ${queryFilter}
        sort: ["${sort}"]
      ) {
        id
        title
        description
        date
        file {
          id
          filename: filename_download
          title
          type
          filesize
        }
        image {
          id
          filename: filename_download
          title
        }
        type {
          id
          name
        }
      }
    }
  `;
};
