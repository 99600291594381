import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Apollo} from "apollo-angular";
import {map, Observable} from "rxjs";
import {PageType} from "src/app/enums/page-type";
import {SingleEventPage, SingleEventPageData} from "src/app/interfaces/portal/single-event-page";
import {GET_SINGLE_EVENT_PAGE} from "src/app/queries/portal/single-event-page.query";
import {PortalContentService} from "src/app/services/portal-content.service";
import {SeoService} from "src/app/services/seo.service";
import {WindowService} from "src/app/services/window.service";

@Injectable({
  providedIn: "root",
})
export class SingleEventPageResolver implements Resolve<SingleEventPageData> {
  constructor(
    private apollo: Apollo,
    private windowService: WindowService,
    private seoService: SeoService,
    private cmsService: PortalContentService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<SingleEventPageData> {
    const {slug} = route.data;
    return this.apollo
      .query({
        query: GET_SINGLE_EVENT_PAGE,
        variables: {
          eventId: route.params["eventId"],
          slug,
        },
      })
      .pipe(
        map((result): SingleEventPageData => {
          const {page: meta, ...page} = result.data;
          const trueMeta: SingleEventPage = {
            title: this.cmsService.mapEventSlugToCategoryPageName(slug),
            image: meta.image,
          };
          return this.seoService.patchTitleAndMetaTags(route, {
            title: page.events[0].name,
            description: this.windowService.getFriendlyDescription(page.events[0].content),
            breadcrumbs: [
              {title: $localize`البوابة`, routerLink: ""},
              {title: trueMeta.title, routerLink: this.cmsService.mapEventSlugToCategoryPageRoute(slug)},
              {title: page.events[0].name, routerLink: `${slug}/${page.events[0].id}`},
            ],
            data: page.events[0],
            meta: trueMeta,
            type: PageType.INTERIOR,
          });
        }),
      );
  }
}
