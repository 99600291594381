<app-loader *ngIf="isLoading"></app-loader>
<app-page>
  <page-header></page-header>
  <page-content>
    <div id="publications">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-6 col-12 mt-lg-0">
            <div class="card rounded-0">
              <div class="card-body">
                <div class="d-flex gap-3 flex-column flex-md-row mb-5">
                  <div style="width: 230px" class="card rounded-0 p-2">
                    <img
                      [src]="publication.image | imageLink"
                      class="book-img img-fluid contain center d-block mx-auto"
                      [alt]="publication.image.title"
                    />
                  </div>

                  <div class="flex-grow-1 pt-4">
                    <div class="author d-block">
                      <img src="assets/images/author-avatar.jpg" alt="المؤلف" />
                      <p class="fw-light ms-2 d-inline">{{ publication.author.name }}</p>
                    </div>

                    <h3 class="text-primary my-3">{{ publication.name }}</h3>
                    <p *ngIf="publication.releaseDate" class="fw-light mt-3">
                      <ng-container i18n>سنة النشر</ng-container>
                      : {{ publication.releaseDate }}
                    </p>
                    <p class="fw-light mt-3">
                      <ng-container i18n>عدد الصفحات</ng-container>
                      : {{ getPublicationPages(publication.pages) }}
                    </p>

                    <div class="text-end">
                      <a
                        *ngIf="publication.purchaseLink"
                        class="btn btn-secondary"
                        [href]="publication.purchaseLink"
                        target="_blank"
                      >
                        <ng-container i18n>شراء الإصدار</ng-container>
                        <fa-icon [icon]="faArrowUpRightFromSquare" class="ms-2"></fa-icon>
                      </a>
                      &nbsp;
                      <a
                        *ngIf="publication.file"
                        class="btn btn-secondary"
                        [href]="publication.file | fileLink"
                        target="_blank"
                      >
                        <ng-container i18n>تحميل الملخص النصي</ng-container>
                        <fa-icon [icon]="faDownload" class="ms-2"></fa-icon>
                      </a>
                    </div>
                  </div>
                </div>

                <ul class="nav nav-pills" id="" role="tablist">
                  <li class="nav-item mx-2" role="presentation">
                    <button
                      class="nav-link active px-4"
                      id="tab0-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#tab0-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="tab0-tab-pane"
                      aria-selected="false"
                    >
                      <fa-icon [icon]="faEllipsisVertical" class="me-2"></fa-icon>
                      <ng-container i18n>التعريف</ng-container>
                    </button>
                  </li>
                  <li *ngIf="infographics.length > 0" class="nav-item mx-2" role="presentation">
                    <button
                      class="nav-link px-4"
                      id="tab1-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#tab1-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="tab1-tab-pane"
                      aria-selected="false"
                    >
                      <fa-icon [icon]="faEllipsisVertical" class="me-2"></fa-icon>
                      <ng-container i18n>الإنفوجرافيك</ng-container>
                    </button>
                  </li>
                </ul>
                <hr class="my-3 border-very-light-gray" />
                <div class="tab-content" id="manager-tabs">
                  <div
                    class="tab-pane active fade show"
                    id="tab0-tab-pane"
                    role="tabpane0"
                    aria-labelledby="tab0-tab"
                    tabindex="0"
                  >
                    <div class="row">
                      <div class="col">
                        <div class="mb-5 d-flex justify-content-end" *ngIf="publication.audioSummary">
                          <button *ngIf="!playAudio" class="btn btn-outline-secondary" (click)="playAudio = true">
                            <ng-container i18n>الاستماع للملخص الصوتي</ng-container>
                            <fa-icon [icon]="faHeadphones" class="ms-2"></fa-icon>
                          </button>
                          <audio *ngIf="playAudio" controls controlsList="nodownload" class="w-100">
                            <source
                              [src]="publication.audioSummary | fileLink"
                              [type]="publication.audioSummary | fileType"
                            />
                            <ng-container i18n>هذا المتصفح لا يدعم تشغيل الصوتيات</ng-container>
                          </audio>
                        </div>
                        <p [innerHTML]="publication.summary"></p>

                        <div
                          *ngIf="publication.videoUrl && getPublicationVideoId(publication)"
                          class="mb-5 d-flex justify-content-center mt-5"
                        >
                          <youtube-player [videoId]="getPublicationVideoId(publication)"></youtube-player>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    *ngIf="infographics.length > 0 && initialized"
                    class="tab-pane fade show"
                    id="tab1-tab-pane"
                    role="tabpane1"
                    aria-labelledby="tab1-tab"
                    tabindex="1"
                  >
                    <div class="row">
                      <div class="col">
                        <lightgallery *ngIf="isBrowser" id="infographic-gallery" [settings]="settings" [onInit]="onInit">
                          <div class="container">
                            <div class="row">
                              <div *ngFor="let infographic of infographics" class="col-lg-6 col-12 mt-4">
                                <div class="item" [attr.data-src]="infographic.image | imageLink">
                                  <div class="card rounded-0">
                                    <div class="card-image">
                                      <img
                                        [src]="infographic.image | imageLink"
                                        class="img-fluid w-100 cover"
                                        style="height: 225px"
                                        [alt]="infographic.title"
                                      />
                                    </div>
                                    <div class="card-body">
                                      <h6
                                        class="title fs-5 mb-4 fw-bold mt-3 tw-line-clamp-2 tw-leading-[1.5rem] tw-min-h-[3rem]"
                                      >
                                        {{ infographic.title }}
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </lightgallery>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 col-12 mt-4 mt-lg-0">
            <!-- <app-publications-sidebar
              *ngIf="newPublications"
              [publications]="newPublications"
              [title]="'جديد اصداراتنا'"
            ></app-publications-sidebar> -->
            <!-- <app-publications-sidebar
              *ngIf="mostViewedPublications"
              [publications]="mostViewedPublications"
              [title]="'الأكثر مشاهدة'"
            ></app-publications-sidebar> -->
            <app-publications-sidebar
              *ngIf="relatedPublications"
              [publications]="relatedPublications"
              i18n-title
              title="ذات صلة"
            ></app-publications-sidebar>
          </div>
        </div>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>
