import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: "masonry",
})
export class MasonryPipe implements PipeTransform {
  transform<T>(value: T[], cols: number, i: number): T[] {
    if (value.length === 0) return value;
    if (cols < 1 || i < 1 || isNaN(cols) || isNaN(i) || i > cols) {
      return value;
    }
    return value.filter((val, index) => {
      return index % cols === i - 1;
    });
  }
}
