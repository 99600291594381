import {Component, Input} from "@angular/core";
import {CarouselSlide} from "src/app/interfaces/carousel-slide";

@Component({
  selector: "app-landing-carousel",
  templateUrl: "./landing-carousel.component.html",
  styleUrls: ["./landing-carousel.component.scss"],
})
export class LandingCarouselComponent {
  @Input() slides!: CarouselSlide[];
}
