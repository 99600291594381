import {Component, Input} from "@angular/core";
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import {StoriesLandingPage} from "src/app/interfaces/stories/stories-landing-page";

@Component({
  selector: "app-stories-landing-cta",
  templateUrl: "./stories-landing-cta.component.html",
  styleUrls: ["./stories-landing-cta.component.scss"],
})
export class StoriesLandingCtaComponent {
  faDownload = faDownload;

  @Input() meta!: StoriesLandingPage;
}
