import {Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {NumbersPage, NumbersPageData, OfficeNumber} from "src/app/interfaces/portal/numbers-page";

@Component({
  selector: "app-numbers-page",
  templateUrl: "./numbers-page.component.html",
  styleUrls: ["./numbers-page.component.scss"],
})
export class NumbersPageComponent implements OnInit {
  meta!: NumbersPage;

  statistics!: OfficeNumber[];

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    const {meta, ...page} = this.route.snapshot.data["page"] as NumbersPageData;

    this.meta = meta;
    this.statistics = page.data;
  }
}
