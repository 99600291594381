import {AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild} from "@angular/core";
import {faAngleLeft, faAngleRight} from "@fortawesome/free-solid-svg-icons";
import {CarouselSlide} from "src/app/interfaces/carousel-slide";
import {WindowService} from "src/app/services/window.service";

@Component({
  selector: "app-carousel",
  templateUrl: "./carousel.component.html",
  styleUrls: ["./carousel.component.scss"],
})
export class CarouselComponent implements AfterViewInit, OnDestroy {
  faAngleRight = faAngleRight;
  faAngleLeft = faAngleLeft;

  @Input() slides!: CarouselSlide[];

  @ViewChild("carousel") carouselElement!: ElementRef<HTMLDivElement>;

  carousel!: any;

  constructor(private windowService: WindowService) {}

  ngAfterViewInit(): void {
    if (!this.carousel) {
      this.windowService.run(() => {
        const Carousel = (window as any).bootstrap.Carousel;
        this.carousel = new Carousel(this.carouselElement.nativeElement, {
          interval: 5000,
          ride: true,
          touch: true,
        });
      });
    }
  }

  ngOnDestroy(): void {
    if (this.carousel) {
      this.windowService.run(() => {
        this.carousel.dispose();
      });
    }
  }

  getPaddedIndex(index: number) {
    return String(index + 1).padStart(2, "0");
  }
}
