import {gql} from "apollo-angular";
import {I18nFunctions} from "src/app/interfaces/i18n";
import {Department, DepartmentsPage} from "src/app/interfaces/portal/departments-page";

export const GET_DEPARTMENTS_PAGE = ({field, filter}: I18nFunctions) => gql<
  {page: DepartmentsPage; departments: Department[]},
  void
>`
  query {
    page: departments_page {
      title: ${field("title")}
      image {
        id
        filename: filename_download
        title
      }
    }
    departments(filter: {_and: [{status: {_eq: "published"}}, ${filter("name")}]}, sort: ["order"]) {
      id
      name: ${field("name")}
      content: ${field("content")}
      order
    }
  }
`;

export const GET_DEPARTMENT_PAGE = ({field, filter}: I18nFunctions) => gql<
  {page: DepartmentsPage; departments: Department[]},
  {departmentId: string}
>`
  query ($departmentId: GraphQLStringOrFloat!) {
    page: departments_page {
      title: ${field("title")}
      image {
        id
        filename: filename_download
        title
      }
    }
    departments: departments(filter: {_and: [{id: {_eq: $departmentId}}, {status: {_eq: "published"}}, ${filter("name")}]}) {
      id
      name: ${field("name")}
      content: ${field("content")}
      order
    }
  }
`;
