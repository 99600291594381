import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve} from "@angular/router";
import {Apollo} from "apollo-angular";
import {map, Observable} from "rxjs";
import {PageType} from "src/app/enums/page-type";
import {DepartmentPageData} from "src/app/interfaces/portal/departments-page";
import {GET_DEPARTMENT_PAGE} from "src/app/queries/portal/departments-page.query";
import {LocalizationService} from "src/app/services/localization.service";
import {SeoService} from "src/app/services/seo.service";
import {WindowService} from "src/app/services/window.service";

@Injectable({
  providedIn: "root",
})
export class SingleDepartmentPageResolver implements Resolve<DepartmentPageData> {
  constructor(
    private apollo: Apollo,
    private windowService: WindowService,
    private seoService: SeoService,
    private localizationService: LocalizationService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<DepartmentPageData> {
    const departmentId = route.params["departmentId"];
    return this.apollo
      .query({
        query: GET_DEPARTMENT_PAGE(this.localizationService.getI18nFns()),
        variables: {
          departmentId,
        },
      })
      .pipe(
        map((result): DepartmentPageData => {
          const {page: meta, ...page} = result.data;
          return this.seoService.patchTitleAndMetaTags(route, {
            title: page.departments[0].name,
            description: this.windowService.getFriendlyDescription(page.departments[0].content),
            breadcrumbs: [
              {title: $localize`البوابة`, routerLink: ""},
              {title: meta.title, routerLink: "departments"},
              {title: page.departments[0].name, routerLink: `departments/${page.departments[0].id}`},
            ],
            data: page.departments[0],
            meta,
            type: PageType.INTERIOR,
          });
        }),
      );
  }
}
