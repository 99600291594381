<ng-content select="page-header"></ng-content>

<app-hero [data]="hero"></app-hero>

<main class="page-content world-bg pt-4 pb-5">
  <ng-content select="page-content"></ng-content>
  <app-fonts-share></app-fonts-share>
</main>

<ng-content select="page-footer"></ng-content>
