import {Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {faGlobe} from "@fortawesome/free-solid-svg-icons";
import {Project, SingleProjectPage, SingleProjectPageData} from "src/app/interfaces/portal/single-project-page";

@Component({
  selector: "app-single-project-page",
  templateUrl: "./single-project-page.component.html",
  styleUrls: ["./single-project-page.component.scss"],
})
export class SingleProjectPageComponent implements OnInit {
  faGlobe = faGlobe;

  meta!: SingleProjectPage;
  project!: Project;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    const {meta, ...page} = this.route.snapshot.data["page"] as SingleProjectPageData;

    this.meta = meta;
    this.project = page.data;
  }

  getFiscalYear(year: string | null | undefined) {
    if (!year) return null;

    if (/^(19|20)\d{2} ?- ?(19|20)\d{2}$/gi.test(year)) return year;

    const start = parseInt(year);
    if (isNaN(start)) return null;

    return `${start} - ${start + 1}`;
  }
}
