<app-page>
  <page-header></page-header>
  <page-content>
    <div class="container">
      <ul class="nav nav-pills" id="" role="tablist">
        <li *ngFor="let department of departments; index as i" class="nav-item mx-2 mb-2" role="presentation">
          <button
            class="nav-link px-4"
            [ngClass]="{active: i === 0}"
            id="tab{{ i }}-tab"
            data-bs-toggle="tab"
            attr.data-bs-target="#tab{{ i }}-tab-pane"
            type="button"
            role="tab"
            attr.aria-controls="tab{{ i }}-tab-pane"
            aria-selected="false"
          >
            <fa-icon [icon]="faElilipsisVertical" class="me-2"></fa-icon>
            {{ department.name }}
          </button>
        </li>
      </ul>
      <hr class="my-5 border-very-light-gray" />
      <div class="tab-content" id="manager-tabs">
        <div
          *ngFor="let department of departments; index as i"
          class="tab-pane fade show"
          [ngClass]="{active: i === 0}"
          id="tab{{ i }}-tab-pane"
          attr.role="tabpane{{ i }}"
          attr.aria-labelledby="tab{{ i }}-tab"
          tabindex="0"
        >
          <div class="row">
            <div class="col">
              <div [innerHTML]="department.content"></div>
            </div>
          </div>
        </div>
        <div
          *ngFor="let department of departments; index as i"
          class="tab-pane fade"
          id="tab{{ i + 1 }}-tab-pane"
          role="tabpanel"
          attr.aria-labelledby="tab{{ i + 1 }}-tab"
          tabindex="0"
        >
          ...
        </div>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>
