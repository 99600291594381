import {gql} from "apollo-angular";
import {I18nFunctions} from "src/app/interfaces/i18n";
import {AboutPageDocument, AboutPageValues, AboutUsPage} from "src/app/interfaces/portal/about-us-page";

export const GET_ABOUTUS_PAGE = ({field, filter}: I18nFunctions) => gql<
  {page: AboutUsPage; values: AboutPageValues[]; documents: AboutPageDocument[]},
  void
>`
  query {
    page: about_page {
      title: ${field("title")}
      image {
        id
        filename: filename_download
        title
      }
      intro: ${field("intro")}
      content: ${field("content")}
      videoLink: video_link
      vision: ${field("vision")}
      mission: ${field("mission")}
      goals: ${field("goals")}
    }
    values(filter: {_and: [{status: {_eq: "published"}}, ${filter("text")}]}, sort: ["-date_created"]) {
      text: ${field("text")}
    }
    documents: about_documents(filter: {_and: [{status: {_eq: "published"}}, ${filter("title")}]}, sort: ["order"]) {
      title: ${field("title")}
      summary: ${field("summary")}
      image {
        id
        filename: filename_download
        title
      }
      file {
        id
        filename: filename_download
        title
      }
    }
  }
`;
