<app-page>
  <page-header></page-header>
  <page-content>
    <div id="single-study">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-7 col-12 mt-lg-0">
            <div class="card rounded-0">
              <div class="card-body p-5">
                <h3 class="text-primary">{{ report.title }}</h3>

                <p class="fw-light small mb-0">
                  <fa-icon [icon]="faCalendarMinus" class="me-2 text-light-gray"></fa-icon>
                  {{ report.date | date : "YYYY/MM/dd" }}
                </p>

                <div class="blog-img my-4">
                  <img [src]="report.image | imageLink" class="img-fluid d-block" [alt]="report.image.title" />
                </div>

                <div [innerHTML]="report.content"></div>

                <div class="mt-5 text-end">
                  <a [href]="report.file | fileLink" target="_blank" class="btn btn-primary">
                    <fa-icon [icon]="faDownload" class="me-2"></fa-icon>
                    <ng-container i18n>حمل التقرير</ng-container>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="col mt-4 mt-md-0">
            <div *ngFor="let report of reports" class="single-report-card card mb-5 rounded-0">
              <div class="row g-0">
                <div class="col-lg-5">
                  <img
                    (click)="navigateToReportDetails(report.id)"
                    [src]="report.image | imageLink"
                    class="img-fluid w-100 cover report-image"
                    style="height: 250px"
                    [alt]="report.image.title"
                  />
                </div>
                <div class="col">
                  <div class="card-body d-flex flex-column h-100">
                    <div class="d-flex justify-content-between align-items-center">
                      <span class="d-flex align-items-center gap-2 text-secondary">
                        <fa-icon [icon]="faSquare"></fa-icon>
                        <ng-container i18n>تقرير</ng-container>
                      </span>

                      <span class="small gr">{{ report.date | date : "YYYY/MM/dd" }}</span>
                    </div>
                    <h5 class="card-title fw-bold text-light-gray mt-3">{{ report.title }}</h5>

                    <div class="d-flex justify-content-between align-items-center mt-auto">
                      <a class="btn btn-primary btn-sm rounded-3" [href]="report.file | fileLink" target="_blank">
                        <ng-container i18n>حمل التقرير</ng-container>
                      </a>

                      <a (click)="navigateToReportDetails(report.id)" class="text-primary v-small details-link">
                        <ng-container i18n>التفاصيل</ng-container>
                        <fa-icon [icon]="faAnglesLeft"></fa-icon>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>
