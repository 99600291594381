<div class="footer-font-override">
  <div class="bg-dark text-light pt-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 col-lg-6">
          <div class="d-flex d-md-block flex-column align-items-center justify-content-center">
            <h6 i18n class="text-primary fw-bold footer-header">مكتب التربية العربي لدول الخليج</h6>
            <hr style="width: 28ch" class="border border-primary border-1 opacity-75" />
          </div>

          <ul class="footer-links list-unstyled mx-3 mx-md-0">
            <li *ngFor="let link of footerLinks" class="list-unstyled-item ps-2 link-height">
              <a *ngIf="link.href" [href]="link.link">{{ link.title }}</a>
              <a *ngIf="!link.href" [routerLink]="link.link">
                {{ link.title }}
              </a>
            </li>
          </ul>

          <app-newsletter></app-newsletter>
        </div>
        <div *ngIf="contact" class="col">
          <div class="d-flex d-md-block flex-column justify-content-center align-items-center">
            <h6 i18n class="text-primary fw-bold footer-header">تواصل معنا</h6>
            <hr style="width: 12ch" class="border border-primary border-1 opacity-75" />
          </div>

          <div class="text-light">
            <p class="link-height mb-0">
              <fa-icon [icon]="faLocationDot" class="me-3 info-icon"></fa-icon>
              {{ contact.location }}
            </p>

            <p class="link-height mb-0">
              <fa-icon [icon]="faPhone" flip="horizontal" class="me-3 info-icon"></fa-icon>
              <a dir="ltr" class="text-light" href="tel: {{ contact.phone }}">
                {{ contact.phone }}
              </a>
            </p>

            <p class="link-height mb-0">
              <fa-icon [icon]="faEnvelope" flip="horizontal" class="me-3 info-icon"></fa-icon>
              <a class="text-light" href="mailto: {{ contact.email }}">
                {{ contact.email }}
              </a>
            </p>
            <!-- <p  class="link-height mb-0">
              <fa-icon [icon]="faQuestion" flip="horizontal" class="me-3 info-icon"></fa-icon>
              <ng-container i18n>هل لديك أي أسئلة تود طرحها؟</ng-container>
            </p> -->

            <a i18n routerLink="/contact-us" class="btn btn-primary fw-bold mt-4">تواصل معنا</a>

            <div
              id="social-media"
              class="social-media-links mt-4 d-flex justify-content-center justify-content-md-start align-content-center gap-4"
            >
              <a class="text-light tw-leading-none" target="_blank" href="https://www.youtube.com/@AbegsOrg">
                <fa-icon [icon]="faYoutube"></fa-icon>
              </a>
              <a class="text-light tw-leading-none" target="_blank" href="https://www.instagram.com/_abegsorg/">
                <fa-icon [icon]="faInstagram"></fa-icon>
              </a>
              <a class="text-light tw-leading-none" target="_blank" href="https://www.facebook.com/abegsorg/">
                <fa-icon [icon]="faFacebook"></fa-icon>
              </a>
              <a class="text-light tw-leading-none" target="_blank" href="https://twitter.com/abegsorg">
                <fa-icon [icon]="faXTwitter"></fa-icon>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <button #scrollTopBtn routerLink="." class="btn btn-primary rounded-circle scroll-top-btn shadow">
    <fa-icon [icon]="faChevronUp"></fa-icon>
  </button>
</div>
