<app-page>
  <page-header></page-header>
  <page-content>
    <div id="news">
      <div class="container">
        <div class="d-flex justify-content-end mb-3">
          <app-search-menu [fields]="filterFields" (search)="onSubmit($event)" (clear)="onClear()"></app-search-menu>
        </div>
        <div class="row">
          <div *ngFor="let piece of news" class="col-xl-3 col-lg-4 col-md-6 mt-lg-0 mb-4">
            <div class="single-blog-card-item">
              <div class="card mx-2 rounded-0">
                <img [src]="piece.image | imageLink" class="card-img-top" [alt]="piece.title" />
                <span class="triangle"></span>
                <div class="card-body pt-4 pb-4 d-flex flex-column">
                  <h6
                    class="text-light tw-line-clamp-2 tw-leading-[1.5rem] tw-min-h-[3rem]"
                    style="font-size: var(--news-card-title-fontSize)"
                  >
                    {{ piece.title }}
                  </h6>
                  <p
                    class="mt-auto mb-0 d-flex justify-content-between text-light fw-light"
                    style="font-size: var(--news-card-description-fontSize)"
                  >
                    <span class="d-flex gap-2">
                      <fa-icon [icon]="faCalendar"></fa-icon>
                      {{ piece.date | date : "YYYY/MM/dd" }}
                    </span>

                    <span class="d-flex gap-2">
                      <fa-icon [icon]="faFlag"></fa-icon>
                      {{ piece.country }}
                    </span>
                  </p>
                  <a [routerLink]="['/news', piece.id]" class="stretched-link"></a>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="!loading && paginate" class="text-center mt-4">
            <button i18n class="btn btn-outline-dark rounded-0 px-5 py-4" (click)="getNextPage()">عرض المزيد</button>
          </div>
          <div
            i18n
            *ngIf="!loading && (!news || !news.length)"
            class="d-flex justify-content-center align-items-center"
            style="min-height: 300px"
          >
            لا توجد بيانات
          </div>
        </div>
        <div *ngIf="loading" class="d-flex align-items-center justify-content-center bg-light" style="min-height: 300px">
          <div class="spinner"></div>
        </div>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>
