<div class="page-hero" style="background-image: url('{{ imageURL }}')" [ngStyle]="{'height.px': data.height}">
  <div class="container h-100 d-flex align-items-center">
    <div class="row flex-grow-1 gap-2">
      <div class="col-12 col-md flex-grow-1">
        <ng-container *ngFor="let breadcrumb of data.breadcrumbs | slice : 0 : -1; let last = last">
          <a class="fs-6 breadcrumb-link" [routerLink]="['/', breadcrumb.routerLink]">
            {{ breadcrumb.title }}
          </a>
          <span *ngIf="!last" class="fs-6 fw-bold text-white text-opacity-75">&nbsp;/&nbsp;</span>
        </ng-container>
        <h1
          *ngIf="data.breadcrumbs && data.breadcrumbs.length > 0"
          class="fs-4 fw-bold text-white mt-2 title tw-line-clamp-2 tw-leading-[1.5rem] tw-min-h-[3rem]"
        >
          {{ data.breadcrumbs[data.breadcrumbs.length - 1].title }}
        </h1>
      </div>
      <div class="col-12 col-md-2 d-flex justify-content-end align-items-center">
        <app-tts-control></app-tts-control>
      </div>
    </div>
  </div>
</div>
