import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {faAnglesLeft, faSquare} from "@fortawesome/free-solid-svg-icons";
import {ReplaySubject, takeUntil} from "rxjs";
import {Report, ReportsPage, ReportsPageData} from "src/app/interfaces/portal/reports-page";
import {SearchMenuField, SearchMenuResult} from "src/app/interfaces/search-menu";
import {PortalContentService} from "src/app/services/portal-content.service";

@Component({
  selector: "app-reports-page",
  templateUrl: "./reports-page.component.html",
  styleUrls: ["./reports-page.component.scss"],
})
export class ReportsPageComponent implements OnInit, OnDestroy {
  faSquare = faSquare;
  faAnglesLeft = faAnglesLeft;

  loading!: boolean;

  meta!: ReportsPage;

  reports!: Report[];

  emptyFilter = {
    title: "",
  };
  filterFields: SearchMenuField[] = [{key: "title", name: $localize`العنوان`, type: "text"}];
  filter!: SearchMenuResult;

  unsubscribe$ = new ReplaySubject(1);

  constructor(private route: ActivatedRoute, private cmsService: PortalContentService) {}

  ngOnInit(): void {
    const {meta} = this.route.snapshot.data["page"] as ReportsPageData;
    this.meta = meta;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(1);
    this.unsubscribe$.complete();
  }

  onSubmit(filter: SearchMenuResult) {
    this.filter = filter;
    this.refreshData();
  }

  onClear() {
    this.filter = {...this.emptyFilter};
    this.refreshData();
  }

  refreshData() {
    this.reports = [];
    this.getReports();
  }

  getReports() {
    this.loading = true;
    this.cmsService
      .listReports(this.filter)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (result) => {
          this.reports = result;
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });
  }
}
