import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {Apollo} from "apollo-angular";
import {map, ReplaySubject, takeUntil} from "rxjs";
import {Locale} from "src/app/enums/locale";
import {
  getSearchPageTotalsQueryAr,
  getSearchPageTotalsQueryEn,
  searchPageQueryAr,
  searchPageQueryEn,
} from "src/app/queries/portal/search-page.query";
import {LocalizationService} from "src/app/services/localization.service";
import {environment} from "src/environments/environment";

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
})
export class SearchComponent implements OnInit, OnDestroy {
  searchKey = "";
  currentPage = -1;
  totalPages!: number;
  searchResults: any[] = [];
  assetsUrl = environment.assetsURI;
  unsubscribe$ = new ReplaySubject(1);

  ITEMS_PER_PAGE = 15;
  cursors: {[key: string]: {limit: number; offset: number}}[] = [];

  constructor(
    private apollo: Apollo,
    private router: Router,
    private route: ActivatedRoute,
    private localizationService: LocalizationService,
  ) {}

  ngOnInit(): void {
    if (this.route.snapshot.queryParams["q"]) {
      this.searchKey = decodeURIComponent(this.route.snapshot.queryParams["q"]);
    }
    this.onSubmit();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(1);
    this.unsubscribe$.complete();
  }
  onSubmit() {
    if (!this.searchKey) {
      this.searchResults = [];
      this.currentPage = 0;
      this.totalPages = 0;
      return;
    }
    this.updateQueryString();
    this.getLimitsAndOffsets()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((cursors) => {
        this.cursors = cursors;
        this.currentPage = 0;
        this.search();
      });
  }

  search(): void {
    const cursor = this.cursors[this.currentPage];
    if (!cursor) {
      this.searchResults = [];
      return;
    }

    this.apollo
      .query({
        query:
          this.localizationService.getLocale() === Locale.ARABIC
            ? searchPageQueryAr(this.localizationService.getI18nFns())
            : searchPageQueryEn(this.localizationService.getI18nFns()),
        variables: {
          searchKey: `%${this.searchKey}%`,
          newsLimit: cursor["news"].limit,
          eventsLimit: cursor["events"].limit,
          publicationsLimit: cursor["publications"].limit,
          projectsLimit: cursor["projects"].limit,
          reportsLimit: cursor["reports"].limit,
          newsOffset: cursor["news"].offset,
          eventsOffset: cursor["events"].offset,
          publicationsOffset: cursor["publications"].offset,
          projectsOffset: cursor["projects"].offset,
          reportsOffset: cursor["reports"].offset,
        },
        fetchPolicy: "no-cache",
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: any) => {
        this.searchResults =
          this.localizationService.getLocale() === Locale.ARABIC
            ? [
                ...result.data.news,
                ...result.data.events,
                ...result.data.projects,
                ...result.data.publications,
                ...result.data.reports,
              ]
            : [...result.data.news, ...result.data.projects];
      });
  }

  updateQueryString(): void {
    const queryParams = {q: encodeURIComponent(this.searchKey)};
    this.router.navigate([], {queryParams: queryParams});
  }
  onPageChange(newPage: number): void {
    this.currentPage = newPage;
    this.search();
  }

  getTotals() {
    return this.apollo
      .query({
        query:
          this.localizationService.getLocale() === Locale.ARABIC
            ? getSearchPageTotalsQueryAr(this.localizationService.getI18nFns())
            : getSearchPageTotalsQueryEn(this.localizationService.getI18nFns()),
        variables: {
          searchKey: `%${this.searchKey}%`,
        },
      })
      .pipe(
        map((result: any): {[key in "news" | "events" | "publications" | "projects" | "reports"]: number} => {
          return {
            news:
              this.localizationService.getLocale() === Locale.ARABIC
                ? result.data.newsPaging[0].count.id
                : result.data.newsPaging[0].count.newsEnCount,
            events: this.localizationService.getLocale() === Locale.ARABIC ? result.data.eventsPaging[0].count.id : 0,
            publications:
              this.localizationService.getLocale() === Locale.ARABIC ? result.data.publicationsPaging[0].count.id : 0,
            projects:
              this.localizationService.getLocale() === Locale.ARABIC
                ? result.data.projectsPaging[0].count.id
                : result.data.projectsPaging[0].count.projectsEnCount,
            reports: this.localizationService.getLocale() === Locale.ARABIC ? result.data.reportsPaging[0].count.id : 0,
          };
        }),
      );
  }

  makeCursors(limits: Record<string, number>[]) {
    const result: {[key: string]: {limit: number; offset: number}}[] = [];

    const offsets: Offsets = {
      news: 0,
      events: 0,
      publications: 0,
      projects: 0,
      reports: 0,
    };

    limits.forEach((pageLimits) => {
      const cursor: {[key: string]: {limit: number; offset: number}} = {};
      ["news", "events", "publications", "projects", "reports"].forEach((key) => {
        const limit = pageLimits[key] || 0;
        cursor[key] = {
          limit,
          offset: offsets[key],
        };
        offsets[key] += limit;
      });
      result.push(cursor);
    });

    return result;
  }

  getLimitsAndOffsets() {
    const pagesLimits: Record<string, number>[] = [];
    return this.getTotals().pipe(
      map((totals: {[key: string]: number}) => {
        let itemsCount = Object.keys(totals).reduce((total, key) => {
          total += totals[key];
          return total;
        }, 0);
        this.totalPages = Math.ceil(itemsCount / this.ITEMS_PER_PAGE);
        while (itemsCount > 0) {
          let pageItemsCount = 0;
          const pageLimits: Record<string, number> = {};
          for (const key in totals) {
            const take = Math.min(this.ITEMS_PER_PAGE - pageItemsCount, totals[key]);
            if (!take) continue;

            pageItemsCount += take;
            totals[key] -= take;
            pageLimits[key] = take;
          }

          itemsCount -= pageItemsCount;
          pagesLimits.push(pageLimits);
        }

        return this.makeCursors(pagesLimits);
      }),
    );
  }
}

export type Offsets = {
  news: number;
  events: number;
  publications: number;
  projects: number;
  reports: number;
  [key: string]: number; // Index signature allowing string indexing
};
