import {gql} from "apollo-angular";
import {I18nFunctions} from "src/app/interfaces/i18n";
import {CountriesPage, CountriesPageListItem} from "src/app/interfaces/portal/countries-page";

export const GET_COUNTRIES_PAGE = ({field, filter}: I18nFunctions) => gql<
  {page: CountriesPage; countries: CountriesPageListItem[]},
  void
>`
  query {
    page: members_page {
      title: ${field("title")}
      image {
        id
        filename: filename_download
        title
      }
    }
    countries: members(filter: {_and: [{status: {_eq: "published"}}, ${filter("name")}]}, sort: ["order"]) {
      name: ${field("name")}
      url
      image {
        id
        filename: filename_download
        title
      }
      content: ${field("content")}
    }
  }
`;
