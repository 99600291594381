import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {icon} from "@fortawesome/fontawesome-svg-core";
import {faCircleLeft, faCircleRight} from "@fortawesome/free-regular-svg-icons";
import {faArrowUpRightFromSquare, faDownload, faEllipsisVertical, faHeadphones} from "@fortawesome/free-solid-svg-icons";
import {parseISO} from "date-fns";
import {InitDetail} from "lightgallery/lg-events";
import {LightGallery} from "lightgallery/lightgallery";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import {filter, ReplaySubject, skip, switchMap, takeUntil} from "rxjs";
import {InfographicGalleryPageListItem} from "src/app/interfaces/portal/infographic-gallery-page";
import {Publication, PublicationPageData} from "src/app/interfaces/portal/publications-page";
import {PortalContentService} from "src/app/services/portal-content.service";
import {WindowService} from "src/app/services/window.service";

@Component({
  selector: "app-single-publication-page",
  templateUrl: "./single-publication-page.component.html",
  styleUrls: ["./single-publication-page.component.scss"],
})
export class SinglePublicationPageComponent implements OnInit, OnDestroy {
  faArrowUpRightFromSquare = faArrowUpRightFromSquare;
  faDownload = faDownload;
  faEllipsisVertical = faEllipsisVertical;
  faHeadphones = faHeadphones;

  isLoading = false;
  publication!: Publication;
  // newPublications!: Publication[];
  // mostViewedPublications!: Publication[];
  relatedPublications!: Publication[];

  playAudio = false;

  lightGallery!: LightGallery;
  infographics: InfographicGalleryPageListItem[] = [];
  settings = {
    licenseKey: "123",
    thumbnail: true,
    counter: false,
    selector: ".item",
    plugins: [lgThumbnail],
    nextHtml: icon(faCircleRight).html.join(""),
    prevHtml: icon(faCircleLeft).html.join(""),
  };
  onInit = (detail: InitDetail): void => {
    this.lightGallery = detail.instance;
  };
  initialized = true;

  isBrowser!: boolean;

  unsubscribe$ = new ReplaySubject(1);

  constructor(
    private route: ActivatedRoute,
    private cmsService: PortalContentService,
    private router: Router,
    private windowService: WindowService,
  ) {
    this.subscribeToRouteChange();
  }

  ngOnInit() {
    this.isBrowser = this.windowService.isBrowser;
    this.isLoading = true;

    const page = this.route.snapshot.data["page"] as PublicationPageData;
    this.publication = page.data.publication;
    this.infographics = page.data.infographics;

    this.updatePublicationViewsOnEnterPage(this.publication.id, this.publication.views + 1);

    // combineLatest([
    //   this.cmsService.getPublicationsByFilter(1, {}, "-views"),
    //   this.cmsService.getPublicationsByFilter(1, {}, "-release_date"),
    // ])
    //   .pipe(takeUntil(this.unsubscribe$))
    //   .subscribe({
    //     next: ([mostViewedPublications, newPublications]) => {
    //       this.mostViewedPublications = mostViewedPublications.publications.slice(0, 4);
    //       this.newPublications = newPublications.publications.slice(0, 4);
    //       this.isLoading = false;
    //     },
    //     error: () => {
    //       this.mostViewedPublications = this.newPublications = [];
    //       this.isLoading = false;
    //     },
    //   });
    this.cmsService
      .getPublicationsByFilter(1, {classification: this.publication.classification.id}, "-release_date")
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (result) => {
          this.relatedPublications = result.slice(0, 4);
          this.isLoading = false;
        },
        error: () => {
          this.relatedPublications = [];
          this.isLoading = false;
        },
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(1);
    this.unsubscribe$.complete();
  }

  updatePublicationViewsOnEnterPage(publicationId: number | string, views: number) {
    this.cmsService.updatePublicationViewsCount(publicationId, views).pipe(takeUntil(this.unsubscribe$)).subscribe();
  }

  getPublicationPages(pages: string | number | undefined | null): number | undefined {
    if (pages === undefined || pages === null) return undefined;

    if (["string", "number"].includes(typeof pages)) {
      const parsed = parseInt(pages.toString());
      return isNaN(parsed) ? undefined : parsed;
    }

    return undefined;
  }

  getPublicationVideoId(publication: Publication): string {
    try {
      if (!publication.videoUrl) return "";
      const url = new URL(publication.videoUrl);
      const v = url.searchParams.get("v");
      if (v) return v;
      if (url.host === "youtu.be") return url.pathname;
      return "";
    } catch (error) {
      return "";
    }
  }

  subscribeToRouteChange() {
    this.router.events
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((event) => event instanceof NavigationEnd),
        skip(1),
        switchMap(() => this.route.params),
        switchMap((params) => this.cmsService.getPublicationById(params["publicationId"])),
      )
      .subscribe((publication) => {
        this.playAudio = false;
        this.publication = publication;
        this.reInitializeInfographs();
      });
  }

  reInitializeInfographs() {
    this.initialized = false;
    setTimeout(() => {
      this.initialized = true;
    });
  }
}
