import {HttpErrorResponse} from "@angular/common/http";
import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {faCheck, faCircleNotch, faEnvelopeOpenText, faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";
import {ReCaptchaV3Service} from "ng-recaptcha";
import {ReplaySubject, switchMap, takeUntil} from "rxjs";
import {ApiService} from "src/app/services/api.service";
import {WindowService} from "src/app/services/window.service";
import {environment} from "src/environments/environment";

@Component({
  selector: "app-newsletter",
  templateUrl: "./newsletter.component.html",
  styleUrls: ["./newsletter.component.scss"],
})
export class NewsletterComponent implements OnInit, OnDestroy {
  faEnvelopeOpenText = faEnvelopeOpenText;
  faCircleNotch = faCircleNotch;
  faCheck = faCheck;
  faTriangleExclamation = faTriangleExclamation;

  emailFormGroup = new FormGroup({
    email: new FormControl("", {nonNullable: true, validators: [Validators.required, Validators.email]}),
  });

  sending!: boolean;
  success!: boolean;
  hasError!: boolean;

  @ViewChild("ref") ref!: ElementRef<HTMLInputElement>;
  popover: any;

  unsubscribe$ = new ReplaySubject(1);

  constructor(
    private apiService: ApiService,
    private recapatchaV3Service: ReCaptchaV3Service,
    private windowService: WindowService,
  ) {}

  ngOnInit(): void {
    this.emailFormGroup.controls.email.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.success = this.hasError = false;
      this.hidePopover();
    });
  }

  ngOnDestroy(): void {
    this.hidePopover();
    this.unsubscribe$.next(1);
    this.unsubscribe$.complete();
  }

  get email() {
    return this.emailFormGroup.controls["email"];
  }

  addToNewslettersList() {
    this.sending = true;
    this.success = this.hasError = false;
    this.recapatchaV3Service
      .execute(environment.recaptcha.newslettersAction)
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap((token) => {
          return this.apiService.submitAction(environment.recaptcha.newslettersAction, token, {email: this.email.value});
        }),
      )
      .subscribe({
        next: () => {
          this.sending = false;
          this.success = true;

          this.triggerPopover("success", $localize`شكرًا لاشتراككم في نشرة جسور البريدية!`);
        },
        error: (error: HttpErrorResponse) => {
          this.sending = false;
          this.hasError = true;
          if (error?.error?.message === "Email already exists") {
            this.triggerPopover("error", $localize`بريدكم مشترك بالفعل في النشرة!`);
          } else {
            this.triggerPopover("error", $localize`عذرًا حدث خطأ. يرجى إعادة المحاولة في وقت لاحق!`);
          }
        },
      });
  }

  triggerPopover(type: "success" | "error", message: string) {
    this.windowService.run(() => {
      if (this.popover) {
        this.popover.dispose();
        this.popover = null;
      }

      if (!this.ref.nativeElement) return;
      const Popover = (window as any).bootstrap.Popover;
      this.popover = Popover.getOrCreateInstance(this.ref.nativeElement, {
        content: message,
        trigger: "manual",
        placement: "top",
        customClass: [type, "newsletter-popover"].join(" "),
      });
      this.popover.show();
    });
  }

  hidePopover() {
    this.windowService.run(() => {
      if (!this.ref.nativeElement || !this.popover) return;
      this.popover.dispose();
      this.popover = null;
    });
  }
}
