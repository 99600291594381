<app-page>
  <page-header></page-header>
  <page-content>
    <div class="container">
      <!-- <div class="row justify-content-center">
        <div *ngFor="let resource of resources; index as i" class="col-lg-4 col-sm-6 mt-4">
          <div class="card h-100 rounded-0 hover-border-primary">
            <img [src]="resource.image | imageLink" class="card-img-top resource-image" [alt]="resource.image.title" />
            <div class="card-body d-flex align-items-center justify-content-center">
              <p class="card-text">
                {{ resource.title }}
              </p>
            </div>
            <a *ngIf="resource.link" [href]="resource.link" target="_blank" class="stretched-link"></a>
          </div>
        </div>
      </div> -->
      <div class="row justify-content-center">
        <div *ngFor="let resource of resources" class="col-12 col-xl-8">
          <div class="card shadow-sm mb-3">
            <div class="row g-0">
              <div class="col-md-4 d-flex justify-content-center align-items-center">
                <img [src]="resource.image | imageLink" class="img-fluid" [alt]="resource.title" style="max-height: 100px" />
              </div>
              <div class="col-md-8">
                <div class="card-body">
                  <h5 class="card-title">{{ resource.title }}</h5>
                  <p class="card-text">
                    {{ resource.description }}
                  </p>
                  <a [href]="resource.link" class="btn btn-primary">الرابط</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>
