<!-- Modal -->
<div
  class="modal fade"
  id="popupModal"
  tabindex="1"
  aria-labelledby="popupModalLabel"
  aria-hidden="true"
  (focus)="setFocus()"
>
  <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header mt-2">
        <div class="flag-wrapper">
          <img src="assets/images/flag-wrapper.svg" alt="" class="mask" />
        </div>

        <button (click)="closeModal()" type="button" class="btn btn-link" aria-label="Close">
          <img src="assets/images/close-circle.svg" alt="" />
        </button>
      </div>
      <div class="modal-body text-center">
        <input
          (keydown.enter)="search()"
          [(ngModel)]="searchKey"
          type="text"
          class="form-control form-control-lg"
          i18n-placeholder
          placeholder="كلمة البحث"
          #myInput
        />
        <button i18n (click)="search()" class="btn btn-dark btn-lg px-4 mt-3">بحث</button>
      </div>
    </div>
  </div>
</div>
