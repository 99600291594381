<app-loader *ngIf="loading"></app-loader>
<app-page>
  <page-header></page-header>
  <page-content>
    <div id="seminar">
      <div class="container">
        <div class="row d-flex justify-content-between">
          <div class="col-lg-8 col-md-6 col-12 mt-lg-0">
            <div i18n class="text-center fw-bold mt-5" *ngIf="!events || !events.length">لا توجد فعاليات في هذه الفترة</div>
            <div *ngFor="let event of events" class="card mb-5 rounded-0">
              <div class="row g-0">
                <div class="col-md-6">
                  <img
                    [src]="event.image | imageLink"
                    class="img-fluid w-100 cover"
                    style="height: 230px"
                    [alt]="event.image.title"
                  />
                </div>
                <div class="col">
                  <div class="card-body d-flex flex-column h-100">
                    <div class="d-flex justify-content-between align-items-center">
                      <span class="d-flex align-items-center gap-2 text-secondary fw-bold fs-6">
                        <fa-icon [icon]="getSlugIcon(event.type.slug)"></fa-icon>
                        <ng-container>{{ event.type.name }}</ng-container>
                      </span>
                      <p class="fw-light small mb-0">
                        {{ event.startDate | date : "YYYY/MM/dd" }}
                      </p>
                    </div>

                    <h5 class="card-title fw-bold text-light-gray mt-3 tw-line-clamp-2 tw-leading-[1.5rem] tw-min-h-[3rem]">
                      {{ event.name }}
                    </h5>
                    <p
                      *ngIf="event.summary"
                      class="text-light-gray tw-line-clamp-3 tw-leading-[1.5rem] tw-min-h-[4.5rem]"
                      [innerHTML]="event.summary"
                    ></p>

                    <div class="mt-auto text-end">
                      <a i18n [routerLink]="['/', event.type.slug, event.id]" class="btn btn-link text-secondary fw-bold">
                        التفاصيل
                      </a>
                    </div>
                  </div>
                </div>
                <a [routerLink]="['/', event.type.slug, event.id]" class="stretched-link"></a>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 col-12 mt-4 mt-lg-0">
            <div class="d-flex align-items-center justify-content-end">
              <select style="width: 130px" class="form-select rounded-0 focus-shadow-none" [(ngModel)]="calendarView">
                <option i18n value="month">يوم</option>
                <option i18n value="year">شهر</option>
                <option i18n value="multi-year">سنه</option>
              </select>
            </div>

            <div class="training-calendar-widget mt-4 d-flex justify-content-center align-items-center">
              <app-calendar
                [selected]="selectedDate"
                (selectedChange)="onPeriodFilterChange($event)"
                class="w-100"
                [currentView]="calendarView"
              ></app-calendar>
            </div>

            <h4 i18n *ngIf="upcomingEvents && upcomingEvents.length > 0" class="fw-bold text-primary mt-5">فعاليات قادمة</h4>
            <div *ngFor="let upcomingEvent of upcomingEvents" class="side-widget mt-4">
              <div class="side-widget mt-4">
                <div class="d-flex align-items-center justify-content-start gap-3">
                  <div
                    class="bg-primary fw-bold h5 text-white d-flex flex-column align-items-center justify-content-center"
                    style="min-width: 70px; aspect-ratio: 1/1"
                  >
                    <p class="mb-0">{{ upcomingEvent.startDate | date : "dd" }}</p>
                    <p class="mb-0">{{ upcomingEvent.startDate | date : "MMMM" }}</p>
                  </div>
                  <div>
                    <p [routerLink]="upcomingEvent.id" class="fs-5 text-light-gray mb-0 meeting-name">
                      {{ upcomingEvent.name }}
                    </p>
                  </div>
                </div>
              </div>
              <hr class="my-4 text-very-light-gray" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>
