import {Component, OnDestroy, OnInit} from "@angular/core";
import {MatCalendarView} from "@angular/material/datepicker";
import {ActivatedRoute} from "@angular/router";
import {endOfDay, endOfMonth, endOfYear, startOfDay, startOfMonth, startOfYear} from "date-fns";
import {ReplaySubject, takeUntil} from "rxjs";
import {PeriodFilter} from "src/app/enums/period-filter";
import {EventCategoryPageData, EventCategoryPageListItem} from "src/app/interfaces/portal/event-category-page";
import {EventSlug} from "src/app/interfaces/portal/events-page";
import {PortalContentService} from "src/app/services/portal-content.service";

@Component({
  selector: "app-event-category-page",
  templateUrl: "./event-category-page.component.html",
  styleUrls: ["./event-category-page.component.scss"],
})
export class EventCategoryPageComponent implements OnInit, OnDestroy {
  loading = false;

  events!: EventCategoryPageListItem[];
  upcomingEvents!: EventCategoryPageListItem[];

  slug!: EventSlug[];

  calendarView: MatCalendarView = "multi-year";
  periodFilter: PeriodFilter = PeriodFilter.YEAR;
  selectedDate: Date = new Date();

  unsubscribe$ = new ReplaySubject(1);

  constructor(private route: ActivatedRoute, private cmsService: PortalContentService) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next(1);
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    const page = this.route.snapshot.data["page"] as EventCategoryPageData;
    this.upcomingEvents = page.data;
    this.slug = this.route.snapshot.data["slug"];
    this.fetchEvents();
  }

  get startDate() {
    switch (this.periodFilter) {
      case PeriodFilter.DAY:
        return startOfDay(this.selectedDate);
      case PeriodFilter.MONTH:
        return startOfMonth(this.selectedDate);
      case PeriodFilter.YEAR:
        return startOfYear(this.selectedDate);
    }
  }

  get endDate() {
    switch (this.periodFilter) {
      case PeriodFilter.DAY:
        return endOfDay(this.selectedDate);
      case PeriodFilter.MONTH:
        return endOfMonth(this.selectedDate);
      case PeriodFilter.YEAR:
        return endOfYear(this.selectedDate);
    }
  }

  getSlugIcon(slug: EventSlug) {
    return this.cmsService.getEventSlugIcon(slug);
  }

  fetchEvents() {
    this.loading = true;
    this.cmsService
      .listEventsBySlug(this.startDate, this.endDate, this.slug)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (result) => {
          this.events = result;
          this.loading = false;
        },
        error: () => {
          this.events = [];
          this.loading = false;
        },
      });
  }

  public onPeriodFilterChange(event: {date: Date; type: PeriodFilter}) {
    this.selectedDate = event.date;
    switch (event.type) {
      case PeriodFilter.DAY:
        this.periodFilter = PeriodFilter.DAY;
        break;
      case PeriodFilter.MONTH:
        this.periodFilter = PeriodFilter.MONTH;
        break;

      case PeriodFilter.YEAR:
        this.periodFilter = PeriodFilter.YEAR;
        break;
      default:
        this.periodFilter = PeriodFilter.DAY;
        break;
    }
    this.fetchEvents();
  }
}
