<app-page>
  <page-header></page-header>
  <page-content>
    <div class="container">
      <div class="row">
        <div class="col-lg-8 col-md-10 mx-auto">
          <div class="card rounded-0">
            <div class="card-body p-5">
              <ul>
                <li *ngFor="let parent of menu">
                  <ng-container *ngIf="parent.type === MenuItemType.LINK">
                    <a *ngIf="parent.href" [href]="parent.link">
                      <fa-icon [icon]="faAngleLeft"></fa-icon>
                      {{ parent.title }}
                    </a>
                    <a *ngIf="!parent.href" [routerLink]="parent.link">
                      <fa-icon [icon]="faAngleLeft"></fa-icon>
                      {{ parent.title }}
                    </a>
                  </ng-container>
                  <ng-container *ngIf="parent.type === MenuItemType.FRAGMENT">
                    <a routerLink="/" [fragment]="parent.link">
                      <fa-icon [icon]="faAngleLeft"></fa-icon>
                      {{ parent.title }}
                    </a>
                  </ng-container>
                  <ng-container *ngIf="parent.type === MenuItemType.GROUP">
                    <a href="javascript:void(0)">
                      <fa-icon [icon]="faAngleLeft"></fa-icon>
                      {{ parent.title }}
                    </a>
                    <ul>
                      <li *ngFor="let item of parent.children">
                        <ng-container *ngIf="item.type === MenuItemType.LINK">
                          <a *ngIf="item.href" [href]="item.link">
                            {{ item.title }}
                          </a>
                          <a *ngIf="!item.href" [routerLink]="item.link">
                            {{ item.title }}
                          </a>
                        </ng-container>
                        <ng-container *ngIf="item.type === MenuItemType.FRAGMENT">
                          <a routerLink="/" [fragment]="item.link">
                            {{ item.title }}
                          </a>
                        </ng-container>
                      </li>
                    </ul>
                  </ng-container>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>
